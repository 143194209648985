import React from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import {
  List,
  makeStyles,
  Typography,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { selectLeaderboardPlayers } from "./leaderboardSlice";
import { selectGameStatusObject } from "../gamestate/gameStateSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(3),
  },
  highlighted: {
    color: "#e7ae02",
    fontWeight: "bold",
    fontStyle: "italic",
  },
}));

const LeaderboardRow = ({ player, index, inControl }) => {
  const classes = useStyles();

  return (
    <ListItem>
      <ListItemText
        primary={
          <span className={clsx(inControl && classes.highlighted)}>{`${
            index + 1
          }. ${player.name}`}</span>
        }
        secondary={"$" + player.score.toLocaleString()}
      />
    </ListItem>
  );
};

const Leaderboard = () => {
  const classes = useStyles();

  const players = useSelector(selectLeaderboardPlayers);
  const gameState = useSelector(selectGameStatusObject);

  const { playerInControl } = gameState;

  let list;
  if (!players) {
    list = <Typography>Loading...</Typography>;
  } else {
    list = players.map((player, idx) => (
      <LeaderboardRow
        player={player}
        index={idx}
        key={`player-${player.id}`}
        inControl={player.id === playerInControl}
      />
    ));
  }

  const dense = players.length > 8;

  return (
    <div className={classes.root}>
      <Typography variant="h5">Leaderboard</Typography>
      <List dense={dense}>{list}</List>
    </div>
  );
};

export default Leaderboard;
