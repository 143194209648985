import logging from "utils/logging";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import GameBoardCell from "./GameBoardCell";
import { useSelector } from "react-redux";
import { selectQuestionObjectsForBoard } from "../gameBoardSlice";

const log = logging.getLogger("GameBoard"); //eslint-disable-line no-unused-vars

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    padding: theme.spacing(2),
    backgroundColor: "black",
    // marginLeft: 0,
    // marginTop: 8,
  },
  rootGrid: {
    backgroundColor: "black",
    height: "95%",
  },
  fullHeight: {
    height: "100%",
  },
  gridRow: {
    height: "100%",
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    // textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const GridRow = ({ cellData }) => {
  const classes = useStyles();

  let width = 2;
  width = cellData.length <= 4 ? 3 : 2;

  return (
    <React.Fragment>
      <Grid container item xs={12}>
        {cellData &&
          cellData.map((cell, idx) => (
            <Grid
              key={`grid-row-${cell.label}-${idx}`}
              item
              md={width}
              className={classes.fullHeight}
            >
              <GameBoardCell
                label={cell.label}
                questionId={cell.id}
                status={cell.status}
              />
            </Grid>
          ))}
      </Grid>
    </React.Fragment>
  );
};

const GameBoard = () => {
  const classes = useStyles();

  const cells = useSelector(selectQuestionObjectsForBoard);

  return (
    <Grid
      container
      className={classes.rootGrid}
      spacing={3}
      alignItems="stretch"
      // flexGrow={1}
    >
      <GridRow cellData={cells.categories} />
      <GridRow cellData={cells[1]} />
      <GridRow cellData={cells[2]} />
      <GridRow cellData={cells[3]} />
      <GridRow cellData={cells[4]} />
      <GridRow cellData={cells[5]} />
    </Grid>
  );
};

export default GameBoard;
