import logging from "utils/logging";
import React from "react";
import { Box, Grid, makeStyles, Fade } from "@material-ui/core";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { selectAllPlayers } from "../player/playerSlice";

const log = logging.getLogger("PodiumGrid"); //eslint-disable-line no-unused-vars

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  bgGradient: {
    background: `linear-gradient(145deg, #9f0e29 0%, #0b207d 50%, #940161)`,
    backgroundAttachment: "fixed",
    height: "100%",
    width: "100%",
    position: "fixed",
    top: "0px",
    left: "0px",
    zIndex: "-1",
  },
  podiumGrid: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
  podium: {
    // width: "300px",
    borderWidth: "10px",
    borderColor: "black",
    borderStyle: "solid",
    marginBottom: "-10px",
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  score: {
    position: "relative",
    // left: "10px",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    // width: "300px",
    backgroundColor: "#1700a0",
    color: "white",
    fontFamily: "Helvetica Inserat",
    fontSize: "2.5em",
    textAlign: "center",
    borderWidth: "10px",
  },
  signature: {
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    position: "relative",
    left: "-10px",
    // padding: "30px",
    width: "100%",
    height: "300px",
    // margin: "auto",
    backgroundColor: "#1700a0",
    objectFit: "contain",
  },
  signaturePlaceholder: {
    marginLeft: 0,
    marginRight: 0,
    position: "relative",

    backgroundColor: "#1700a0",
    objectFit: "contain",
    width: "100%",
    height: "300px",
    left: "-10px",
  },
}));

export const PodiumSignature = ({ player }) => {
  const classes = useStyles();

  const hasSignature = !!player.signatureURL;

  return hasSignature ? (
    <Fade timeout={300} in={hasSignature}>
      <img
        alt={`Signature for ${player.name}`}
        className={clsx(classes.signature, classes.podium)}
        src={player.signatureURL}
      />
    </Fade>
  ) : (
    <Fade timeout={200} in={!hasSignature}>
      <Box width={1} className={clsx(classes.signature, classes.podium)} />
    </Fade>
  );
};

const Podium = ({ player, podiumSize, onClick, podiumScreenRenderer }) => {
  const classes = useStyles();

  let screen = <PodiumSignature player={player} />;

  if (podiumScreenRenderer) {
    screen = (
      <div className={clsx(classes.podium, classes.signature)}>
        {podiumScreenRenderer(player)}
      </div>
    );
  }

  return (
    <Grid item xs={podiumSize}>
      <div className={classes.podium}>
        <div className={classes.score}>
          {player.name} – {player.score.toLocaleString()}
        </div>
        <Box width={1} onClick={() => (onClick ? onClick(player) : "")}>
          {screen}
        </Box>
      </div>
    </Grid>
  );
};

const PodiumGrid = ({ onClickPodium, players, podiumScreenRenderer }) => {
  const classes = useStyles();

  const allPlayers = useSelector(selectAllPlayers);

  if (!players && !allPlayers) {
    return <div />;
  }

  let playersToShow = players ? players : allPlayers;

  const podiumSize = playersToShow.length <= 8 ? 3 : 2;

  return (
    <div className={classes.root}>
      <div className={classes.bgGradient} />
      <Grid container spacing={3} className={classes.podiumGrid}>
        {playersToShow &&
          playersToShow.map((player) => (
            <Podium
              player={player}
              podiumSize={podiumSize}
              onClick={onClickPodium}
              key={`podium-${player.id}`}
              podiumScreenRenderer={podiumScreenRenderer}
            />
          ))}
      </Grid>
    </div>
  );
};

export default PodiumGrid;
