import React, { useEffect, useState } from "react";
import { Backdrop, Modal, Zoom, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { advanceState } from "features/gamestate/GameState";
import { useDispatch, useSelector } from "react-redux";
import {
  selectGameId,
  selectGameObject,
  selectGameStatusObject,
} from "features/gamestate/gameStateSlice";
import {
  selectAllPlayers,
  selectPlayerInControl,
} from "features/player/playerSlice";
import {
  GAMESTATE_ROUND_FINAL,
  GAMESTATE_ROUNDSTATE_DAILYDOUBLE_PICKED,
  GAMESTATE_ROUNDSTATE_QUESTION_WAGERING,
} from "features/gamestate/constants";
import { DDResult, DDRevealBox, DDWager } from "./DailyDoubleGameBoardQuestion";
import {
  GameBoardClue,
  GameBoardQuestionCountdown,
  GameBoardQuestionHeader,
  GameBoardQuestionResults,
} from "./GameBoardQuestion";
import { selectCurrentQuestionObject } from "features/question/questionSlice";
import {
  selectActiveDDSubmission,
  stopWatchingSubmissionAction,
  watchSingleSubmissionAction,
} from "features/question/submissionSlice";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    fontFamily: "Korinna",
    fontSize: "3em",
    width: "800px",
    minHeight: "450px",
    color: "#ffffff",
    backgroundColor: "#1700a0",
    textAlign: "center",
  },
}));

const GameBoardModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [advancingState, setAdvancingState] = useState(false);

  const gameId = useSelector(selectGameId);
  const gameObj = useSelector(selectGameObject);
  const players = useSelector(selectAllPlayers);
  const gameStateObj = useSelector(selectGameStatusObject);
  const playerInControl = useSelector(selectPlayerInControl);
  const questionObj = useSelector(selectCurrentQuestionObject);
  const ddSubmission = useSelector(selectActiveDDSubmission);

  const isFinal = gameStateObj.round === GAMESTATE_ROUND_FINAL;
  const isDD = gameStateObj.dailyDouble;

  useEffect(() => {
    if (isDD && gameId && playerInControl && questionObj) {
      // Watch the player submission if it's a daily double
      dispatch(
        watchSingleSubmissionAction(gameId, playerInControl.id, questionObj.id)
      );
    }
    return function cleanup() {
      if ((gameId, questionObj, playerInControl)) {
        dispatch(
          stopWatchingSubmissionAction(
            gameId,
            questionObj.id,
            playerInControl.id
          )
        );
      }
    };
  }, [isDD, dispatch, gameId, questionObj, playerInControl]);

  const onClick = async () => {
    if (advancingState) {
      return;
    }
    setAdvancingState(true);
    await advanceState(gameObj, players, dispatch);
    setAdvancingState(false);
  };

  let questionBody = () => {
    if (!questionObj) {
      return <div />;
    }

    let pointsLabel = questionObj.label;
    let ddResult = null;
    if (isDD && ddSubmission) {
      pointsLabel = `$${ddSubmission.wager.toLocaleString()}`;
      ddResult = (
        <DDResult
          answer={questionObj.answers[0]}
          gameState={gameStateObj}
          guess={ddSubmission.answer}
          playerName={playerInControl ? playerInControl.name : null}
          wager={ddSubmission.wager}
          correct={ddSubmission.result === "correct"}
        />
      );
    }

    return (
      <React.Fragment>
        <GameBoardQuestionHeader
          category={questionObj.category}
          pointsLabel={pointsLabel}
          showPoints={!isFinal}
          isWager={isDD}
        />
        <GameBoardClue clueText={questionObj.clue} />
        <GameBoardQuestionCountdown
          gameState={gameStateObj}
          questionObject={questionObj}
          gameId={gameId}
        />
        {isDD ? (
          ddResult
        ) : (
          <GameBoardQuestionResults
            gameState={gameStateObj}
            answer={questionObj.answers[0]}
          />
        )}
      </React.Fragment>
    );
  };

  let body;

  switch (gameStateObj.roundState) {
    case GAMESTATE_ROUNDSTATE_DAILYDOUBLE_PICKED:
      body = <DDRevealBox />;
      break;
    case GAMESTATE_ROUNDSTATE_QUESTION_WAGERING:
      let wager = null;
      if (ddSubmission) {
        wager = ddSubmission.wager;
      }
      body = (
        <DDWager
          playerName={playerInControl ? playerInControl.name : null}
          wager={wager}
        />
      );
      break;
    default:
      body = questionBody();
      break;
  }

  return (
    <Modal
      open={true}
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Zoom in={true} timeout={250}>
        <div className={classes.paper} onClick={onClick}>
          {advancingState ? <LinearProgress /> : ""}
          {body}
        </div>
      </Zoom>
    </Modal>
  );
};

export { GameBoardModal };
