import React, { useEffect } from "react";

const FullBackgroundColor = ({ color }) => {
  useEffect(() => {
    document.body.style.backgroundColor = color;
  }, [color]);

  return <React.Fragment />;
};

export { FullBackgroundColor };
