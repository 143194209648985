import logging from "utils/logging";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CircularProgress,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  selectGameId,
  selectGameObject,
  selectGameStatusObject,
} from "../gamestate/gameStateSlice";
import Question from "features/question/Question";
import MakePlayer from "features/player/MakePlayer";
import {
  getPlayerForUserInGameAction,
  selectUserId,
  selectPlayer,
  selectLoadingPlayer,
} from "features/player/playerSlice";
import {
  CLIENT_QUESTIONVIEW_ROUNDSTATES,
  GAMESTATE_CURRENT_ROUND,
  GAMESTATE_ROUND_FINAL,
  GAMESTATE_ROUNDSTATE_QUESTION_WAGERING,
  GAMESTATE_ROUNDSTATE_WAITING,
} from "../gamestate/constants";
import PlayerLeaderboard from "../leaderboard/PlayerLeaderboard";
import { Wagering } from "./Wagering";
import {
  stopWatchingSubmissionAction,
  watchSingleSubmissionAction,
} from "../question/submissionSlice";

const log = logging.getLogger("GameComponent"); //eslint-disable-line no-unused-vars

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    height: "100%",
  },
  loading: {
    width: "100%",
    height: "100%",
    minHeight: "300px",
  },
  divider: {
    // backgroundColor: "#dddddd",
  },
  header: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    fontSize: "2em",
    // color: "white",
    fontFamily: "Gyparody",
  },
  header2: {
    paddingBottom: theme.spacing(1),
    fontSize: "2em",
    fontFamily: "Helvetica Inserat",
  },
  bodyDiv: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  waiting: {
    fontFamily: "Korinna",
  },
}));

const Game = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const gameStatus = useSelector(selectGameStatusObject);
  const game = useSelector(selectGameObject);
  const gameId = useSelector(selectGameId);
  const loadingPlayer = useSelector(selectLoadingPlayer);
  const userId = useSelector(selectUserId);
  const player = useSelector(selectPlayer);

  const playerId = player ? player.id : null;
  const { questionId } = gameStatus;

  // ---- Effects
  useEffect(() => {
    if (gameId && playerId && questionId) {
      dispatch(watchSingleSubmissionAction(gameId, playerId, questionId));
    }

    return function cleanup() {
      dispatch(stopWatchingSubmissionAction(gameId, questionId, playerId));
    };
  }, [gameId, playerId, questionId, dispatch]);

  useEffect(() => {
    // If the game is loaded, load the player.
    if (gameId && userId) {
      dispatch(getPlayerForUserInGameAction(userId, gameId));
    }
  }, [dispatch, gameId, userId]);

  // If we don't have a game yet, show a loading screen
  let body = <div />;
  if (loadingPlayer) {
    body = (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.loading}
      >
        <CircularProgress />
      </Grid>
    );
  } else if (game && !player) {
    // If we have a game, but don't have a player, show the player join screen
    body = <MakePlayer gameId={game.id} userId={userId} />;
  } else {
    // We have the player & the game, make the gameplay screens based on state
    let gameBody = <div />;
    if (
      gameStatus &&
      gameStatus.currentState === GAMESTATE_CURRENT_ROUND &&
      gameStatus.dailyDouble
    ) {
      let myDD = gameStatus.playerInControl === player.id;
      log.debug("It's my DD: ", myDD);
      if (myDD) {
        if (gameStatus.roundState === GAMESTATE_ROUNDSTATE_QUESTION_WAGERING) {
          gameBody = <Wagering />;
        } else if (
          CLIENT_QUESTIONVIEW_ROUNDSTATES.includes(gameStatus.roundState)
        ) {
          gameBody = (
            <Question
              questionId={questionId}
              gameId={game.id}
              userId={userId}
              playerId={player.id}
            />
          );
        }
      } else {
        gameBody = (
          <Typography variant="h4" className={classes.waiting}>
            Daily Double!
          </Typography>
        );
      }
    } else if (
      gameStatus &&
      gameStatus.currentState === GAMESTATE_CURRENT_ROUND &&
      CLIENT_QUESTIONVIEW_ROUNDSTATES.includes(gameStatus.roundState)
    ) {
      gameBody = (
        <Question
          questionId={questionId}
          gameId={game.id}
          userId={userId}
          playerId={player.id}
        />
      );
    } else if (
      gameStatus &&
      gameStatus.currentState === "round" &&
      gameStatus.round !== GAMESTATE_ROUND_FINAL &&
      gameStatus.roundState === GAMESTATE_ROUNDSTATE_WAITING
    ) {
      let mePicking = gameStatus.playerInControl === player.id;
      gameBody = (
        <Typography variant="h4" className={classes.waiting}>
          {mePicking ? (
            <p>
              You're up!
              <br />
              Pick the next clue.
            </p>
          ) : (
            "Waiting for the next clue."
          )}
        </Typography>
      );
    } else if (
      gameStatus &&
      gameStatus.currentState === "round" &&
      gameStatus.round === GAMESTATE_ROUND_FINAL &&
      gameStatus.roundState === GAMESTATE_ROUNDSTATE_QUESTION_WAGERING
    ) {
      gameBody = <Wagering />;
    }

    body = (
      <div>
        {game && (
          <div>
            <Typography className={classes.header}>{game.name}</Typography>
          </div>
        )}

        <div className={classes.bodyDiv}>{gameBody}</div>
        <Divider />
        <div className={classes.bodyDiv}>
          <PlayerLeaderboard />
        </div>
      </div>
    );
  }

  return <div className={classes.root}>{body}</div>;
};

export { Game };
