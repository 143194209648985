/* eslint-disable no-undef */
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";
// import "firebase/remote-config";
import { getAuth } from "firebase/auth";

import logging from "utils/logging";
const log = logging.getLogger("Firebase Utils");

let firestoreDB;
let firebaseAuth;

const initialize = () => {
  const config = {
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };
  firebase.initializeApp(config);

  firestoreDB = firebase.firestore();
  if (process.env.REACT_APP_FIRESTORE_URL) {
    // firestoreDB.settings({
    //   host: process.env.REACT_APP_FIRESTORE_URL,
    //   ssl: false,
    // });
    firestoreDB.useEmulator(
      process.env.REACT_APP_FIRESTORE_EMULATOR_HOST,
      parseInt(process.env.REACT_APP_FIRESTORE_EMULATOR_PORT)
    );
  }

  firebaseAuth = firebase.auth();
  if (process.env.REACT_APP_EMULATOR_AUTH_URL) {
    log.info(
      `Using emulator for auth: ${process.env.REACT_APP_EMULATOR_AUTH_URL}`
    );
    firebaseAuth.useEmulator(process.env.REACT_APP_EMULATOR_AUTH_URL);
  }
};

const firestore = () => firebase.firestore;
const db = () => firebase.firestore();
const remoteConfig = () => firebase.remoteConfig();
const auth = () => firebaseAuth;
const firebaseAuthConstants = () => firebase.auth;
const storage = () => {
  const stor = firebase.storage();
  if (process.env.NODE_ENV === "development") {
    if (process.env.REACT_APP_FIREBASE_STORAGE_EMULATOR_HOST) {
      stor.useEmulator(
        process.env.REACT_APP_FIREBASE_STORAGE_EMULATOR_HOST,
        parseInt(process.env.REACT_APP_FIREBASE_STORAGE_EMULATOR_PORT)
      );
    }
  }
  return stor;
};

const functions = () => {
  const functions = firebase.functions();
  if (process.env.NODE_ENV === "development") {
    if (process.env.REACT_APP_FIREBASE_FUNCTIONS_URL) {
      functions.useEmulator(
        process.env.REACT_APP_FIREBASE_FUNCTIONS_HOST,
        parseInt(process.env.REACT_APP_FIREBASE_FUNCTIONS_PORT)
      );
      // functions.useFunctionsEmulator(
      //   process.env.REACT_APP_FIREBASE_FUNCTIONS_URL
      // );
    }
  }
  return functions;
};

function stopWatching(unsubscribe) {
  unsubscribe();
}

let api = {
  firestore,
  initialize,
  db,
  storage,
  remoteConfig,
  functions,
  auth,
  getAuth,
  authConstants: firebaseAuthConstants,
  stopWatching,
};

export default api;
