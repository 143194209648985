import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Container,
  Button,
  Typography,
  Divider,
} from "@material-ui/core";
import { Redirect, Link } from "react-router-dom";
import {
  checkRoomIdAction,
  selectDidJoinRoom,
  selectJoinedRoomId,
  selectErrorMessage,
  resetJoinStateAction,
} from "./joinRoomSlice";
import { FullBackgroundColor } from "../components/FullBackgroundColor";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
  margin: {
    margin: theme.spacing(2),
  },
  groupardyHeader: {
    textAlign: "center",
    fontFamily: "Gyparody",
    color: "#ffbf00",
    fontSize: "3em",
    textShadow: `0px 0px 0 rgb(243,179,0),
                 1px 1px 0 rgb(230,166,0),
                 2px 2px 0 rgb(218,154,0),
                 3px 3px 0 rgb(206,142,0),
                 4px 4px 0 rgb(193,129,0),
                 5px 5px 0 rgb(181,117,0),
                 6px 6px 0 rgb(168,104,0),
                 7px 7px 0 rgb(156,92,0),
                 8px 8px  0 rgb(144,80,0),
                 9px 9px 8px rgba(120,128,204,0.58),
                 9px 9px 1px rgba(120,128,204,0.5),
                 0px 0px 8px rgba(120,128,204,.2)`,
  },
  createGame: {
    margin: theme.spacing(2),
    "& a": {
      textDecoration: "none",
    },
  },
}));

const JoinRoom = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [roomCode, setRoomCode] = useState("");
  const [joining, setJoining] = useState(false);

  const didJoin = useSelector(selectDidJoinRoom);
  const joinedRoomId = useSelector(selectJoinedRoomId);
  const errorMessage = useSelector(selectErrorMessage);

  useEffect(() => {
    dispatch(resetJoinStateAction());
  }, [dispatch]);

  async function onJoinRoom() {
    setJoining(true);

    await dispatch(checkRoomIdAction(roomCode));

    setJoining(false);
  }

  return (
    <Container maxWidth="sm" className={classes.root}>
      <FullBackgroundColor color={"white"} />
      <p className={classes.groupardyHeader}>This is Groupardy!</p>
      <Typography variant="body1" gutterBottom>
        Enter your room code here:
      </Typography>
      <div>
        <TextField
          id="room-code"
          label="Room Code"
          variant="outlined"
          onChange={(e) => setRoomCode(e.target.value)}
        />
      </div>
      <div>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          disabled={joining}
          className={classes.margin}
          onClick={onJoinRoom}
        >
          {" "}
          Join Room{" "}
        </Button>
      </div>
      {errorMessage ? (
        <p>{errorMessage}</p>
      ) : (
        didJoin && <Redirect push to={`/game/${joinedRoomId}`} />
      )}
      <Divider />
      <div className={classes.createGame}>
        <Link to="/manage/create">
          <Button
            variant="contained"
            size="medium"
            disabled={joining}
            className={classes.margin}
          >
            {" "}
            New Game{" "}
          </Button>
        </Link>
      </div>
    </Container>
  );
};

export { JoinRoom };
