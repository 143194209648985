import logging from "utils/logging";
import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import GameboardLeaderboard from "./GameBoardLeaderboard";
import { SuperlativeShow } from "../../superlatives/SuperlativesShow";

const log = logging.getLogger("Final Credits"); // eslint-disable-line no-unused-vars

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  bgGradient: {
    background: `linear-gradient(145deg, #9f0e29 0%, #0b207d 50%, #940161)`,
    backgroundAttachment: "fixed",
    height: "100%",
    width: "100%",
    position: "fixed",
    top: "0px",
    left: "0px",
    zIndex: "-1",
  },
  leaderboardGrid: {
    margin: theme.spacing(3),
  },
}));

// const FinalCreditCard = ({ header, subHead, mainValue, subValue }) => {};

const FinalCredits = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.bgGradient} />
      <Grid container spacing={2} className={classes.root}>
        <Grid item md={4} className={classes.leaderboardGrid}>
          <GameboardLeaderboard />
        </Grid>
        <Grid item md>
          <SuperlativeShow />
        </Grid>
      </Grid>
    </div>
  );
};

export { FinalCredits };
