import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { buildModelReducer } from "../../redux/reducers";
import { superlativeEntity } from "../../models/superlative";
import Provider from "provider";

export const superlativeAdapter = createEntityAdapter();
const initialState = superlativeAdapter.getInitialState({
  loading: true,
});

const superlativeSlice = createSlice({
  name: "superlatives",
  initialState,
  reducers: {
    generatingSuperlatives(state) {
      state.loading = true;
    },
    loadingSuperlatives(state) {
      state.loading = true;
    },
    superlativesLoaded(state, action) {
      state.loading = false;
      // state.
      superlativeAdapter.upsertMany(state, action.payload.superlatives);
    },
  },
  extraReducers: (builder) => {
    buildModelReducer({
      builder,
      entity: superlativeEntity,
      superlativeAdapter,
      loadingKey: "loading",
    });
  },
});

const getSuperlativesAction = (gameId) => async (dispatch) => {
  dispatch(superlativeSlice.actions.loadingSuperlatives());
  let superlatives = await Provider.getSuperlatives(gameId);
  dispatch(
    superlativeSlice.actions.superlativesLoaded({
      superlatives,
    })
  );
};

export { getSuperlativesAction };

export const {
  selectById: selectSuperlativeById,
  selectIds: selectSuperlativeIds,
  selectEntities: selectSuperlativeEntities,
  selectAll: selectAllSuperlatives,
  selectTotal: selectTotalSuperlatives,
} = superlativeAdapter.getSelectors((state) => state.superlatives);

export default superlativeSlice.reducer;
