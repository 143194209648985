import React from "react";
import {
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  Link as RouterLink,
} from "react-router-dom";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ManagedGameHeader from "../components/ManagedGameHeader";
import { useSelector } from "react-redux";
import {
  selectGameId,
  selectGameObject,
} from "features/gamestate/gameStateSlice";
import EditorBoard from "features/gameEditor/EditorBoard";
import GameSettings from "features/manager/settings/GameSettings";
import GameStateChip from "../components/GameStateChip";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  listMenu: {
    flexGrow: 1,
    height: "80vh",
    overflow: "auto",
  },
  listMenuHeader: {
    fontSize: "1.2em",
  },
}));

const ManagedGameRouter = () => {
  let { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/editor`}>
        <Redirect to={`${url}/editor/round1`} />
      </Route>
      <Route path={`${path}/editor/:round`}>
        <EditorBoard />
      </Route>
      <Route path={`${path}/settings`}>
        <GameSettings />
      </Route>
    </Switch>
  );
};

const ManagedGameHome = (props) => {
  const classes = useStyles();

  const gameObject = useSelector(selectGameObject);
  const gameId = useSelector(selectGameId);

  return (
    <Container maxWidth="lg">
      <ManagedGameHeader gameObject={gameObject} />
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <List dense className={classes.listMenu}>
            <ListItem>
              <Typography variant="h6" className={classes.listMenuHeader}>
                Edit Game
              </Typography>
            </ListItem>
            <ListItem
              button
              component={RouterLink}
              to={`/manage/game/${gameId}/editor/round1`}
            >
              <ListItemText primary="Round 1" />
            </ListItem>
            <ListItem
              button
              component={RouterLink}
              to={`/manage/game/${gameId}/editor/round2`}
            >
              <ListItemText primary="Round 2" />
            </ListItem>
            <ListItem
              button
              component={RouterLink}
              to={`/manage/game/${gameId}/editor/final`}
            >
              <ListItemText primary="Final Round" />
            </ListItem>
            <Divider />
            <ListItem button>
              <ListItemText primary="Game Details" />
            </ListItem>
            <ListItem
              button
              component={RouterLink}
              to={`/manage/game/${gameId}/settings`}
            >
              <ListItemText primary="Settings" />
            </ListItem>
            <ListItem
              button
              component={RouterLink}
              target="_blank"
              to={`/manage/game/${gameId}/gameboard`}
            >
              <ListItemText primary="Game Board" />
              <OpenInNewIcon fontSize="small" />
            </ListItem>
            <ListItem
              button
              component={RouterLink}
              target="_blank"
              to={`/manage/game/${gameId}/controller`}
            >
              <ListItemText primary="Controller" />
              <OpenInNewIcon fontSize="small" />
            </ListItem>
            <Divider />
            <ListItem>
              <Typography variant="h6" className={classes.listMenuHeader}>
                Game State
              </Typography>
            </ListItem>
            <ListItem>
              <GameStateChip />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} md={10}>
          <ManagedGameRouter />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ManagedGameHome;
