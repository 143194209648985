import logging from "utils/logging";
import React from "react";
import clsx from "clsx";
import ScaleText from "react-scale-text";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  selectGameId,
  selectGameStatusObject,
} from "../../gamestate/gameStateSlice";
import { selectQuestionById } from "features/question/questionSlice";
import { beginDailyDoubleAction, beginQuestionAction } from "../gameBoardSlice";

const log = logging.getLogger("GameBoard-Cell");

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: "100%",
  },

  gameCell: {
    height: "80%",
    minHeight: "80px",
    minWidth: "165px",
    maxWidth: "400px",

    overflow: "hidden",
    // whiteSpace: "wrap",
    textOverflow: "ellipsis",

    // maxHeight: "300px",
    textAlign: "center",
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#1700a0",
    fontFamily: "Helvetica Inserat",
    fontSize: "2.5em",
    color: "white",
    letterSpacing: "3px",
    textShadow: `0px 0px 0 rgb(-14,-14,0),
                 1px 1px 0 rgb(-28,-28,0),
                 2px 2px 0 rgb(-42,-42,0),
                 3px 3px 0 rgb(-57,-57,0),
                 4px 4px 0 rgb(-71,-71,0),
                 5px 5px 0 rgb(-85,-85,0),
                 6px 6px 0 rgb(-99,-99,0),
                 7px 7px  0 rgb(-113,-113,0),
                 8px 8px 7px rgba(0,0,0,0),
                 8px 8px 1px rgba(0,0,0,0.5),
                 0px 0px 7px rgba(0,0,0,.2)`,
  },
  moneyCell: {
    minHeight: "80px",
    fontSize: "7em",
    color: "#e6a23c",
    backgroundColor: "#1700a0",
    fontFamily: "swiss911_xcm_btregular",
    cursor: "pointer",
    "&:hover": {
      background: "#2201cd",
    },
  },
  cellText: {
    height: "75px",
    justifyContent: "center",
    fontSize: "1.7em",
  },
  headerCell: {
    fontSize: "0.9em",
  },
}));

const GameBoardCell = ({ label, status, questionId }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const gameId = useSelector(selectGameId);
  const gameState = useSelector(selectGameStatusObject);
  const questionObj = useSelector((state) =>
    selectQuestionById(state, questionId)
  );

  const available =
    status === undefined || !(status === "active" || status === "completed");

  const onClick = () => {
    if (!questionId) {
      return;
    }

    if (!available) {
      return;
    }

    if (questionObj.dailyDouble) {
      log.debug("Picking the daily double");
      dispatch(beginDailyDoubleAction(gameId, gameState, questionId));
    } else {
      dispatch(beginQuestionAction(gameId, gameState, questionId));
    }
  };

  // log.debug("Rendering cell for: ", label, questionId);
  // log.debug("caps label: ", label.toUpperCase());

  return (
    <Paper
      className={clsx(
        classes.gameCell,
        questionId && available && classes.moneyCell
      )}
      onClick={onClick}
    >
      <ScaleText minFontSize={30} maxFontSize={50}>
        <span
          className={clsx(classes.cellText, !questionId && classes.headerCell)}
        >
          {available && label.toUpperCase()}
        </span>
      </ScaleText>
    </Paper>
  );
};

export default GameBoardCell;
