import logging from "utils/logging";
import utils from "./firebaseUtils";
import {
  EQUALTO,
  FIREBASE_COLLECTION_GAMES,
  FIREBASE_COLLECTION_QUESTIONS,
  FIREBASE_COLLECTION_SUBMISSIONS,
  FIREBASE_FUNCTION_NAME_COMMIT_QUESTION_SCORES,
  FIREBASE_SUBMISSION_FIELD_PLAYER_ID,
  FIREBASE_FUNCTION_NAME_PREPARE_FINAL,
  FIREBASE_FUNCTION_NAME_COMMIT_FINAL_PLAYER_SCORE,
} from "./constants";
import {
  loadDiffOfModelsForQuerySnapshot,
  loadModelForDocumentSnapshot,
} from "./firebaseModels";
import {
  SUBMISSION_STATE_PENDING,
  SUBMISSION_STATE_WAGER,
} from "../features/gamestate/constants";
const log = logging.getLogger("firebase/submission");

export const watchSubmissionsForPlayerQuestion = async (
  { gameId, playerId, questionId },
  onChange
) => {
  log.debug("Watching submissions for: ", gameId, playerId, questionId);
  const submissionCheckQuery = utils
    .db()
    .collection(FIREBASE_COLLECTION_GAMES)
    .doc(gameId)
    .collection(FIREBASE_COLLECTION_QUESTIONS)
    .doc(questionId)
    .collection(FIREBASE_COLLECTION_SUBMISSIONS)
    .where(FIREBASE_SUBMISSION_FIELD_PLAYER_ID, EQUALTO, playerId);

  return submissionCheckQuery.onSnapshot(async (qSnap) => {
    let diff = await loadDiffOfModelsForQuerySnapshot(qSnap);
    onChange(diff);
  });
};

const watchSubmissionsForQuestion = async (
  { gameId, questionId },
  onChange
) => {
  const submissionCheckQuery = utils
    .db()
    .collection(FIREBASE_COLLECTION_GAMES)
    .doc(gameId)
    .collection(FIREBASE_COLLECTION_QUESTIONS)
    .doc(questionId)
    .collection(FIREBASE_COLLECTION_SUBMISSIONS);

  return submissionCheckQuery.onSnapshot(async (qSnap) => {
    let diff = await loadDiffOfModelsForQuerySnapshot(qSnap);
    onChange(diff);
  });
};

const getSubmissions = async (gameId, questionId, playerId) => {
  const submissionCheckQuery = utils
    .db()
    .collection(FIREBASE_COLLECTION_GAMES)
    .doc(gameId)
    .collection(FIREBASE_COLLECTION_QUESTIONS)
    .doc(questionId)
    .collection(FIREBASE_COLLECTION_SUBMISSIONS)
    .where(FIREBASE_SUBMISSION_FIELD_PLAYER_ID, EQUALTO, playerId);

  const submissionQuerySnap = await submissionCheckQuery.get();

  return await loadDiffOfModelsForQuerySnapshot(submissionQuerySnap);
};

export const submitQuestionAction = async (
  gameId,
  questionId,
  playerId,
  answer
) => {
  const submissions = await getSubmissions(gameId, questionId, playerId);

  log.info("Got submissions before submitting: ", submissions);

  let submission = null;

  if (submissions.added.length === 1) {
    submission = submissions.added[0];
    if (submission.answer) {
      return submissions.added[0];
    }

    const submissionRef = utils
      .db()
      .collection(FIREBASE_COLLECTION_GAMES)
      .doc(gameId)
      .collection(FIREBASE_COLLECTION_QUESTIONS)
      .doc(questionId)
      .collection(FIREBASE_COLLECTION_SUBMISSIONS)
      .doc(submission.id);

    await submissionRef.update({
      answer,
      status: SUBMISSION_STATE_PENDING,
      points: 0,
      submittedAt: utils.firestore().FieldValue.serverTimestamp(),
    });

    const docSnap = await submissionRef.get();

    submission = await loadModelForDocumentSnapshot(docSnap);

    log.info("Updated submission: ", submission);

    return submission;
  } else if (submissions.added.length > 1) {
    log.error("Too many matching subsmissions!");
    return submissions.added[0];
  } else {
    // This is a new submission
    const submissionRef = utils
      .db()
      .collection(FIREBASE_COLLECTION_GAMES)
      .doc(gameId)
      .collection(FIREBASE_COLLECTION_QUESTIONS)
      .doc(questionId)
      .collection(FIREBASE_COLLECTION_SUBMISSIONS);

    let submissionDoc = {
      playerId,
      answer,
      status: SUBMISSION_STATE_PENDING,
      points: 0,
      submittedAt: utils.firestore().FieldValue.serverTimestamp(),
    };

    const docRef = await submissionRef.add(submissionDoc);

    const docSnap = await docRef.get();

    submission = await loadModelForDocumentSnapshot(docSnap);

    log.info("Created submission: ", submission);

    return submission;
  }
};

const submitWager = async (gameId, questionId, playerId, wager) => {
  const submissions = await getSubmissions(gameId, questionId, playerId);

  log.info("Got subsmissions before submitting: ", submissions);

  if (submissions.added.length === 1) {
    return submissions.added[0];
  } else if (submissions.added.length > 1) {
    log.error("Too many matching subsmissions!");
    return submissions.added[0];
  }

  const submissionRef = utils
    .db()
    .collection(FIREBASE_COLLECTION_GAMES)
    .doc(gameId)
    .collection(FIREBASE_COLLECTION_QUESTIONS)
    .doc(questionId)
    .collection(FIREBASE_COLLECTION_SUBMISSIONS);

  const submissionDoc = {
    playerId,
    wager,
    status: SUBMISSION_STATE_WAGER,
    points: 0,
  };

  const docRef = await submissionRef.add(submissionDoc);

  const docSnap = await docRef.get();

  const submission = await loadModelForDocumentSnapshot(docSnap);

  log.info("Created submission: ", submission);

  return submission;
};

const updateSubmission = async (
  gameId,
  questionId,
  submissionId,
  newState,
  newPoints
) => {
  const submissionRef = utils
    .db()
    .collection(FIREBASE_COLLECTION_GAMES)
    .doc(gameId)
    .collection(FIREBASE_COLLECTION_QUESTIONS)
    .doc(questionId)
    .collection(FIREBASE_COLLECTION_SUBMISSIONS)
    .doc(submissionId);

  if (!newPoints) {
    newPoints = 0;
  }

  await submissionRef.update({
    status: newState,
    points: newPoints,
  });
};

const commitSubmissionScoresToLeaderboard = async (gameId, questionId) => {
  const commitScores = utils
    .functions()
    .httpsCallable(FIREBASE_FUNCTION_NAME_COMMIT_QUESTION_SCORES);
  const result = await commitScores({ gameId, questionId });
  log.debug("Got result from committing scores:  ", result);
  return result;
};

const prepareFinalReveals = async (gameId) => {
  const prepForFinal = utils
    .functions()
    .httpsCallable(FIREBASE_FUNCTION_NAME_PREPARE_FINAL);
  const result = await prepForFinal({ gameId });
  log.debug("Prepared final scores: ", result);
  return result;
};

const commitFinalPlayerScore = async (
  gameId,
  playerId,
  submissionId,
  questionId
) => {
  const commitScore = utils
    .functions()
    .httpsCallable(FIREBASE_FUNCTION_NAME_COMMIT_FINAL_PLAYER_SCORE);
  const result = await commitScore({
    gameId,
    playerId,
    questionId,
    submissionId,
  });
  log.debug("Got result from committing score:  ", result);
  return result;
};

const api = {
  submitQuestionAction,
  submitWager,
  watchSubmissionsForPlayerQuestion,
  watchSubmissionsForQuestion,
  updateSubmission,
  commitSubmissionScoresToLeaderboard,
  commitFinalPlayerScore,
  prepareFinalReveals,
};

export default api;
