import utils from "./firebaseUtils";
import {
  FIREBASE_COLLECTION_GAMES,
  FIREBASE_GAME_FIELD_ROOMID,
  FIREBASE_GAME_FIELD_MANAGERID,
  EQUALTO,
  FIREBASE_FUNCTION_NAME_RESET_GAME,
  FIREBASE_FUNCTION_COPY_GAME,
  FIREBASE_FUNCTION_CREATE_GAME,
} from "./constants";
import {
  loadModelForDocumentSnapshot,
  loadModelsForQuerySnapshot,
  loadDiffOfModelsForQuerySnapshot,
} from "./firebaseModels";

import logging from "utils/logging";

const log = logging.getLogger("firebase/game");

const createFullGame = async (gameName, categoryCount, managerId) => {
  // Call the cloud function to do this. Get the new game ID back and return it.
  const createGameFunc = utils
    .functions()
    .httpsCallable(FIREBASE_FUNCTION_CREATE_GAME);
  const result = await createGameFunc({
    name: gameName,
    categoryCount,
    managerId,
  });
  log.info(`Created game called "${gameName}" with result:`, result);
  return result.data.gameId;
};

const resetGame = async (gameId) => {
  const resetGameFunc = utils
    .functions()
    .httpsCallable(FIREBASE_FUNCTION_NAME_RESET_GAME);
  const result = await resetGameFunc({ gameId });
  log.info("Got result from resetting game: ", result);
  return result;
};

const copyGame = async (gameId, newGameName) => {
  const copyGameFunc = utils
    .functions()
    .httpsCallable(FIREBASE_FUNCTION_COPY_GAME);
  const result = await copyGameFunc({ gameId, name: newGameName });
  log.info("Copied game: ", result);
  return result;
};

const getGameForRoomId = async (roomId) => {
  const roomRef = utils
    .db()
    .collection(FIREBASE_COLLECTION_GAMES)
    .where(FIREBASE_GAME_FIELD_ROOMID, EQUALTO, roomId);

  const querySnap = await roomRef.get();

  const rooms = await loadModelsForQuerySnapshot(querySnap);
  if (rooms.length === 0) {
    return null;
  } else if (rooms.length === 1) {
    return rooms[0];
  } else {
    log.error("Found multiple rooms with the same roomId");
    log.info("Games: ", rooms);
    return null;
  }
};

const watchGame = async (gameId, onChange) => {
  const gameRef = utils.db().collection(FIREBASE_COLLECTION_GAMES).doc(gameId);

  return gameRef.onSnapshot(async (gameSnap) => {
    log.info("Game updated: ", gameSnap.data());
    let game = await loadModelForDocumentSnapshot(gameSnap);
    log.info("Updated data: ", game);
    onChange(game);
  });
};

const watchManagedGames = async (userId, onChange) => {
  const gameQuery = utils
    .db()
    .collection(FIREBASE_COLLECTION_GAMES)
    .where(FIREBASE_GAME_FIELD_MANAGERID, "==", userId);

  return gameQuery.onSnapshot(async (gameQuerySnap) => {
    let diff = await loadDiffOfModelsForQuerySnapshot(gameQuerySnap);
    log.debug("Got diffed games: ", diff);
    onChange(diff);
  });
};

const watchGameForRoomId = async (roomId, onChange) => {
  let game = await getGameForRoomId(roomId);

  if (game === null) {
    return null;
  }

  const gameRef = utils.db().collection(FIREBASE_COLLECTION_GAMES).doc(game.id);

  return gameRef.onSnapshot(async (gameSnap) => {
    let game = await loadModelForDocumentSnapshot(gameSnap);
    onChange(game);
  });
};

const updateGameState = async (gameId, newState) => {
  let gameRef = utils.db().collection(FIREBASE_COLLECTION_GAMES).doc(gameId);

  await gameRef.update({ state: newState });
};

const api = {
  resetGame,
  copyGame,
  getRoom: getGameForRoomId,
  watchGame,
  watchManagedGames,
  watchGameForRoomId,
  updateGameState,
  createGame: createFullGame,
};

export default api;
