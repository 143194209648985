import React from "react";
import logging from "utils/logging";
import { makeStyles, Typography, Fade } from "@material-ui/core";
import FinalCategoryCell from "./FinalCategoryCell";
import { useSelector, useDispatch } from "react-redux";
import {
  selectGameObject,
  selectGameStatusObject,
} from "../../gamestate/gameStateSlice";
import { selectAllPlayers } from "../../player/playerSlice";
import {
  GAMESTATE_ROUND_FINAL,
  GAMESTATE_ROUNDSTATE_QUESTION_WAGERING,
} from "../../gamestate/constants";
import { selectCurrentQuestionObject } from "../../question/questionSlice";
import { advanceState } from "../../gamestate/GameState";

const log = logging.getLogger("FinalRoundBoard");

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "black",
    color: "white",
    width: "100%",
    // height: "100%",
    textAlign: "center",
    fontFamily: "Gyparody",
  },
}));

const FinalRoundBoard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const gameState = useSelector(selectGameStatusObject);
  const gameObj = useSelector(selectGameObject);
  const players = useSelector(selectAllPlayers);
  const question = useSelector(selectCurrentQuestionObject);

  if (!question) {
    log.debug("No question found for final round");
    return <div />;
  }

  const showCategory =
    gameState.round === GAMESTATE_ROUND_FINAL &&
    gameState.roundState === GAMESTATE_ROUNDSTATE_QUESTION_WAGERING;

  const openQuestion = async () => {
    await advanceState(gameObj, players, dispatch);
  };

  log.debug("Round: ", showCategory);
  return (
    <div className={classes.root}>
      <Typography variant="h1" className={classes.root}>
        Final
        <br />
        Round!
      </Typography>
      <Fade timeout={500} in={showCategory}>
        <FinalCategoryCell label={question.category} onClick={openQuestion} />
      </Fade>
    </div>
  );
};

export { FinalRoundBoard };
