import logging from "utils/logging";
import _ from "lodash";
import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  selectGameId,
  selectGameStatusObject,
} from "../gamestate/gameStateSlice";
import { selectPlayerEntities, selectPlayer } from "../player/playerSlice";
import { GAMESTATE_CURRENT_ROUND } from "../gamestate/constants";
import { submissionEntity } from "../../models/submission";
import {
  createCloseListenerAction,
  createOpenListenerAction,
  modelsUpdated,
} from "../../redux/actions";
import Provider from "../../provider";
import { buildModelReducer } from "../../redux/reducers";

const log = logging.getLogger("submission-slice");

const submissionAdaptor = createEntityAdapter();
const initialState = submissionAdaptor.getInitialState({
  loadingSubmissions: true,
});

const submissionSlice = createSlice({
  name: "submissions",
  initialState,
  reducers: {
    watchSubmissions(state) {
      state.loadingSubmissions = true;
    },
    submissionLoaded(state, action) {
      state.loadingSubmissions = false;
      submissionAdaptor.upsertOne(state, action.payload.submission);
    },
  },
  extraReducers: (builder) => {
    buildModelReducer({
      builder,
      entity: submissionEntity,
      loadingKey: "loadingSubmissions",
      adapter: submissionAdaptor,
    });
  },
});

export const { submissionLoaded } = submissionSlice.actions;

const watchSubmissionActionBase = createOpenListenerAction(
  "game/watch_single_submission",
  "watch-submission"
);

const stopWatchingSubmissionActionBase = createCloseListenerAction(
  "game/stop_watching_single_submission",
  "watch-submission"
);

const watchSingleSubmissionAction =
  (gameId, playerId, questionId) => async (dispatch) => {
    dispatch(submissionSlice.actions.watchSubmissions());
    dispatch(
      watchSubmissionActionBase({ gameId, playerId, questionId }, async () => {
        log.info(
          "watching submissions for game/player/question",
          gameId,
          playerId,
          questionId
        );
        await Provider.watchSubmissionsForPlayerQuestion(
          { gameId, playerId, questionId },
          (submissionsUpdate) => {
            log.info("Got submissions update: ", submissionsUpdate);
            dispatch(modelsUpdated(submissionsUpdate, submissionEntity));
          }
        );
      })
    );
  };

const stopWatchingSubmissionAction =
  (gameId, questionId, playerId) => async (dispatch) => {
    dispatch(
      stopWatchingSubmissionActionBase({ gameId, questionId, playerId })
    );
  };

const watchQuestionSubmissionsActionBase = createOpenListenerAction(
  "game/watch_question_submissions",
  "watch-question-submissions"
);

const stopWatchingQuestionSubmissionsActionBase = createCloseListenerAction(
  "game/stop_watching_question_submissions",
  "watch-question-submissions"
);

const watchQuestionSubmissionsAction = (gameId, questionId) => (dispatch) => {
  dispatch(submissionSlice.actions.watchSubmissions());
  dispatch(
    watchQuestionSubmissionsActionBase({ gameId }, async () => {
      log.info("watching submissions for game/question", gameId, questionId);
      await Provider.watchSubmissionsForQuestion(
        { gameId, questionId },
        (submissionsUpdate) => {
          log.info("Got submissions update: ", submissionsUpdate);
          dispatch(modelsUpdated(submissionsUpdate, submissionEntity));
        }
      );
    })
  );
};

const stopWatchingQuestionSubmissionsAction =
  (gameId, questionId) => (dispatch) => {
    dispatch(stopWatchingQuestionSubmissionsActionBase({ gameId, questionId }));
  };

/* ------ Selectors ---- */

const selectSubmissionsState = (state) => state.submissions;
const selectLoadingSubmissions = createSelector(
  selectSubmissionsState,
  (s) => s.loadingSubmissions
);

export const {
  selectById: selectSubmissionById,
  selectIds: selectSubmissionIds,
  selectEntities: selectSubmissionEntities,
  selectAll: selectAllSubmissions,
  selectTotal: selectTotalSubmissions,
} = submissionAdaptor.getSelectors((state) => state.submissions);

const selectCurrentSubmission = createSelector(
  [
    selectSubmissionsState,
    selectGameId,
    selectGameStatusObject,
    selectPlayer,
    selectSubmissionEntities,
  ],
  (submissionsState, gameId, gameState, player, entities) => {
    if (
      gameState.currentState !== GAMESTATE_CURRENT_ROUND ||
      !gameState.questionId
    ) {
      return null;
    }

    log.info("Got entities: ", entities, submissionsState);

    let subs = Object.values(entities).filter(
      (e) => e.playerId === player.id && e.questionId === gameState.questionId
    );

    log.info("Submissions that match 'current submission'", subs);

    return subs[0];
  }
);

const selectPlayerSubmissionsForJudging = createSelector(
  [
    selectGameId,
    selectGameStatusObject,
    selectSubmissionEntities,
    selectPlayerEntities,
  ],
  (gameId, gameState, submissionEntities, players) => {
    if (
      gameState.currentState !== GAMESTATE_CURRENT_ROUND ||
      !gameState.questionId ||
      Object.keys(players).length === 0
    ) {
      return null;
    }

    const playersCopy = _.cloneDeep(players);

    // Get all the submissions for this question
    let subs = Object.values(submissionEntities).filter(
      (e) => e.questionId === gameState.questionId
    );
    // log.info("PlayersCopy: ", playersCopy);
    // log.info("Submissions: ", subs);

    subs.forEach((submission) => {
      playersCopy[submission.playerId].submission = submission;
    });

    return _.sortBy(Object.values(playersCopy), ["submission.submittedAt"]);
  }
);

const selectPlayerSubmissionsForGameboardLeaderboard = createSelector(
  [
    selectGameId,
    selectGameStatusObject,
    selectSubmissionEntities,
    selectPlayerEntities,
  ],
  (gameId, gameState, submissionEntities, players) => {
    if (Object.keys(players).length === 0) {
      return null;
    }

    const playersCopy = _.cloneDeep(players);

    // Get all the submissions for this question
    let subs = Object.values(submissionEntities).filter(
      (e) => e.questionId === gameState.questionId
    );
    // log.info("PlayersCopy: ", playersCopy);
    // log.info("Submissions: ", subs);

    subs.forEach((submission) => {
      playersCopy[submission.playerId].submission = submission;
    });

    return _.sortBy(Object.values(playersCopy), (p) => -p.score);
  }
);

const selectPlayerSubmissionsForFinalReveals = createSelector(
  selectPlayerSubmissionsForGameboardLeaderboard,
  (playerSubmissions) => {
    log.debug("Player submissions: ", playerSubmissions);
    return _.sortBy(playerSubmissions, (p) => p.preFinalScore);
  }
);

const selectActiveDDSubmission = createSelector(
  (state) => state,
  selectGameStatusObject,
  selectSubmissionEntities,
  (state, gameState, entities) => {
    const playerId = gameState.playerInControl;

    if (!playerId) {
      return null;
    }

    let subs = Object.values(entities).filter(
      (e) => e.playerId === playerId && e.questionId === gameState.questionId
    );

    log.info("Submissions that match 'current submission'", subs);

    return subs[0];
  }
);

export {
  selectLoadingSubmissions,
  selectCurrentSubmission,
  selectPlayerSubmissionsForJudging,
  selectPlayerSubmissionsForGameboardLeaderboard,
  selectPlayerSubmissionsForFinalReveals,
  selectActiveDDSubmission,
  watchSingleSubmissionAction,
  stopWatchingSubmissionAction,
  watchQuestionSubmissionsAction,
  stopWatchingQuestionSubmissionsAction,
};

export default submissionSlice.reducer;
