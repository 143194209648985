import logging from "utils/logging";
import * as Constants from "./constants";
import Provider from "../../provider";
import { makeQuestionAvailableAction } from "../question/questionSlice";
import { updateGameStateAction } from "./stateActions";
import _ from "lodash";

const log = logging.getLogger("Game State"); // eslint-disable-line no-unused-vars

const getNextState = (gameObject, players) => {
  const { state: gameState } = gameObject;

  let nextState = { ...gameState };
  switch (gameState.currentState) {
    case Constants.GAMESTATE_CURRENT_LOBBY:
      let nextRoundMap = {
        [Constants.GAMESTATE_ROUND_PRACTICE]: Constants.GAMESTATE_ROUND_1,
        [Constants.GAMESTATE_ROUND_1]: Constants.GAMESTATE_ROUND_2,
        [Constants.GAMESTATE_ROUND_2]: Constants.GAMESTATE_ROUND_FINAL,
      };
      let nextRound = Constants.GAMESTATE_ROUND_PRACTICE;
      if (gameState.round) {
        nextRound = nextRoundMap[gameState.round];
      }
      nextState = {
        currentState: Constants.GAMESTATE_CURRENT_ROUND,
        round: nextRound,
        roundState: Constants.GAMESTATE_ROUNDSTATE_WAITING,
      };

      if (nextRound === Constants.GAMESTATE_ROUND_1) {
        // Pick a random player to pick the category
        let randomPlayer = _.sample(players);
        log.debug("Random player: ", players, randomPlayer);
        if (randomPlayer) {
          nextState[Constants.GAMESTATE_FIELD_CONTROLLING_PLAYER] =
            randomPlayer.id;
        }
      } else if (nextRound === Constants.GAMESTATE_ROUND_2) {
        let lastPlayer = _.head(_.sortBy(players, ["score"]));
        // Pick the player in last place to pick the category
        if (lastPlayer) {
          nextState[Constants.GAMESTATE_FIELD_CONTROLLING_PLAYER] =
            lastPlayer.id;
        }
      } else if (nextRound === Constants.GAMESTATE_ROUND_FINAL) {
        log.debug(
          "Setting the question for the final round: ",
          gameObject.categories.final.question
        );
        nextState[Constants.GAMESTATE_FIELD_CURRENT_QUESTION] =
          gameObject.categories.final.question;
      }
      break;
    case Constants.GAMESTATE_CURRENT_ROUND:
      switch (gameState.roundState) {
        case Constants.GAMESTATE_ROUNDSTATE_WAITING:
          if (gameState.round === Constants.GAMESTATE_ROUND_FINAL) {
            const questionId = gameObject.categories.final.question;
            nextState = {
              ...gameState,
              roundState: Constants.GAMESTATE_ROUNDSTATE_QUESTION_WAGERING,
              questionId,
            };
          }
          break;
        case Constants.GAMESTATE_ROUNDSTATE_DAILYDOUBLE_PICKED:
          nextState = {
            ...gameState,
            roundState: Constants.GAMESTATE_ROUNDSTATE_QUESTION_WAGERING,
          };
          break;
        case Constants.GAMESTATE_ROUNDSTATE_QUESTION_WAGERING:
          nextState = {
            ...gameState,
            roundState: Constants.GAMESTATE_ROUNDSTATE_QUESTION_READING,
          };
          break;
        case Constants.GAMESTATE_ROUNDSTATE_QUESTION_READING:
          const endTime = new Date();
          let questionTime = Constants.QUESTION_TIME_SECONDS;
          if (gameState.round === Constants.GAMESTATE_ROUND_FINAL) {
            questionTime = Constants.FINAL_ROUND_QUESTION_TIME_SECONDS;
          }
          log.debug(`Allowing ${questionTime} seconds for answer`);
          endTime.setSeconds(endTime.getSeconds() + questionTime);
          nextState = {
            ...gameState,
            roundState: Constants.GAMESTATE_ROUNDSTATE_QUESTION_ANSWERING,
            endTime: endTime.toJSON(),
          };
          break;
        case Constants.GAMESTATE_ROUNDSTATE_QUESTION_ANSWERING:
          nextState = {
            ...gameState,
            roundState: Constants.GAMESTATE_ROUNDSTATE_QUESTION_JUDGING,
          };
          break;
        case Constants.GAMESTATE_ROUNDSTATE_QUESTION_JUDGING:
          nextState = {
            ...gameState,
            roundState: Constants.GAMESTATE_ROUNDSTATE_QUESTION_RESULTS,
          };
          if (gameState.round === Constants.GAMESTATE_ROUND_FINAL) {
            nextState.roundState =
              Constants.GAMESTATE_ROUNDSTATE_QUESTION_REVEALING;
          }
          break;
        case Constants.GAMESTATE_ROUNDSTATE_QUESTION_RESULTS:
          nextState = {
            currentState: Constants.GAMESTATE_CURRENT_ROUND,
            round: gameState.round,
            roundState: Constants.GAMESTATE_ROUNDSTATE_WAITING,
            questionId: null,
            playerInControl: gameState.playerInControl,
          };
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }
  return nextState;
};

export const advanceState = async (gameObject, players, dispatch) => {
  log.debug("Advancing state for game: ", gameObject);
  let newState = getNextState(gameObject, players);
  const gameId = gameObject.id;
  log.debug("Clicked game state: ", newState, gameId);
  if (
    newState.roundState === Constants.GAMESTATE_ROUNDSTATE_QUESTION_REVEALING
  ) {
    await Provider.prepareFinalReveals(gameId);
    await Provider.generateSuperlatives(gameId);
  } else if (
    newState.roundState === Constants.GAMESTATE_ROUNDSTATE_QUESTION_RESULTS
  ) {
    // If we're about to show results, save the score differences
    log.debug("Committing scores to leaderboard");
    const { data } = await Provider.commitSubmissionScoresToLeaderboard(
      gameId,
      newState.questionId
    );
    log.debug("Got new player in control: ", data);
    newState["playerInControl"] = data.playerInControl;
  } else if (
    newState.roundState === Constants.GAMESTATE_ROUNDSTATE_QUESTION_ANSWERING
  ) {
    log.info("Opening question for answers: ", newState);
    dispatch(
      makeQuestionAvailableAction(gameId, newState.questionId, newState.endTime)
    );
  }

  log.info("Setting new game state: ", newState);
  dispatch(updateGameStateAction(gameId, newState));
};
