import React from "react";
import firebaseUtils from "../firebaseUtils";
import { StyledFirebaseAuth } from "react-firebaseui";
import logging from "utils/logging";
const log = logging.getLogger("Firebase Auth Component");

const FirebaseAuth = () => {
  const fbAuth = firebaseUtils.getAuth();
  const authConstants = firebaseUtils.authConstants();

  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    // signInSuccessUrl: "/manage",
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      authConstants.EmailAuthProvider.PROVIDER_ID,
      // authConstants.GoogleAuthProvider.PROVIDER_ID,
      // authConstants.FacebookAuthProvider.PROVIDER_ID,
    ],
  };

  log.debug("Built config");

  return (
    <div>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={fbAuth} />
    </div>
  );
};

export default FirebaseAuth;
