import logging from "utils/logging";
import React, { useEffect, useState } from "react";
import PodiumGrid, { PodiumSignature } from "../../lobby/PodiumGrid";
import { FinalQuestionRevealerModal } from "./FinalQuestionRevealerModal";
import { makeStyles, Typography, Box, Fab } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPlayerSubmissionsForFinalReveals,
  stopWatchingQuestionSubmissionsAction,
  watchQuestionSubmissionsAction,
} from "features/question/submissionSlice";
import {
  selectGameId,
  selectGameStatusObject,
} from "../../gamestate/gameStateSlice";
import Provider from "provider";
import { FinalRoundPodiumScreen } from "./FinalRoundPodiumScreen";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { GAMESTATE_CURRENT_CREDITS } from "../../gamestate/constants";
import { updateGameStateAction } from "../../gamestate/stateActions";

const log = logging.getLogger("Final Round Podiums");

const useStyles = makeStyles((theme) => ({
  header: {
    background: "transparent",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    // width: "100%",
    height: "125px",
  },
  headerSpacer: {
    height: "125px",
  },
  h1Text: {
    paddingTop: theme.spacing(2),
    textAlign: "center",
    width: "100%",
    fontFamily: "Gyparody",
    color: "#ffbf00",
    fontSize: "5em",
    textShadow: `0px 0px 0 rgb(243,179,0),
                 1px 1px 0 rgb(230,166,0),
                 2px 2px 0 rgb(218,154,0),
                 3px 3px 0 rgb(206,142,0),
                 4px 4px 0 rgb(193,129,0),
                 5px 5px 0 rgb(181,117,0),
                 6px 6px 0 rgb(168,104,0),
                 7px 7px 0 rgb(156,92,0),
                 8px 8px  0 rgb(144,80,0),
                 9px 9px 8px rgba(120,128,204,0.58),
                 9px 9px 1px rgba(120,128,204,0.5),
                 0px 0px 8px rgba(120,128,204,.2)`,
  },
  fab: {
    position: "fixed",
    right: theme.spacing(4),
    top: theme.spacing(4),
    backgroundColor: "#2a07fa",
    "&:hover": {
      backgroundColor: "#4829ff",
    },
  },
  fabIcon: {
    marginRight: theme.spacing(1),
  },
}));

const FinalRoundPodiums = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [highlightedPlayer, setHighlightedPlayer] = useState(null);
  const gameStateObj = useSelector(selectGameStatusObject);
  const gameId = useSelector(selectGameId);
  const questionId = gameStateObj.questionId;
  const players = useSelector(selectPlayerSubmissionsForFinalReveals);

  useEffect(() => {
    if (gameId && questionId) {
      dispatch(watchQuestionSubmissionsAction(gameId, questionId));
    }

    return function cleanup() {
      if (gameId && questionId) {
        dispatch(stopWatchingQuestionSubmissionsAction(gameId, questionId));
      }
    };
  }, [dispatch, questionId, gameId]);

  const onClickPlayer = (player) => {
    log.debug("Clicked player: ", player);
    setHighlightedPlayer(player);
  };

  const onCloseModal = () => {
    log.debug("Modal closed");
    setHighlightedPlayer(null);
  };

  log.debug("Highlighted player: ", highlightedPlayer);

  const onCommit = async (playerSubmission) => {
    log.debug(
      "Commit score for ",
      playerSubmission.id,
      playerSubmission.submission.id
    );
    await Provider.commitFinalPlayerScore(
      gameId,
      playerSubmission.id,
      playerSubmission.submission.id,
      questionId
    );
  };

  const onEndGame = () => {
    dispatch(
      updateGameStateAction(gameId, {
        currentState: GAMESTATE_CURRENT_CREDITS,
      })
    );
  };

  const highlightModal = (
    <FinalQuestionRevealerModal
      player={highlightedPlayer}
      open={highlightedPlayer !== null}
      onCloseModal={onCloseModal}
      onFinish={() => onCommit(highlightedPlayer)}
    />
  );

  /**
   * When a player is selected show their modal
   * - In the modal...
   * -- When clicked, show the answer
   * -- When clicked again, show the wager
   * -- When clicked again, show their final score
   * -- When clicked again, put that frame onto the podium and...
   * -- Update the player's "score" officially so leaderboards all update
   * -- Update the submission status to "revealed"
   * ----- On that players screen, you can now show the correct/incorrect and their final score
   * -- Their podium score should show their score (this is automatic)

   * -- When the reveals are all complete, pull up the leaderboard
   * ----- Also play the post-final jeop music
   * ----- Show the correct answer on each player's screen
   */

  const qaScreen = (player) => {
    let showSignature = !player.submission || !player.submission.answer;
    if (player.submission) {
      showSignature = !player.submission.revealed;
    }
    if (showSignature) {
      return <PodiumSignature player={player} />;
    }
    const answer = (player.submission && player.submission.answer) ?? "";
    const wager = (player.submission && player.submission.wager) ?? 0;
    return (
      <div style={{ fontSize: "1.2em" }}>
        <FinalRoundPodiumScreen
          answer={answer}
          wager={wager}
          showCorrect={false}
          showWager={true}
          showAnswer={true}
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className={classes.headerSpacer} />
      <PodiumGrid
        players={players}
        onClickPodium={onClickPlayer}
        podiumScreenRenderer={qaScreen}
      />
      <Box className={classes.header}>
        <Typography variant="h1" className={classes.h1Text}>
          Final Round
        </Typography>
      </Box>
      <Fab
        className={classes.fab}
        variant="extended"
        color="primary"
        onClick={onEndGame}
      >
        <CheckCircleIcon className={classes.fabIcon} />
        End Game
      </Fab>
      {highlightedPlayer && highlightModal}
    </React.Fragment>
  );
};

export { FinalRoundPodiums };
