import logging from "utils/logging";
import React, { useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as Constants from "features/gamestate/constants";
import {
  selectGameId,
  selectGameObject,
  selectGameStatusObject,
} from "../../gamestate/gameStateSlice";
import { selectAllPlayers } from "features/player/playerSlice";
import { updateGameStateAction } from "../../gamestate/stateActions";
import Provider from "provider";
import { advanceState } from "../../gamestate/GameState";

const log = logging.getLogger("GameStateButton"); //eslint-disable-line no-unused-vars

const StateButton = () => {
  const dispatch = useDispatch();

  const gameObj = useSelector(selectGameObject);
  const gameState = useSelector(selectGameStatusObject);
  const players = useSelector(selectAllPlayers);

  const [pending, setPending] = useState(false);

  let disabled = true;
  let copy = "Button!";

  switch (gameState.currentState) {
    case Constants.GAMESTATE_CURRENT_LOBBY:
      disabled = false;
      copy = "Start Next Round";
      if (!gameState.round) {
        copy = "Start Game";
      }
      break;
    case Constants.GAMESTATE_CURRENT_ROUND:
      switch (gameState.roundState) {
        case Constants.GAMESTATE_ROUNDSTATE_WAITING:
          if (gameState.round === Constants.GAMESTATE_ROUND_FINAL) {
            disabled = false;
            copy = "Reveal Category & Open Wagering";
          } else {
            disabled = true;
            copy = "Choose a Question on the Board";
          }
          break;
        case Constants.GAMESTATE_ROUNDSTATE_QUESTION_READING:
          disabled = false;
          copy = "Begin Accepting Answers";
          break;
        case Constants.GAMESTATE_ROUNDSTATE_QUESTION_ANSWERING:
          disabled = false;
          copy = "Stop Accepting Answers";
          break;
        case Constants.GAMESTATE_ROUNDSTATE_QUESTION_JUDGING:
          disabled = false;
          copy = "Reveal Results";
          if (gameState.round === Constants.GAMESTATE_ROUND_FINAL) {
            copy = "Show Podiums for Revealing Results";
          }
          break;
        case Constants.GAMESTATE_ROUNDSTATE_QUESTION_RESULTS:
          disabled = false;
          copy = "Close Results";
          break;
        case Constants.GAMESTATE_ROUNDSTATE_FINALROUND_CATEGORY_PREVIEW:
          disabled = false;
          copy = "Open Wagering";
          break;
        case Constants.GAMESTATE_ROUNDSTATE_QUESTION_WAGERING:
          disabled = false;
          copy = "Close Wagering and Reveal Question";
          break;
        case Constants.GAMESTATE_ROUNDSTATE_QUESTION_REVEALING:
          disabled = false;
          copy = "Reveal All";
          break;
        default:
          disabled = true;
          copy = `Unknown State: ${gameState.roundState}`;
          break;
      }
      break;
    default:
      break;
  }

  const onClickStateButton = async () => {
    setPending(true);
    await advanceState(gameObj, players, dispatch);
    setPending(false);
  };

  return (
    <Button
      size="large"
      disabled={disabled || pending}
      variant="contained"
      color="primary"
      onClick={onClickStateButton}
    >
      {copy}
      {pending && <CircularProgress size={20} />}
    </Button>
  );
};

const PauseButton = () => {
  const dispatch = useDispatch();
  const gameState = useSelector(selectGameStatusObject);
  const gameId = useSelector(selectGameId);

  const onClick = () => {
    const { currentState, ...rest } = gameState;
    const nextState = {
      ...rest,
      currentState: "paused",
      lastState: currentState,
    };

    dispatch(updateGameStateAction(gameId, nextState));
  };

  return (
    <Button size="large" variant="contained" onClick={onClick}>
      Pause
    </Button>
  );
};

const EndRoundButton = () => {
  const dispatch = useDispatch();
  const gameState = useSelector(selectGameStatusObject);
  const gameId = useSelector(selectGameId);

  const onClick = async () => {
    const { round, ...rest } = gameState; //eslint-disable-line no-unused-vars
    const nextState = {
      round,
      currentState: "lobby",
    };

    if (round === Constants.GAMESTATE_ROUND_PRACTICE) {
      await Provider.resetScores(gameId);
    }

    dispatch(updateGameStateAction(gameId, nextState));
  };

  return (
    <Button size="large" variant="contained" onClick={onClick}>
      End Round
    </Button>
  );
};

export { StateButton, PauseButton, EndRoundButton };
