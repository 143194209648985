import React, { useState } from "react";
import {
  Button,
  makeStyles,
  Snackbar,
  Dialog,
  TextField,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import Provider from "provider";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectGameId } from "features/gamestate/gameStateSlice";
import logging from "utils/logging";

const log = logging.getLogger("Game Settings");

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(2),
  },
}));

const CopyGameButton = () => {
  const classes = useStyles();

  const gameId = useSelector(selectGameId);
  const history = useHistory();

  const [copying, setCopying] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [newGameName, setNewGameName] = useState("");

  const closeConfirm = () => setConfirmOpen(false);

  const copyGame = async () => {
    setCopying(true);
    setConfirmOpen(false);
    const result = await Provider.copyGame(gameId, newGameName);
    log.debug("copy game result: ", result);

    setCopying(false);
    const newGameId = result.data.gameId;
    // Redirect to the new game
    history.push(`/manage/game/${newGameId}/editor`);
  };

  return (
    <React.Fragment>
      <Button
        className={classes.button}
        size="large"
        variant="contained"
        onClick={() => setConfirmOpen(true)}
        color="primary"
        disabled={copying}
      >
        Copy Game
      </Button>
      <Dialog
        open={confirmOpen}
        onClose={closeConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will create a new game with all the questions from this game.
            <br />
            <br />
            What would you like to call your new game?
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="copied-game-name"
            label="New Game Name"
            type="text"
            fullWidth
            onChange={(v) => setNewGameName(v.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirm} color="primary">
            Cancel
          </Button>
          <Button onClick={copyGame} color="primary" autoFocus>
            Create a Copy
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default CopyGameButton;
