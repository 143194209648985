import logging from "utils/logging";
import _ from "lodash";
import React, { useState } from "react";
import { Button, Container, makeStyles, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import {
  selectCurrentQuestionObject,
  submitWagerAction,
} from "../question/questionSlice";
import AnswerTextField from "../question/components/AnswerTextField";
import { selectCurrentSubmission } from "../question/submissionSlice";
import { selectPlayer } from "../player/playerSlice";
import {
  selectGameId,
  selectGameStatusObject,
} from "../gamestate/gameStateSlice";
import { GAMESTATE_ROUND_FINAL } from "../gamestate/constants";

const log = logging.getLogger("Wagering"); //eslint-disable-line no-unused-vars

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#1700a0",
    color: "white",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  categoryHeader: {
    fontFamily: "Helvetica Inserat",
    fontSize: "1.5em",
  },
  maxWagerButton: {
    color: "#dedede",
    borderColor: "#999999",
    marginBottom: theme.spacing(1),
  },
  button: {
    backgroundColor: "#555555",
    "&:disabled": {
      color: "#6b6b85",
      backgroundColor: "#2e3988",
    },
    "&:hover.Mui-disabled": {
      color: "#6b6b85",
      backgroundColor: "#2e3988",
    },
  },
}));

const DollarNumberFormat = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      inputMode="numeric"
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
};

const Wagering = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [wager, setWager] = useState();
  const [submitting, setSubmitting] = useState(false);

  const gameId = useSelector(selectGameId);
  const gameState = useSelector(selectGameStatusObject);
  const questionObj = useSelector(selectCurrentQuestionObject);
  const submissionObject = useSelector(selectCurrentSubmission);
  const playerObject = useSelector(selectPlayer);

  const isDD = gameState.dailyDouble;
  const isFinal = gameState.round === GAMESTATE_ROUND_FINAL;

  if (!questionObj) {
    return <div />;
  }

  const onSubmit = () => {
    setSubmitting(true);
    log.info(
      "Submitting wager: ",
      gameId,
      questionObj.id,
      playerObject.id,
      wager
    );

    dispatch(submitWagerAction(gameId, questionObj.id, playerObject.id, wager));
  };

  const haveWager = submissionObject && submissionObject.wager;
  let minWager = isDD ? 5 : 0;
  let roundMax = gameState.round === "round1" ? 1000 : 2000;
  let maxWager = Math.max(isDD ? roundMax : 0, playerObject.score);

  const error = wager < minWager || wager > maxWager;
  let helperText = null;
  if (wager < minWager) {
    helperText = isDD
      ? "Your wager must be at least $5"
      : "Your wager must be zero or higher";
  } else if (wager > maxWager) {
    helperText = `Your wager must be ${
      "$" + maxWager.toLocaleString()
    } or less.`;
  }

  const onTDD = () => {
    setWager(maxWager);
  };

  const wagerArea = (
    <AnswerTextField
      error={error}
      helperText={helperText}
      id="final-wager-input"
      disabled={haveWager}
      label="Wager"
      value={haveWager ? submissionObject.wager : wager}
      onChange={(e) => setWager(e.target.value)}
      inputComponent={DollarNumberFormat}
    />
  );

  const maxButton = (
    <Button
      className={classes.maxWagerButton}
      variant="outlined"
      onClick={onTDD}
      disabled={haveWager || submitting}
    >
      {isFinal ? "Risk it All" : "True Daily Double"}
    </Button>
  );

  let disabled =
    _.isNull(wager) ||
    _.isUndefined(wager) ||
    wager < minWager ||
    wager > maxWager;

  let buttonText = "Submit";
  if (haveWager) {
    buttonText = "Wager Locked";
    disabled = true;
  } else if (submitting) {
    buttonText = "Submitting...";
    disabled = true;
  }

  let submitButton = (
    <Button
      variant="contained"
      color="secondary"
      onClick={onSubmit}
      disabled={disabled}
      className={classes.button}
    >
      {buttonText}
    </Button>
  );

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Typography variant="h4" gutterBottom className={classes.categoryHeader}>
        {questionObj.category.toUpperCase()}
      </Typography>
      {wagerArea}
      {maxButton} <br />
      {submitButton}
    </Container>
  );
};

export { Wagering };
