export const FIREBASE_COLLECTION_GAMES = "games";
export const FIREBASE_COLLECTION_QUESTIONS = "questions";
export const FIREBASE_COLLECTION_PLAYERS = "players";
export const FIREBASE_COLLECTION_SUBMISSIONS = "submissions";
export const FIREBASE_COLLECTION_SUPERLATIVES = "superlatives";

export const FIREBASE_GAME_FIELD_ROOMID = "roomId";
export const FIREBASE_GAME_FIELD_MANAGERID = "creator";

export const FIREBASE_PLAYER_FIELD_USERID = "userId";

export const FIREBASE_SUBMISSION_FIELD_PLAYER_ID = "playerId";

export const FIREBASE_FUNCTION_CREATE_GAME = "createGame";
export const FIREBASE_FUNCTION_NAME_COMMIT_QUESTION_SCORES =
  "commitSubmissionScores";
export const FIREBASE_FUNCTION_NAME_RESET_GAME = "resetGame";
export const FIREBASE_FUNCTION_COPY_GAME = "copyGame";
export const FIREBASE_FUNCTION_NAME_PREPARE_FINAL = "prepareFinalRoundReveal";
export const FIREBASE_FUNCTION_NAME_COMMIT_FINAL_PLAYER_SCORE =
  "commitFinalPlayerScore";
export const FIREBASE_FUNCTION_UPDATE_CATEGORY = "editCategory";
export const FIREBASE_FUNCTION_GENERATE_SUPERLATIVES = "generateSuperlatives";

export const EQUALTO = "==";
