import logging from "utils/logging";
import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import Provider from "provider";
import {
  createCloseListenerAction,
  createOpenListenerAction,
  modelsUpdated,
} from "redux/actions";
import { playerEntity } from "models/player";
import { buildModelReducer } from "redux/reducers";
import { selectGameStatusObject } from "../gamestate/gameStateSlice";

const log = logging.getLogger("player-slice"); //eslint-disable-line no-unused-vars

const playerAdapter = createEntityAdapter();
const initialState = playerAdapter.getInitialState({
  userIsLoading: true,
  userId: null,
  playerIsLoading: true,
  // player: null,
  playerId: null,
  loadingPlayers: false,
});

const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    userLoaded(state, action) {
      state.userIsLoading = false;
      state.userId = action.payload.userId;
    },
    loadingUser(state) {
      state.userId = null;
      state.userIsLoading = true;
    },
    loadingPlayer(state) {
      state.playerIsLoading = true;
      state.playerId = null;
    },
    playerLoaded(state, action) {
      log.debug("Player loaded: ", action);
      state.playerIsLoading = false;
      if (action.payload.player) {
        state.playerId = action.payload.player.id;
        playerAdapter.upsertOne(state, action.payload.player);
      }
    },
    creatingPlayer(state) {
      state.playerIsLoading = true;
      state.playerId = null;
    },
    watchingPlayers(state) {
      state.loadingPlayers = true;
    },
  },
  extraReducers: (builder) => {
    buildModelReducer({
      builder,
      entity: playerEntity,
      loadingKey: "loadingPlayers",
      adapter: playerAdapter,
    });
  },
});

const getAnonymousUserAction = () => async (dispatch) => {
  dispatch(playerSlice.actions.loadingUser());

  let user = await Provider.getAnonymousUser();

  dispatch(playerSlice.actions.userLoaded({ userId: user.uid }));
};

const getPlayerForUserInGameAction = (userId, gameId) => async (dispatch) => {
  dispatch(playerSlice.actions.loadingPlayer());

  let player = await Provider.getPlayerForUserInGame(userId, gameId);

  log.debug("Got player: ", player);

  dispatch(playerSlice.actions.playerLoaded({ player }));
};

const createPlayerForUserInGameAction = (
  userId,
  gameId,
  playerName,
  signatureDataURL
) => async (dispatch) => {
  dispatch(playerSlice.actions.creatingPlayer);

  let player = await Provider.createPlayerForUserInGame(
    userId,
    gameId,
    playerName,
    signatureDataURL
  );

  dispatch(playerSlice.actions.playerLoaded({ player }));
};

const watchPlayersActionBase = createOpenListenerAction(
  "player/watch_players",
  "watch-players"
);

const stopWatchingPlayersActionBase = createCloseListenerAction(
  "player/stop_watching_players",
  "watch-players"
);

const watchPlayersAction = (gameId) => (dispatch) => {
  dispatch(playerSlice.actions.watchingPlayers());
  dispatch(
    watchPlayersActionBase({ gameId }, async () => {
      log.info("Watching players for game: ", gameId);
      return await Provider.watchPlayersForGameId(gameId, (playersUpdate) => {
        log.info("Got updated players: ", playersUpdate);
        dispatch(modelsUpdated(playersUpdate, playerEntity));
      });
    })
  );
};

const stopWatchingPlayersAction = (gameId) => async (dispatch) => {
  dispatch(stopWatchingPlayersActionBase({ gameId }));
};

export { watchPlayersAction, stopWatchingPlayersAction };

/* ---- Selectors ---- */

export const {
  selectById: selectPlayerById,
  selectIds: selectPlayerIds,
  selectEntities: selectPlayerEntities,
  selectAll: selectAllPlayers,
  selectTotal: selectTotalPlayers,
} = playerAdapter.getSelectors((state) => state.player);

const selectPlayerState = (state) => state.player;
const selectUserId = createSelector(selectPlayerState, (s) => s.userId);
const selectLoadingUser = createSelector(
  selectPlayerState,
  (s) => s.userIsLoading
);
const selectLoadingPlayer = createSelector(
  selectPlayerState,
  (s) => s.playerIsLoading
);
const selectPlayerId = createSelector(selectPlayerState, (s) => s.playerId);
const selectPlayer = createSelector(
  (state) => state,
  selectPlayerId,
  (state, playerId) => {
    if (!playerId) {
      return null;
    }
    return selectPlayerById(state, playerId);
  }
);

const selectPlayerInControl = createSelector(
  (state) => state,
  selectGameStatusObject,
  (state, gameState) => {
    if (gameState.playerInControl) {
      return selectPlayerById(state, gameState.playerInControl);
    }

    return null;
  }
);

export {
  selectPlayerState,
  selectLoadingUser,
  selectLoadingPlayer,
  selectUserId,
  selectPlayer,
  selectPlayerInControl,
};

export {
  getAnonymousUserAction,
  getPlayerForUserInGameAction,
  createPlayerForUserInGameAction,
};
export default playerSlice.reducer;
