import React from "react";
import { useSelector } from "react-redux";
import { Chip } from "@material-ui/core";
import InputIcon from "@material-ui/icons/Input";
import { selectGameStatusObject } from "features/gamestate/gameStateSlice";

const GameStateChip = () => {
  const gameState = useSelector(selectGameStatusObject);

  let icon = <InputIcon fontSize="small" />;
  let label = gameState.currentState;

  return <Chip icon={icon} label={label} variant="outlined" color="primary" />;
};

export default GameStateChip;
