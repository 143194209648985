export const GAMESTATE_CURRENT_LOBBY = "lobby";
export const GAMESTATE_CURRENT_ROUND = "round";
export const GAMESTATE_CURRENT_PAUSE = "paused";
export const GAMESTATE_CURRENT_CREDITS = "credits";

export const GAMESTATE_ROUND_PRACTICE = "practice";
export const GAMESTATE_ROUND_1 = "round1";
export const GAMESTATE_ROUND_2 = "round2";
export const GAMESTATE_ROUND_FINAL = "final";

export const GAMESTATE_FIELD_CONTROLLING_PLAYER = "playerInControl";
export const GAMESTATE_FIELD_CURRENT_QUESTION = "questionId";

export const GAMESTATE_ROUNDSTATE_WAITING = "waiting";
export const GAMESTATE_ROUNDSTATE_FINALROUND_CATEGORY_PREVIEW = "final.preview";
export const GAMESTATE_ROUNDSTATE_DAILYDOUBLE_PICKED = "dailyDouble.picked";
export const GAMESTATE_ROUNDSTATE_QUESTION_WAGERING = "question.wagering";
export const GAMESTATE_ROUNDSTATE_QUESTION_READING = "question.reading";
export const GAMESTATE_ROUNDSTATE_QUESTION_ANSWERING = "question.answering";
export const GAMESTATE_ROUNDSTATE_QUESTION_JUDGING = "question.judging";
export const GAMESTATE_ROUNDSTATE_QUESTION_RESULTS = "question.results";
export const GAMESTATE_ROUNDSTATE_QUESTION_REVEALING = "question.revealing";

export const CLIENT_QUESTIONVIEW_ROUNDSTATES = [
  GAMESTATE_ROUNDSTATE_QUESTION_READING,
  GAMESTATE_ROUNDSTATE_QUESTION_ANSWERING,
  GAMESTATE_ROUNDSTATE_QUESTION_JUDGING,
  GAMESTATE_ROUNDSTATE_QUESTION_RESULTS,
  GAMESTATE_ROUNDSTATE_QUESTION_REVEALING,
];

export const MANAGER_QUESTIONVIEW_ROUNDSTATES = [
  GAMESTATE_ROUNDSTATE_FINALROUND_CATEGORY_PREVIEW,
  GAMESTATE_ROUNDSTATE_QUESTION_WAGERING,
  GAMESTATE_ROUNDSTATE_QUESTION_READING,
  GAMESTATE_ROUNDSTATE_QUESTION_ANSWERING,
  GAMESTATE_ROUNDSTATE_QUESTION_JUDGING,
  GAMESTATE_ROUNDSTATE_QUESTION_RESULTS,
  GAMESTATE_ROUNDSTATE_QUESTION_REVEALING,
];

export const SUBMISSION_STATE_WAGER = "wager";
export const SUBMISSION_STATE_CORRECT = "correct";
export const SUBMISSION_STATE_WRONG = "incorrect";
export const SUBMISSION_STATE_PENDING = "submitted";
export const SUBMISSION_STATE_COMMITTED = "committed";

export const QUESTION_TIME_SECONDS = 25;
export const FINAL_ROUND_QUESTION_TIME_SECONDS = 32;
