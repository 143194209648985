import logging from "utils/logging";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useSound from "use-sound";
import dailyDoubleSound from "sounds/daily-double.mp3";
import { Typography } from "@material-ui/core";
import DDImage from "images/dailydouble.jpg";
import {
  GAMESTATE_ROUNDSTATE_QUESTION_ANSWERING,
  GAMESTATE_ROUNDSTATE_QUESTION_JUDGING,
  GAMESTATE_ROUNDSTATE_QUESTION_RESULTS,
} from "../../gamestate/constants";

const log = logging.getLogger("Daily Double - Gameboard Screen"); // eslint-disable-line no-unused-vars

const useStyles = makeStyles((theme) => ({
  ddImage: {
    maxWidth: "100%",
    objectFit: "contain",
  },
  wager: {
    paddingTop: theme.spacing(5),
    fontFamily: "Helvetica Inserat",
  },
  result: {
    fontFamily: "Helvetica Inserat",
    fontSize: "1em",
  },
  guess: {
    fontFamily: "Korinna",
  },
  judgement: {
    fontFamily: "Helvetica Inserat",
    fontSize: "0.7em",
  },
  plusPoints: {
    color: "#4ce74c",
  },
  minusPoints: {
    color: "#e74c4c",
  },
  correctAnswer: {
    marginTop: theme.spacing(2),
    fontFamily: "Korinna",
    fontSize: "0.7em",
  },
  correctAnswerLabel: {
    fontFamily: "Helvetica Inserat",
  },
}));

const DDRevealBox = () => {
  const classes = useStyles();

  const [playDDSound] = useSound(dailyDoubleSound);

  useEffect(() => {
    log.debug("Playing DD sound");
    playDDSound();
  }, [playDDSound]);

  return (
    <div className={classes.ddImage}>
      <img alt="Daily Double" className={classes.ddImage} src={DDImage} />
    </div>
  );
};

const DDWager = ({ playerName, wager }) => {
  const classes = useStyles();

  let prompt = playerName
    ? `${playerName}, submit your wager:`
    : "Submit your wager:";

  let wagerEntry = <div />;
  if (wager) {
    wagerEntry = (
      <Typography className={classes.wager} variant="h3">
        ${wager.toLocaleString()}
      </Typography>
    );
  }

  return (
    <div>
      <Typography className={classes.wager} variant="h3">
        {prompt}
      </Typography>
      {wagerEntry}
    </div>
  );
};

const DDResult = ({ gameState, guess, playerName, answer, correct, wager }) => {
  const classes = useStyles();

  if (!gameState.dailyDouble) {
    return null;
  }

  let visibleStates = [
    GAMESTATE_ROUNDSTATE_QUESTION_ANSWERING,
    GAMESTATE_ROUNDSTATE_QUESTION_JUDGING,
    GAMESTATE_ROUNDSTATE_QUESTION_RESULTS,
  ];

  if (!visibleStates.includes(gameState.roundState)) {
    return null;
  }

  let guessLabel = playerName
    ? `${playerName.toUpperCase()}'S GUESS: `
    : `GUESS: `;

  let judgement = null;

  if (gameState.roundState === GAMESTATE_ROUNDSTATE_QUESTION_RESULTS) {
    if (correct) {
      judgement = (
        <Typography variant="h6" className={classes.judgement}>
          ✅ Correct!{" "}
          <span className={classes.plusPoints}>
            +${Math.abs(wager).toLocaleString()}
          </span>
        </Typography>
      );
    } else {
      judgement = (
        <div>
          <div style={{ margin: "8px" }} />
          <Typography variant="h6" className={classes.judgement}>
            ❌ &nbsp;Incorrect!{" "}
            <span className={classes.minusPoints}>
              -${Math.abs(wager).toLocaleString()}
            </span>
          </Typography>
          <Typography variant="h6" className={classes.correctAnswer}>
            <span className={classes.correctAnswerLabel}>Correct Answer:</span>{" "}
            {answer}
          </Typography>
        </div>
      );
    }
  }

  return (
    <Typography variant="h5" className={classes.result}>
      <p>
        {guessLabel} <span className={classes.guess}>{guess}</span>
      </p>
      {judgement}
    </Typography>
  );
};

export { DDRevealBox, DDWager, DDResult };
