import { createSlice, createSelector } from "@reduxjs/toolkit";
import Provider from "provider/index";
import logging from "utils/logging";

const log = logging.getLogger("join-room-slice");

const joinRoomSlice = createSlice({
  name: "JoinRoom",
  initialState: {
    room: null,
    checkingRoomId: false,
    errorMessage: null,
  },
  reducers: {
    resetJoinState(state) {
      state.room = null;
      state.checkingRoomId = false;
      state.errorMessage = null;
    },
    checkRoomId(state, action) {
      state.errorMessage = null;
      state.room = null;
      state.checkingRoomId = true;
    },
    setRoomIdValid(state, action) {
      state.room = action.payload.roomId;
      state.checkingRoomId = false;
    },
    setRoomIdNotValid(state) {
      state.room = null;
      state.checkingRoomId = false;
      state.errorMessage = "Invalid Room ID.  Please try again.";
    },
  },
});

const actions = joinRoomSlice.actions;

const checkRoomIdAction = (roomId) => async (dispatch) => {
  const fixedRoomId = roomId.trim().toUpperCase();

  dispatch(actions.checkRoomId({ roomId: fixedRoomId }));

  let room = await Provider.getRoom(fixedRoomId);

  log.debug(`Room for ${fixedRoomId}: `, room);

  if (room) {
    dispatch(actions.setRoomIdValid({ roomId: fixedRoomId }));
  } else {
    dispatch(actions.setRoomIdNotValid());
  }
};

/* -- Selectors -- */
const selectJoinRoom = (state) => state.joinRoom;

const selectLoading = createSelector(selectJoinRoom, (joinRoomState) => {
  return joinRoomState.checkingRoomId;
});

const selectDidJoinRoom = createSelector(
  selectJoinRoom,
  selectLoading,
  (joinRoomState, loading) => !loading && joinRoomState.room !== null
);

const selectJoinedRoomId = createSelector(
  selectJoinRoom,
  (joinRoomState) => joinRoomState.room
);

const selectErrorMessage = createSelector(
  selectJoinRoom,
  (joinRoomState) => joinRoomState.errorMessage
);

const resetJoinStateAction = joinRoomSlice.actions.resetJoinState;

export { joinRoomSlice, checkRoomIdAction, resetJoinStateAction };
export {
  selectJoinRoom,
  selectLoading,
  selectDidJoinRoom,
  selectJoinedRoomId,
  selectErrorMessage,
};
export default joinRoomSlice.reducer;
