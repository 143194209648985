import log from "loglevel";
import prefix from "loglevel-plugin-prefix";

prefix.reg(log);
prefix.apply(log, { template: "[%t] %l (%n)" });

const getLogger = (loggerName, level = "DEBUG") => {
  const logger = log.getLogger(loggerName);
  logger.setLevel(level);
  return logger;
};

const api = {
  getLogger,
};

export default api;
