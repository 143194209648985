import React, { useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { Button, List, makeStyles, Typography } from "@material-ui/core";
import { selectCurrentUserId } from "features/auth/authSlice";
import { SkeletonGameListItem, GameListItem } from "./GameListItem";
import {
  stopWatchingManagedGamesAction,
  watchManagedGamesAction,
  selectAllManagedGames,
  selectManagedGamesLoading,
} from "../managedGamesSlice";

const useStyles = makeStyles((theme) => ({
  emptyState: {
    textAlign: "center",
  },
}));

const GameList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentUserId = useSelector(selectCurrentUserId);
  const loading = useSelector(selectManagedGamesLoading);
  const games = useSelector(selectAllManagedGames);

  useEffect(() => {
    dispatch(watchManagedGamesAction(currentUserId));

    return function cleanup() {
      dispatch(stopWatchingManagedGamesAction());
    };
  }, [dispatch]);

  if (loading) {
    return (
      <List>
        <SkeletonGameListItem />
        <SkeletonGameListItem />
        <SkeletonGameListItem />
      </List>
    );
  }

  if (games.length > 0) {
    return (
      <List>
        {games.map((game) => (
          <GameListItem
            name={game.name}
            gameId={game.id}
            created={moment(game.createdAt).calendar()}
          />
        ))}
      </List>
    );
  }

  return (
    <div className={classes.emptyState}>
      <Typography variant="h4">Create a New Game</Typography>
      <br />
      <Button
        color="primary"
        variant="contained"
        component={RouterLink}
        to={"/manage/create"}
      >
        New Game
      </Button>
    </div>
  );
};

export default GameList;
