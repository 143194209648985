import logging from "utils/logging";
import {
  selectQuestionEntities,
  getQuestionsForRound,
} from "../question/questionSlice";
import {
  selectGameObject,
  selectGameStatusObject,
} from "features/gamestate/gameStateSlice";
import { createSelector } from "@reduxjs/toolkit";
import { updateGameStateAction } from "../gamestate/stateActions";
import {
  GAMESTATE_ROUNDSTATE_QUESTION_READING,
  GAMESTATE_ROUNDSTATE_DAILYDOUBLE_PICKED,
} from "../gamestate/constants";
import Provider from "provider";

const log = logging.getLogger("GameBoard-slice"); //eslint-disable-line no-unused-vars

/* ----- Actions ------- */

const beginQuestionAction = (gameId, gameState, questionId) => async (
  dispatch
) => {
  // The logic (here or on the server) should be:
  // - Set the question state as "active"
  await Provider.markQuestionSelected(gameId, questionId);

  // - Set the game state to "question.reading"
  const newState = {
    ...gameState,
    questionId: questionId,
    roundState: GAMESTATE_ROUNDSTATE_QUESTION_READING,
  };

  dispatch(updateGameStateAction(gameId, newState));
};

const beginDailyDoubleAction = (gameId, gameState, questionId) => async (
  dispatch
) => {
  await Provider.markQuestionSelected(gameId, questionId);

  const newState = {
    ...gameState,
    questionId: questionId,
    roundState: GAMESTATE_ROUNDSTATE_DAILYDOUBLE_PICKED,
    dailyDouble: true,
  };

  dispatch(updateGameStateAction(gameId, newState));
};

export { beginQuestionAction, beginDailyDoubleAction };
/* ------ Selectors ------- */

const selectQuestionObjectsForBoard = createSelector(
  [selectGameStatusObject, selectGameObject, selectQuestionEntities],
  (gameState, game, questions) => {
    let categoryData;
    switch (gameState.currentState) {
      case "round":
        categoryData = game.categories[gameState.round];
        break;
      default:
        return null;
    }

    return getQuestionsForRound(categoryData, questions);
  }
);

export { selectQuestionObjectsForBoard };
