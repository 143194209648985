import logging from "utils/logging";
import React, { useState } from "react";
import { Modal, Zoom } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FinalRoundPodiumScreen } from "./FinalRoundPodiumScreen";

const log = logging.getLogger("Final Question Revealer Modal");

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "600px",
    minHeight: "450px",
    padding: theme.spacing(3),
    color: "#ffffff",
    backgroundColor: "#1700a0",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    fontSize: "2em",
  },
}));

const FinalQuestionRevealerModal = ({
  open,
  player,
  onCloseModal,
  onFinish,
}) => {
  const classes = useStyles();

  log.debug("In modal", player);

  const [revealState, setRevealState] = useState("hidden");
  const [showAnswer, setShowAnswer] = useState(false);
  const [showWager, setShowWager] = useState(false);
  const [showCorrect, setShowCorrect] = useState(false);

  const onClick = () => {
    switch (revealState) {
      case "hidden":
        setRevealState("answer");
        setShowAnswer(true);
        break;
      case "answer":
        setRevealState("answerResult");
        setShowCorrect(true);
        break;
      case "answerResult":
        setRevealState("wager");
        setShowWager(true);
        break;
      case "wager":
        setRevealState("final");
        onFinish();
        break;
      case "final":
        onCloseModal();
        break;
      default:
        break;
    }
  };

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      closeAfterTransition
      className={classes.modal}
    >
      <Zoom in={true} timeout={500}>
        <div className={classes.paper} onClick={onClick}>
          <FinalRoundPodiumScreen
            player={player}
            score={player.score}
            answer={player.submission.answer}
            wager={player.submission.wager}
            correct={player.submission.result === "correct"}
            showAnswer={showAnswer}
            showWager={showWager}
            showCorrect={showCorrect}
          />
        </div>
      </Zoom>
    </Modal>
  );
};

export { FinalQuestionRevealerModal };
