import React, { useEffect } from "react";
import clsx from "clsx";
import JSONView from "../components/JSONView";

import { useDispatch, useSelector } from "react-redux";
import {
  selectGameId,
  selectGameStatusObject,
} from "features/gamestate/gameStateSlice";
import {
  Divider,
  Grid,
  makeStyles,
  Container,
  Typography,
} from "@material-ui/core";
import { StateButton, EndRoundButton } from "../components/GameStateButtons";
import JudgingBoard from "../components/JudgingBoard";
import { MANAGER_QUESTIONVIEW_ROUNDSTATES } from "../../gamestate/constants";
import { selectCurrentQuestionObject } from "../../question/questionSlice";
import {
  stopWatchingQuestionSubmissionsAction,
  watchQuestionSubmissionsAction,
} from "../../question/submissionSlice";
import Leaderboard from "../../leaderboard/Leaderboard";
import DebugButton from "../components/DebugButton";
import logging from "utils/logging";

const log = logging.getLogger("Game Controller");

const useStyles = makeStyles((theme) => ({
  titleCard: {
    textAlign: "center",
    fontFamily: "Helvetica Inserat",
    fontSize: "2em",
    marginTop: theme.spacing(3),
  },
  primaryButton: {
    textAlign: "center",
  },
  margin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  cellBox: {
    backgroundColor: "#ededed",
  },
}));

const GameController = () => {
  log.debug("Game controller");
  const classes = useStyles();
  const dispatch = useDispatch();

  const gameStateObj = useSelector(selectGameStatusObject);
  const gameId = useSelector(selectGameId);
  const questionId = gameStateObj.questionId;
  const questionObject = useSelector(selectCurrentQuestionObject);

  useEffect(() => {
    if (gameId && questionId) {
      dispatch(watchQuestionSubmissionsAction(gameId, questionId));
    }

    return function cleanup() {
      if (gameId && questionId) {
        dispatch(stopWatchingQuestionSubmissionsAction(gameId, questionId));
      }
    };
  }, [dispatch, questionId, gameId]);

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.titleCard}>
          Game Controller - Cockpit View
        </Grid>
        <Grid
          container
          item
          xs={12}
          spacing={2}
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          className={clsx([classes.primaryButton])}
        >
          <Grid item>
            <StateButton />
          </Grid>
          {/*<Grid item>*/}
          {/*  <PauseButton />*/}
          {/*</Grid>*/}
          <Grid item>
            <EndRoundButton />
          </Grid>
          <Grid item>
            <DebugButton />
          </Grid>
        </Grid>
        <Divider />
        <Grid item xs={12} md={6}>
          <Leaderboard />
        </Grid>
        {MANAGER_QUESTIONVIEW_ROUNDSTATES.includes(gameStateObj.roundState) && (
          <Grid item xs={12} md={6}>
            <JudgingBoard />
          </Grid>
        )}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" className={classes.margin}>
            Game State
          </Typography>
          <JSONView gameObject={gameStateObj} />
        </Grid>
        <Grid item xs={12} md={6}>
          {MANAGER_QUESTIONVIEW_ROUNDSTATES.includes(
            gameStateObj.roundState
          ) && (
            <div>
              <Typography variant="h5" className={classes.margin}>
                Question State
              </Typography>
              <JSONView gameObject={questionObject} />
            </div>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default GameController;
