import { createSelector, createSlice } from "@reduxjs/toolkit";
import Provider from "provider";
import logging from "utils/logging";
const log = logging.getLogger("Create Game Slice");

const createGameSlice = createSlice({
  name: "create",
  initialState: {
    creating: false,
    createdGameId: null,
  },
  reducers: {
    creatingGame(state) {
      state.creating = true;
      state.createdGameId = null;
    },
    gameCreated(state, action) {
      state.creating = false;
      state.createdGameId = action.payload.gameId;
    },
    reset(state) {
      state.creating = false;
      state.createdGameId = null;
    },
  },
});

const resetCreateGameStateAction = () => (dispatch) =>
  dispatch(createGameSlice.actions.reset());

const createGameAction = (gameName, categoryCount, managerId) => async (
  dispatch
) => {
  dispatch(createGameSlice.actions.creatingGame());
  const gameId = await Provider.createGame(gameName, categoryCount, managerId);
  log.debug("Got newly created game ID: ", gameId);
  dispatch(createGameSlice.actions.gameCreated({ gameId }));
};

export { resetCreateGameStateAction, createGameAction };

// ------ Selectors -----
const selectCreateGameState = (state) => state.createGame;
const selectCreating = createSelector(
  selectCreateGameState,
  (state) => state.creating
);
const selectCreatedGameId = createSelector(
  selectCreateGameState,
  (state) => state.createdGameId
);

export { selectCreating, selectCreatedGameId };

export default createGameSlice.reducer;
