import { createSelector, createSlice } from "@reduxjs/toolkit";
import { selectGameObject } from "features/gamestate/gameStateSlice";
import Provider from "provider";
import { GAMESTATE_ROUND_FINAL } from "../gamestate/constants";
import logging from "utils/logging";
const log = logging.getLogger("Editor Slice");

const editorSlice = createSlice({
  name: "editor",
  initialState: {
    round: "round1",
    nameEditState: null,
    editingQuestion: null,
    editingCategory: null,
    savingCategory: false,
    savingQuestion: false,
  },
  reducers: {
    editName(state, action) {
      state.nameEditState = "saving";
    },
    nameEdited(state, action) {
      state.nameEditState = "saved";
    },
    editQuestion(state, action) {
      state.editingQuestion = action.payload.questionId;
    },
    editCategory(state, action) {
      state.editingCategory = action.payload.categoryNum;
    },
    stopEditingCategory(state) {
      state.editingCategory = null;
    },
    stopEditingQuestion(state) {
      state.editingQuestion = null;
    },
    changeRound(state, action) {
      state.round = action.payload.round;
    },
    saveNewCategory(state) {
      state.savingCategory = true;
    },
    finishSavingNewCategory(state) {
      state.savingCategory = false;
      state.editingCategory = null;
    },
    saveQuestion(state) {
      state.savingQuestion = true;
    },
    finishSavingQuestion(state) {
      state.savingQuestion = false;
      state.editingQuestion = null;
    },
  },
});

export const {
  editQuestion,
  editCategory,
  stopEditingQuestion,
  stopEditingCategory,
  changeRound,
} = editorSlice.actions;

const updateGameName = (gameId, newName) => async (dispatch) => {
  dispatch(editorSlice.actions.editName());

  await Provider.updateGameName(gameId, newName);

  dispatch(editorSlice.actions.nameEdited());
};

const updateCategoryAction =
  (gameId, roundId, categoryIndex, newCategory) => async (dispatch) => {
    dispatch(editorSlice.actions.saveNewCategory());

    if (roundId === GAMESTATE_ROUND_FINAL) {
      await Provider.updateFinalCategory(gameId, newCategory);
    } else {
      await Provider.updateCategory(
        gameId,
        roundId,
        categoryIndex,
        newCategory
      );
    }
    dispatch(editorSlice.actions.finishSavingNewCategory());
  };

const updateQuestionAction =
  (gameId, questionId, newQuestionData) => async (dispatch) => {
    dispatch(editorSlice.actions.saveQuestion());

    await Provider.updateQuestion(gameId, questionId, newQuestionData);

    dispatch(editorSlice.actions.finishSavingQuestion());
  };

export { updateGameName, updateCategoryAction, updateQuestionAction };

/* --------- Selectors ----------- */
const selectEditorState = (state) => state.editor;
const selectEditingQuestionId = createSelector(
  selectEditorState,
  (e) => e.editingQuestion
);
const selectEditingNameState = createSelector(
  selectEditorState,
  (e) => e.nameEditState
);
// const selectEditingRound = createSelector(selectEditorState, (e) => e.round);
const selectEditingCategory = createSelector(
  selectEditorState,
  (e) => e.editingCategory
);
const selectSavingCategory = createSelector(
  selectEditorState,
  (e) => e.savingCategory
);
const selectSavingQuestion = createSelector(
  selectEditorState,
  (e) => e.savingQuestion
);

const selectCategoryString = (round) =>
  createSelector(
    [selectEditingCategory, selectGameObject],
    (categoryIdx, gameObj) => {
      log.debug("Getting selected category: ", round, gameObj);
      if (round === GAMESTATE_ROUND_FINAL) {
        return gameObj["categories"]["final"].category;
      }
      const roundData = gameObj["categories"][round];
      return roundData[categoryIdx].category;
    }
  );

export {
  selectCategoryString,
  selectEditingQuestionId,
  selectEditingCategory,
  // selectEditingRound,
  selectEditingNameState,
  selectSavingCategory,
  selectSavingQuestion,
};

export default editorSlice.reducer;
