import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import GameBoard from "./components/GameBoard";
import { Grid, Fab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PlayArrow } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  selectGameId,
  selectGameStatusObject,
} from "../gamestate/gameStateSlice";
import {
  GAMESTATE_CURRENT_CREDITS,
  GAMESTATE_CURRENT_LOBBY,
  GAMESTATE_CURRENT_ROUND,
  GAMESTATE_ROUND_FINAL,
  GAMESTATE_ROUNDSTATE_QUESTION_ANSWERING,
  GAMESTATE_ROUNDSTATE_QUESTION_JUDGING,
  GAMESTATE_ROUNDSTATE_QUESTION_READING,
  GAMESTATE_ROUNDSTATE_QUESTION_RESULTS,
  GAMESTATE_ROUNDSTATE_QUESTION_REVEALING,
} from "../gamestate/constants";
import Lobby from "../lobby/Lobby";
import GameboardLeaderboard from "./components/GameBoardLeaderboard";
import { FinalRoundBoard } from "./components/FinalRoundBoard";
import { FinalRoundPodiums } from "./components/FinalRoundPodiums";
import { GameBoardModal } from "./components/GameBoardModal";
import { FinalCredits } from "./components/FinalCredits";
import { updateGameStateAction } from "../gamestate/stateActions";
import * as Constants from "../gamestate/constants";
import Provider from "../../provider";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    padding: theme.spacing(2),
    backgroundColor: "black",
    // marginLeft: 0,
    // marginTop: 8,
  },
  rootGrid: {
    backgroundColor: "black",
    height: "90%",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
  },
  gridSpacer: {
    width: "100%",
    height: "100%",
  },
  backgroundHolder: {
    backgroundColor: "black",
    width: "100%",
    height: "100%",
    position: "fixed",
    top: "0px",
    left: "0px",
    zIndex: "-1",
  },
  leaderboardGrid: {
    height: "100%",
    overflowY: "scroll",
  },
  fab: {
    position: "fixed",
    right: theme.spacing(4),
    bottom: theme.spacing(4),
    // fontSize: "1.5em",
    backgroundColor: "#2a07fa",
    "&:hover": {
      backgroundColor: "#4829ff",
    },
  },
  fabIcon: {
    marginRight: theme.spacing(1),
  },
}));

const GameBoardState = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Get the game state `currentState`

  /* ----
  - If it's a lobby, show a lobby (bunch of people)
  - If it's a round, show the game board & leaderboard
  --- If it's waiting, end
  --- If it's a question reading, active, judging, show the question
  --- If it's a question results, show question results view
  - If it's pausd, show a pause screen (jeop logo?)
  - If it's a break, show the people w/ scores + leaderboard
  - If it's final jeopardy...
  --- Just show a final jeopardy component and let it figure it out
  - If it's game over, show the leaderboard & people + trophy

  -------
   */
  const gameStatus = useSelector(selectGameStatusObject);
  const gameId = useSelector(selectGameId);

  useEffect(() => {
    document.body.style.backgroundColor = "black";
  }, []);

  if (gameStatus.currentState === GAMESTATE_CURRENT_LOBBY) {
    return <Lobby />;
  }

  if (
    gameStatus.round &&
    gameStatus.round === GAMESTATE_ROUND_FINAL &&
    gameStatus.roundState === GAMESTATE_ROUNDSTATE_QUESTION_REVEALING
  ) {
    // In this case, show the Final Round podiums
    return <FinalRoundPodiums />;
  }

  if (gameStatus.currentState === GAMESTATE_CURRENT_CREDITS) {
    return <FinalCredits />;
  }

  let mainBoard = <GameBoard />;
  if (gameStatus.round && gameStatus.round === GAMESTATE_ROUND_FINAL) {
    mainBoard = <FinalRoundBoard />;
  }

  let questionVisibleState = [
    GAMESTATE_ROUNDSTATE_QUESTION_READING,
    GAMESTATE_ROUNDSTATE_QUESTION_ANSWERING,
    GAMESTATE_ROUNDSTATE_QUESTION_JUDGING,
    GAMESTATE_ROUNDSTATE_QUESTION_RESULTS,
  ];
  let showQuestion =
    gameStatus.currentState === GAMESTATE_CURRENT_ROUND &&
    questionVisibleState.includes(gameStatus.roundState);

  let showDD =
    gameStatus.currentState === GAMESTATE_CURRENT_ROUND &&
    gameStatus.dailyDouble;

  let modal = <div />;

  if (showQuestion || showDD) {
    modal = <GameBoardModal />;
  }

  const onEndRound = async () => {
    const { round, ...rest } = gameStatus; //eslint-disable-line no-unused-vars
    const nextState = {
      round,
      currentState: "lobby",
    };
    if (round === Constants.GAMESTATE_ROUND_PRACTICE) {
      await Provider.resetScores(gameId);
    }

    dispatch(updateGameStateAction(gameId, nextState));
  };

  let fab = (
    <Fab
      className={classes.fab}
      variant="extended"
      color="primary"
      size="large"
      onClick={onEndRound}
    >
      <PlayArrow className={classes.fabIcon} />
      End Round
    </Fab>
  );
  if (gameStatus.round === GAMESTATE_ROUND_FINAL) {
    fab = null;
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Groupardy - Game Board</title>
      </Helmet>
      <Grid container>
        <Grid
          item
          xs={9}
          container
          className={classes.rootGrid}
          alignItems="stretch"
        >
          {mainBoard}
        </Grid>
      </Grid>
      <Grid
        container
        // className={classes.rootGrid}
        spacing={3}
        alignItems="stretch"
      >
        <Grid item xs={9}>
          <div />
        </Grid>
        <Grid item xs={3}>
          <GameboardLeaderboard />
        </Grid>
      </Grid>
      {modal}
      <div className={classes.backgroundHolder} />
      {fab}
    </div>
  );
};

export default GameBoardState;
