import { updatingGameState } from "./gameStateSlice";
import Provider from "provider";

const updateGameStateAction = (gameId, newState) => async (dispatch) => {
  dispatch(updatingGameState);

  await Provider.updateGameState(gameId, newState);
};

export { updateGameStateAction };
