import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Fab, makeStyles, Typography } from "@material-ui/core";
import {
  selectGameObject,
  selectGameStatusObject,
} from "../gamestate/gameStateSlice";
import { selectAllPlayers } from "../player/playerSlice";
import PodiumGrid from "./PodiumGrid";
import { PlayArrow } from "@material-ui/icons";
import { advanceState } from "../gamestate/GameState";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  headerSection: {
    background: "transparent",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    // width: "100%",
    height: "175px",
  },
  headerSpacer: {
    height: "185px",
  },
  header: {
    paddingTop: theme.spacing(2),
    textAlign: "center",
    width: "100%",
    fontFamily: "Gyparody",
    color: "#ffbf00",
    fontSize: "5em",
    textShadow: `0px 0px 0 rgb(243,179,0),
                 1px 1px 0 rgb(230,166,0),
                 2px 2px 0 rgb(218,154,0),
                 3px 3px 0 rgb(206,142,0),
                 4px 4px 0 rgb(193,129,0),
                 5px 5px 0 rgb(181,117,0),
                 6px 6px 0 rgb(168,104,0),
                 7px 7px 0 rgb(156,92,0),
                 8px 8px  0 rgb(144,80,0),
                 9px 9px 8px rgba(120,128,204,0.58),
                 9px 9px 1px rgba(120,128,204,0.5),
                 0px 0px 8px rgba(120,128,204,.2)`,
  },
  instructions: {
    textAlign: "center",
    width: "100%",
    paddingBottom: theme.spacing(2),
    color: "white",
  },
  gameLink: {
    textDecoration: "underline",
    fontWeight: "bold",
    fontSize: "1.2em",
  },
  roomName: {
    fontWeight: "bold",
    fontFamily: "Courier",
    fontSize: "1.5em",
  },
  fab: {
    position: "fixed",
    right: theme.spacing(4),
    top: theme.spacing(4),
    backgroundColor: "#2a07fa",
    "&:hover": {
      backgroundColor: "#4829ff",
    },
  },
  fabIcon: {
    marginRight: theme.spacing(1),
  },
}));

const Lobby = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const game = useSelector(selectGameObject);
  const gameState = useSelector(selectGameStatusObject);
  const players = useSelector(selectAllPlayers);

  const onStartGame = async () => {
    await advanceState(game, players, dispatch);
  };

  let buttonCopy = "Start Game";
  if (gameState.round) {
    buttonCopy = "Begin Next Round";
  }

  return (
    <div className={classes.root}>
      <div className={classes.headerSpacer} />
      <PodiumGrid />
      <div className={classes.headerSection}>
        <Typography variant="h3" className={classes.header}>
          This is Groupardy!
        </Typography>
        {!gameState.round && (
          <Typography variant="h4" className={classes.instructions}>
            To join, go to{" "}
            <span className={classes.gameLink}>groupardy.com</span> on your
            phone, and join with room code{" "}
            <span className={classes.roomName}>{game.roomId}</span>
          </Typography>
        )}
      </div>
      <Fab
        className={classes.fab}
        variant="extended"
        color="primary"
        onClick={onStartGame}
      >
        <PlayArrow className={classes.fabIcon} />
        {buttonCopy}
      </Fab>
    </div>
  );
};

export default Lobby;
