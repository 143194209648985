import logging from "utils/logging";
import React, { useEffect } from "react";
import clsx from "clsx";
import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  selectGameId,
  selectGameStatusObject,
} from "../../gamestate/gameStateSlice";
import {
  selectPlayerSubmissionsForGameboardLeaderboard,
  stopWatchingQuestionSubmissionsAction,
  watchQuestionSubmissionsAction,
} from "../../question/submissionSlice";
import {
  GAMESTATE_CURRENT_CREDITS,
  GAMESTATE_ROUNDSTATE_QUESTION_RESULTS,
} from "../../gamestate/constants";

const log = logging.getLogger("Gameboard Leaderboard");

const useStyles = makeStyles((theme) => ({
  gameboardLeaderboardRow: {
    backgroundColor: "#1700a0",
    color: "white",
    fontFamily: "Helvetica Inserat",
    fontSize: "1.5em",
    "& .MuiListItemText-primary": {
      fontFamily: "Helvetica Inserat",
      color: "white",
      fontSize: "0.9em",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
  whiteHeader: {
    color: "white",
    fontFamily: "Helvetica Inserat",
    fontSize: "2.5em",
    textAlign: "center",
  },
  gameboardLeaderboardRowMoney: {
    color: "white",
    fontFamily: "Swiss911 XCm BT",
    fontSize: "1.75em",
    textAlign: "center",
  },
  gameboardLeaderboardRowNegativeMoney: {
    color: "#bc1b1b",
    fontFamily: "Swiss911 XCm BT",
    fontSize: "1.75em",
    textAlign: "center",
  },
  plusPoints: {
    color: "#1bbc1b",
    fontSize: "0.75em",
  },
  minusPoints: {
    color: "#bc1b1b",
    fontSize: "0.75em",
  },
}));

const GameboardLeaderboardRow = ({
  player,
  gameState,
  index,
  isHighlighted,
}) => {
  const classes = useStyles();

  let scoreChangeText = <span />;
  if (
    player.submission &&
    gameState.roundState &&
    gameState.roundState === GAMESTATE_ROUNDSTATE_QUESTION_RESULTS
  ) {
    log.info("PLayer has sub: ", player);
    const positive = player.submission.points >= 0;
    const sign = positive ? " +" : " ";
    scoreChangeText = (
      <span
        className={clsx([
          positive && classes.plusPoints,
          !positive && classes.minusPoints,
        ])}
      >
        {sign + player.submission.points.toLocaleString()}
      </span>
    );
  }

  return (
    <ListItem className={classes.gameboardLeaderboardRow} divider={true}>
      <ListItemText
        primary={
          <React.Fragment>
            <span>{`${isHighlighted ? "→" : ""}${index + 1}. ${
              player.name
            }`}</span>
            {scoreChangeText}
          </React.Fragment>
        }
        className={classes.gameboardLeaderboardRow}
      />
      <Typography
        variant="h5"
        className={
          player.score >= 0
            ? classes.gameboardLeaderboardRowMoney
            : classes.gameboardLeaderboardRowNegativeMoney
        }
        align="right"
      >
        {player.score >= 0
          ? "$" + player.score.toLocaleString()
          : "- $" + Math.abs(player.score).toLocaleString()}
      </Typography>
    </ListItem>
  );
};

const GameboardLeaderboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const players = useSelector(selectLeaderboardPlayers);
  const players = useSelector(selectPlayerSubmissionsForGameboardLeaderboard);
  const gameStateObj = useSelector(selectGameStatusObject);
  const gameId = useSelector(selectGameId);
  const questionId = gameStateObj.questionId;

  useEffect(() => {
    if (gameId && questionId) {
      dispatch(watchQuestionSubmissionsAction(gameId, questionId));
    }

    return function cleanup() {
      if (gameId && questionId) {
        dispatch(stopWatchingQuestionSubmissionsAction(gameId, questionId));
      }
    };
  }, [dispatch, questionId, gameId]);

  let list;
  if (!players) {
    list = <Typography>Loading...</Typography>;
  } else {
    list = players.map((player, idx) => (
      <GameboardLeaderboardRow
        player={player}
        gameState={gameStateObj}
        key={`player-${player.id}`}
        index={idx}
        isHighlighted={gameStateObj.playerInControl === player.id}
      />
    ));
  }

  const headerText =
    gameStateObj.currentState === GAMESTATE_CURRENT_CREDITS
      ? "Final Scores"
      : "Scores";

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.whiteHeader}>
        {headerText}
      </Typography>
      <List>{list}</List>
    </div>
  );
};

export default GameboardLeaderboard;
