import logging from "utils/logging";
import utils from "./firebaseUtils";
import {
  EQUALTO,
  FIREBASE_COLLECTION_GAMES,
  FIREBASE_COLLECTION_PLAYERS,
  FIREBASE_PLAYER_FIELD_USERID,
} from "./constants";
import {
  loadDiffOfModelsForQuerySnapshot,
  loadModelForDocumentSnapshot,
  loadModelsForQuerySnapshot,
} from "./firebaseModels";

const log = logging.getLogger("firebase/player");

const getPlayerForUserInGame = async (userId, gameId) => {
  const playerQuery = utils
    .db()
    .collection(FIREBASE_COLLECTION_GAMES)
    .doc(gameId)
    .collection(FIREBASE_COLLECTION_PLAYERS)
    .where(FIREBASE_PLAYER_FIELD_USERID, EQUALTO, userId);

  const querySnap = await playerQuery.get();

  const players = await loadModelsForQuerySnapshot(querySnap);

  log.debug("Players found: ", players);

  if (players.length === 0) {
    return null;
  } else if (players.length === 1) {
    return players[0];
  } else {
    log.error(`Found multiple players in game ${gameId} with userId ${userId}`);
    throw new Error(
      `Found multiple players in game ${gameId} with userId ${userId}`
    );
  }
};

const createSignatureImage = async (gameId, playerId, dataURL) => {
  const ref = utils.storage().ref(`${gameId}/${playerId}-signature.jpg`);
  let snapshot = await ref.putString(dataURL, "data_url");
  log.debug("Uploaded a data_url string!", snapshot);

  return ref;
};

const createPlayerForUserInGame = async (
  userId,
  gameId,
  playerName,
  signatureDataURL
) => {
  let existingPlayer = await getPlayerForUserInGame(userId, gameId);
  if (existingPlayer !== null) {
    return existingPlayer;
  }

  const playerCollection = utils
    .db()
    .collection(FIREBASE_COLLECTION_GAMES)
    .doc(gameId)
    .collection(FIREBASE_COLLECTION_PLAYERS);

  let playerRef = await playerCollection.add({
    userId,
    name: playerName,
    score: 0,
  });

  let playerSnap = await playerRef.get();

  log.debug("Got player: ", playerSnap.data());

  const signatureRef = await createSignatureImage(
    gameId,
    playerSnap.id,
    signatureDataURL
  );

  log.debug("Got signature ref: ", signatureRef);

  let url = await signatureRef.getDownloadURL();
  log.debug("Got signature file URL: ", url);

  await playerRef.update({
    signatureURL: url,
    signatureStoragePath: signatureRef.fullPath,
  });

  return await loadModelForDocumentSnapshot(playerSnap);
};

const watchPlayersForGameId = (gameId, onChange) => {
  log.debug("Watching players for game: ", gameId);

  const playersRef = utils
    .db()
    .collection(FIREBASE_COLLECTION_GAMES)
    .doc(gameId)
    .collection(FIREBASE_COLLECTION_PLAYERS);

  return playersRef.onSnapshot(async (qSnap) => {
    let diff = await loadDiffOfModelsForQuerySnapshot(qSnap);
    log.debug("Got diffed players: ", diff);
    onChange(diff);
  });
};

const resetScores = async (gameId) => {
  log.debug("Resetting scores");

  const playersRef = utils
    .db()
    .collection(FIREBASE_COLLECTION_GAMES)
    .doc(gameId)
    .collection(FIREBASE_COLLECTION_PLAYERS);

  const playersQSnap = await playersRef.get();

  const batch = utils.db().batch();

  playersQSnap.forEach((playerSnap) => {
    batch.update(playerSnap.ref, { score: 0 });
  });

  await batch.commit();
};

const api = {
  getPlayerForUserInGame,
  createPlayerForUserInGame,
  watchPlayersForGameId,
  resetScores,
};

export default api;
