import React from "react";
import clsx from "clsx";
import { Box, Fade, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  paper: {
    color: "#ffffff",
    backgroundColor: "#1700a0",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  fullHeight: {
    height: "100%",
  },
  header: {
    fontFamily: "Helvetica Inserat",
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  half: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  guess1: {
    fontSize: "2em",
    fontFamily: "Chilanka",
    padding: theme.spacing(2),
  },
}));

const FinalRoundPodiumScreen = ({
  player,
  score,
  answer,
  wager,
  correct,
  showCorrect,
  showAnswer,
  showWager,
}) => {
  const classes = useStyles();

  return (
    <Box
      height={1}
      className={clsx(classes.root, classes.paper)}
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
      alignItems="center"
      flex={1}
    >
      {player && (
        <Box
          display="flex"
          alignItems="center"
          flex={1}
          justifyContent="center"
          flexDirection="column"
        >
          <p className={classes.header}>
            {player.name} | ${score.toLocaleString()}
          </p>
        </Box>
      )}
      <Box
        display="flex"
        alignItems="center"
        flex={1}
        justify="center"
        flexDirection="column"
      >
        <Fade in={showAnswer} timeout={300}>
          <p className={classes.guess1}>{answer}</p>
        </Fade>
      </Box>
      {showCorrect && (
        <Box>
          <Fade in={showCorrect} timeout={300}>
            <p className={classes.header}>
              {correct ? `✅ Correct!` : `❌ Incorrect!`}
            </p>
          </Fade>
        </Box>
      )}
      <Box display="flex" alignItems="center" flex={1} flexDirection="column">
        <Fade in={showWager} timeout={300}>
          <p className={classes.guess1}>${wager}</p>
        </Fade>
      </Box>
    </Box>
  );
};

export { FinalRoundPodiumScreen };
