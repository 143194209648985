import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  headerText: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
}));

const ManagedGameHeader = ({ gameObject }) => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h3" className={classes.headerText}>
        {gameObject.name}
      </Typography>
    </div>
  );
};

export default ManagedGameHeader;
