import _ from "lodash";
import { createSelector } from "@reduxjs/toolkit";
import { selectAllPlayers, selectPlayerEntities } from "../player/playerSlice";
import Provider from "provider";
import {
  selectGameId,
  selectGameStatusObject,
} from "../gamestate/gameStateSlice";
import { selectSubmissionEntities } from "../question/submissionSlice";

const saveSubmissionScoresToLeaderboard =
  (gameId, questionId) => async (dispatch) => {
    // Call the backend to save the scores
    await Provider.commitSubmissionScoresToLeaderboard(gameId, questionId);
  };

const selectLeaderboardPlayers = createSelector(selectAllPlayers, (players) => {
  return _.sortBy(players, (p) => -p.score);
});

const selectLeaderboardPlayersReverse = createSelector(
  selectAllPlayers,
  (players) => {
    return _.sortBy(players, (p) => p.score);
  }
);

const selectPlayersForFinalPodiums = createSelector(
  selectAllPlayers,
  (players) => {
    return _.sortBy(players, (p) => p.preFinalScore);
  }
);

export {
  selectLeaderboardPlayers,
  selectLeaderboardPlayersReverse,
  selectPlayersForFinalPodiums,
  saveSubmissionScoresToLeaderboard,
};
