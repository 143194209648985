import React from "react";
import { Container, Typography, Paper, Button, Grid } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import GameList from "./GameList";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  headerGrid: {
    marginTop: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  title: {
    // marginTop: theme.spacing(2),
  },
}));

const ManagerHome = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="md">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.headerGrid}
      >
        <Grid item>
          <Typography variant="h4" className={classes.title}>
            Manage My Games
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to={"/manage/create"}
          >
            New Game
          </Button>
        </Grid>
      </Grid>
      <Paper className={classes.paper} elevation={3}>
        <GameList />
      </Paper>
    </Container>
  );
};

export { ManagerHome };
