import logging from "utils/logging";
import React from "react";
import { useDispatch } from "react-redux";
import { Typography, Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { updateGameStateAction } from "../../gamestate/stateActions";
import * as Constants from "../../gamestate/constants";
import QuestionCountdown from "../../questionCountdown/QuestionCountdown";

import {
  GAMESTATE_ROUND_FINAL,
  GAMESTATE_ROUNDSTATE_QUESTION_ANSWERING,
  GAMESTATE_ROUNDSTATE_QUESTION_RESULTS,
} from "../../gamestate/constants";

const log = logging.getLogger("GameBoardQuestion"); // eslint-disable-line no-unused-vars

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    fontFamily: "Korinna",
    fontSize: "3em",
    width: "800px",
    minHeight: "450px",
    color: "#ffffff",
    backgroundColor: "#1700a0",
    textAlign: "center",
  },
  clueHeader: {
    fontFamily: "Helvetica Inserat",
    fontSize: "0.75em",
  },
  clue: {
    textShadow: `0px 0px 0 rgb(-10,-10,0),
                 1px 1px 0 rgb(-20,-20,0),
                 2px 2px 0 rgb(-30,-30,0),
                 3px 3px 0 rgb(-40,-40,0),
                 4px 4px 0 rgb(-50,-50,0),
                 5px 5px  0 rgb(-60,-60,0),
                 6px 6px 5px rgba(0,0,0,0),
                 6px 6px 1px rgba(0,0,0,0.5),
                 0px 0px 5px rgba(0,0,0,.2)`,
    letterSpacing: "3px",
    fontWeight: "bold",
  },
  correctAnswer: {
    fontFamily: "Korinna",
    fontSize: "0.75em",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  correctAnswerHeader: {
    fontFamily: "Helvetica Inserat",
    fontSize: "1em",
  },
}));

const GameBoardQuestionHeader = ({
  showPoints,
  category,
  pointsLabel,
  isWager,
}) => {
  const classes = useStyles();

  return (
    <div>
      <span className={classes.clueHeader}>{category.toUpperCase()}</span>
      {showPoints && (
        <React.Fragment>
          <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
          <span className={classes.clueHeader}>
            {isWager ? "Wager: " : ""}
            {pointsLabel}
          </span>
        </React.Fragment>
      )}
    </div>
  );
};

const GameBoardClue = ({ clueText }) => {
  const classes = useStyles();

  return <p className={classes.clue}>{clueText.toUpperCase()}</p>;
};

const GameBoardQuestionCountdown = ({ gameId, gameState, questionObject }) => {
  const dispatch = useDispatch();

  if (!questionObject) {
    return null;
  }

  if (gameState.roundState !== GAMESTATE_ROUNDSTATE_QUESTION_ANSWERING) {
    return null;
  }

  const onTimeReachedZero = () => {
    const { endTime, ...rest } = gameState;
    const newState = {
      ...rest,
      roundState: Constants.GAMESTATE_ROUNDSTATE_QUESTION_JUDGING,
    };
    dispatch(updateGameStateAction(gameId, newState));
  };

  return (
    <QuestionCountdown
      endTimeStr={questionObject.endTime}
      startTimeStr={questionObject.startTime}
      onTimeReachedZero={onTimeReachedZero}
      roundState={gameState.roundState}
      shouldPlayFinalMusic={gameState.round === GAMESTATE_ROUND_FINAL}
    />
  );
};

const GameBoardQuestionResults = ({ gameState, answer }) => {
  const classes = useStyles();

  if (gameState.roundState !== GAMESTATE_ROUNDSTATE_QUESTION_RESULTS) {
    return null;
  }

  return (
    <Fade
      timeout={800}
      in={gameState.roundState === GAMESTATE_ROUNDSTATE_QUESTION_RESULTS}
    >
      <Typography variant="h5" className={classes.correctAnswer}>
        <p className={classes.correctAnswerHeader}>Answer</p>
        <p>{answer}</p>
      </Typography>
    </Fade>
  );
};

export {
  GameBoardQuestionHeader,
  GameBoardClue,
  GameBoardQuestionCountdown,
  GameBoardQuestionResults,
};
