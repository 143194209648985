import logging from "utils/logging";
import React from "react";
import { Button } from "@material-ui/core";
import Provider from "provider";
import { useSelector } from "react-redux";
import { selectGameId } from "../../gamestate/gameStateSlice";

const log = logging.getLogger("DebugButton");

// eslint-disable-next-line no-unused-vars
const commitScores = async (gameId, questionId) => {
  await Provider.commitSubmissionScoresToLeaderboard(gameId, questionId);
};

const resetGame = async (gameId) => {
  const result = await Provider.resetGame(gameId);
  log.debug("reset game result: ", result);
};

const DebugButton = () => {
  let disabled = false;
  // Do whatever we need you to do right now
  const gameId = useSelector(selectGameId);
  // const gameState = useSelector(selectGameStatusObject);

  const onClick = async () => {
    await resetGame(gameId);
  };

  return (
    <Button
      size="large"
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      color="secondary"
    >
      Reset Game!
    </Button>
  );
};

export default DebugButton;
