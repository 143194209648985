import logging from "utils/logging";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { selectQuestionById } from "../question/questionSlice";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import {
  selectCategoryString,
  stopEditingQuestion,
  stopEditingCategory,
  selectSavingCategory,
  selectSavingQuestion,
  selectEditingCategory,
  updateCategoryAction,
  updateQuestionAction,
} from "./editorSlice";
import { selectGameId } from "../gamestate/gameStateSlice";

const log = logging.getLogger("Editor Modal");

const EditQuestionModal = ({ questionId }) => {
  const dispatch = useDispatch();

  const savingQuestion = useSelector(selectSavingQuestion);

  const gameId = useSelector(selectGameId);
  const questionObj = useSelector((state) =>
    selectQuestionById(state, questionId)
  );

  const [clue, setClue] = useState(questionObj.clue);
  const [prompt, setPrompt] = useState(questionObj.prompt);
  const [answers, setAnswers] = useState(questionObj.answers.join(", "));
  const [dailyDouble, setDailyDouble] = useState(questionObj.dailyDouble);

  const onClose = () => {
    log.debug("close");
    dispatch(stopEditingQuestion());
  };

  const saveQuestion = () => {
    log.debug("Save question");

    const answersArray = answers.split(",").map((s) => s.trim());

    const newQuestionData = {
      clue,
      prompt: prompt ? prompt : "",
      answers: answersArray,
      dailyDouble: !!dailyDouble,
    };

    dispatch(updateQuestionAction(gameId, questionId, newQuestionData));
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle id="edit-category-title">
        {questionObj.category.toUpperCase()} | {questionObj.label}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          multiline
          variant="outlined"
          id="clue-field"
          label="Clue"
          fullWidth
          margin="normal"
          value={clue}
          onChange={(e) => setClue(e.target.value)}
        />
        <TextField
          id="prompt-field"
          label="Prompt"
          fullWidth
          margin="normal"
          value={prompt}
          variant="outlined"
          onChange={(e) => setPrompt(e.target.value)}
        />
        <TextField
          autoFocus
          multiline
          variant="outlined"
          id="answers-field"
          label="Answers"
          margin="normal"
          fullWidth
          value={answers}
          onChange={(e) => setAnswers(e.target.value)}
        />
        <FormControlLabel
          control={
            <Switch
              checked={dailyDouble}
              onChange={(e) => setDailyDouble(e.target.checked)}
              name="daily-double-switch"
              color="primary"
            />
          }
          label="Daily Double?"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={savingQuestion}>
          Cancel
        </Button>
        <Button
          onClick={saveQuestion}
          color="primary"
          disabled={savingQuestion}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EditCategoryModal = () => {
  // const classes = useStyles();

  const dispatch = useDispatch();

  const { round: roundId } = useParams();

  const categoryIdx = useSelector(selectEditingCategory);
  const categoryStr = useSelector(selectCategoryString(roundId));
  const savingCategory = useSelector(selectSavingCategory);
  const gameId = useSelector(selectGameId);
  // const roundId = useSelector(selectEditingRound);

  const [category, setCategory] = useState(categoryStr);

  const onClose = () => {
    dispatch(stopEditingCategory());
  };

  const changeCategory = () => {
    log.debug("Commit new category: ", category, roundId, categoryIdx);
    dispatch(updateCategoryAction(gameId, roundId, categoryIdx, category));
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle id="edit-category-title">Edit Category</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          // margin="dense"
          id="category-name"
          label="Category Name"
          fullWidth
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={savingCategory}>
          Cancel
        </Button>
        <Button
          onClick={changeCategory}
          color="primary"
          disabled={savingCategory}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { EditQuestionModal, EditCategoryModal };
