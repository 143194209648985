import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnonymousUserAction,
  // selectUserId,
  selectLoadingUser,
  selectUserId,
} from "./playerSlice";
import { CircularProgress, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    minHeight: "300px",
  },
}));

const AnonymousUserWrapper = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const userId = useSelector(selectUserId);
  const loading = useSelector(selectLoadingUser);

  useEffect(() => {
    // Load the user
    dispatch(getAnonymousUserAction());
  }, [dispatch]);

  if (loading || !userId) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.root}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return props.children;
};

export default AnonymousUserWrapper;
