import React from "react";
import clsx from "clsx";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    color: "white",
    backgroundColor: "#555555",
    "&:disabled": {
      color: "#6b6b85",
      backgroundColor: "#2e3988",
    },
  },
  buttonCompleted: {
    backgroundColor: "#0d7a0d",
    color: "#ababab",
    "&:disabled": {
      backgroundColor: "#0d7a0d",
      color: "#ababab",
    },
  },
}));

const AnswerSubmitButton = ({ disabled, text, onClick, completed }) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      // color="secondary"
      onClick={onClick}
      disabled={disabled}
      className={clsx([classes.button, completed && classes.buttonCompleted])}
    >
      {text}
    </Button>
  );
};

export default AnswerSubmitButton;
