import React, { useState } from "react";
import {
  makeStyles,
  Container,
  Divider,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Button,
  MenuItem,
  TextField,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import EditorBoard from "./EditorBoard";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { selectGameId, selectGameObject } from "../gamestate/gameStateSlice";
import {
  selectEditingQuestionId,
  selectEditingCategory,
  selectEditingNameState,
  changeRound,
  updateGameName,
} from "./editorSlice";
import { EditQuestionModal, EditCategoryModal } from "./EditorModal";
import logging from "utils/logging";
const log = logging.getLogger("Game Editor"); //eslint-disable-line no-unused-vars

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  header: {
    marginTop: theme.spacing(3),
    textAlign: "center",
  },
  formControl: {
    marginTop: theme.spacing(3),
    minWith: "400px",
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  buttonList: {
    "& > *": {
      margin: theme.spacing(1),
    },
    textAlign: "center",
  },
  buttonLink: {
    textDecoration: "none",
  },
}));

const GameEditor = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedRound, setRound] = useState("round1");

  const gameObj = useSelector(selectGameObject);
  const gameId = useSelector(selectGameId);

  const editingNameState = useSelector(selectEditingNameState);
  const editingQuestionId = useSelector(selectEditingQuestionId);
  const editingCategoryIdx = useSelector(selectEditingCategory);

  const location = useLocation();
  const match = useRouteMatch();

  log.debug("Location: ", location, "Match: ", match);

  const chooseRound = (newRound) => {
    setRound(newRound);
    dispatch(changeRound({ round: newRound }));
  };

  const setGameName = (newName) => {
    dispatch(updateGameName(gameId, newName));
  };

  let nameHelperText = null;
  if (editingNameState === "saving") {
    nameHelperText = "...";
  } else if (editingNameState === "saved") {
    nameHelperText = "✓ Saved";
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h3" className={classes.header}>
        Groupardy Game Editor
      </Typography>
      <br />
      <TextField
        id="game-name-edit"
        label="Game Name"
        style={{ margin: 8 }}
        placeholder="The name of your Groupardy game"
        fullWidth
        helperText={nameHelperText}
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={gameObj.name}
        onChange={(e) => setGameName(e.target.value)}
      />
      <Divider className={classes.divider} />
      <div className={classes.buttonList}>
        <Link
          to={`/manage/game/${gameId}/gameboard`}
          target="_blank"
          className={classes.buttonLink}
        >
          <Button variant="contained" endIcon={<OpenInNewIcon />}>
            Game Board
          </Button>
        </Link>
        <Link
          to={`/manage/game/${gameId}/controller`}
          target="_blank"
          className={classes.buttonLink}
        >
          <Button variant="contained" endIcon={<OpenInNewIcon />}>
            Game Controller
          </Button>
        </Link>
      </div>
      <Divider className={classes.divider} />
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Round</InputLabel>
        <Select
          fullWidth={true}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedRound}
          onChange={(e) => chooseRound(e.target.value)}
        >
          <MenuItem value={"round1"}>Round 1</MenuItem>
          <MenuItem value={"round2"}>Round 2</MenuItem>
          <MenuItem value={"final"}>Final Round</MenuItem>
        </Select>
      </FormControl>
      <Divider className={classes.divider} />
      <EditorBoard round={selectedRound} />
      {editingQuestionId && (
        <EditQuestionModal questionId={editingQuestionId} />
      )}
      {editingCategoryIdx !== null && <EditCategoryModal />}
    </Container>
  );
};

export default GameEditor;
