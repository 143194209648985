import React from "react";
import {
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import { useSelector } from "react-redux";
import { selectSimpleGameStatus } from "../managedGamesSlice";

const SkeletonGameListItem = () => {
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar>
          <Skeleton animation="wave" variant="circle" width={40} height={40} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={<Skeleton variant="text" />}
        secondary={<Skeleton variant="text" />}
      />
    </ListItem>
  );
};

const GameListItem = ({ gameId, name, created }) => {
  // const link = <RouterLink to={`/manage/game/${gameId}/editor`} />;

  const simpleGameStatus = useSelector(selectSimpleGameStatus(gameId));

  return (
    <ListItem
      button
      component={RouterLink}
      to={`/manage/game/${gameId}/editor`}
    >
      <ListItemAvatar>
        <Avatar>
          <ViewComfyIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={name}
        secondary={`Created ${created} | ${simpleGameStatus}`}
      />
    </ListItem>
  );
};

export { SkeletonGameListItem, GameListItem };
