import { configureStore } from "@reduxjs/toolkit";
import joinRoomReducer from "features/joinRoom/joinRoomSlice";
import gameReducer from "features/gamestate/gameStateSlice";
import questionReducer from "features/question/questionSlice";
import playerReducer from "features/player/playerSlice";
import listenerMiddleware from "redux/listeners/middleware";
import submissionReducer from "features/question/submissionSlice";
import editorReducer from "features/gameEditor/editorSlice";
import superlativeReducer from "features/superlatives/superlativeSlice";
import createGameReducer from "features/manager/createGame/createGameSlice";
import authReducer from "features/auth/authSlice";
import managedGamesReducer from "features/manager/managedGamesSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    game: gameReducer,
    player: playerReducer,
    joinRoom: joinRoomReducer,
    questions: questionReducer,
    submissions: submissionReducer,
    editor: editorReducer,
    superlatives: superlativeReducer,
    createGame: createGameReducer,
    managedGames: managedGamesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ["openFunction", "payload.game.state.endTime"],
        ignoredPaths: ["game.game.state.endTime"],
      },
    }).concat(listenerMiddleware),
});
