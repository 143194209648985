import logging from "utils/logging";
import {
  createOpenListenerAction,
  createCloseListenerAction,
} from "redux/actions";
import Provider from "provider";
import * as Constants from "features/gamestate/constants";
import { createSelector, createSlice } from "@reduxjs/toolkit";

const log = logging.getLogger("game-slice");

const gameStateSlice = createSlice({
  name: "game",
  initialState: { game: null, loading: false, gameStateChanging: false },
  reducers: {
    gameUpdated(state, action) {
      state.game = action.payload.game;
      state.gameStateChanging = false;
      state.loading = false;
    },
    loadingGame(state) {
      state.loading = true;
    },
    updatingGameState(state) {
      state.gameStateChanging = true;
    },
  },
});

const watchGameActionBase = createOpenListenerAction(
  "game/watch_game",
  "watch-game"
);

const stopWatchingGameActionBase = createCloseListenerAction(
  "game/stop_watching_game",
  "watch-game"
);

const watchGameAction = (gameId) => (dispatch) => {
  dispatch(gameStateSlice.actions.loadingGame);
  dispatch(
    watchGameActionBase({ gameId }, async () => {
      log.info("Watching game: ", gameId);
      let listener = await Provider.watchGame(gameId, (gameUpdate) => {
        log.debug("Got game update: ", gameUpdate);
        dispatch(gameStateSlice.actions.gameUpdated({ game: gameUpdate }));
      });
      return listener;
    })
  );
};

const stopWatchingGameAction = (gameId) => (dispatch) => {
  dispatch(stopWatchingGameActionBase({ gameId }));
};

const watchRoomAction = (roomId) => (dispatch) => {
  dispatch(gameStateSlice.actions.loadingGame);
  dispatch(
    watchGameActionBase({ roomId }, async () => {
      log.info("Watching room: ", roomId);
      let listener = await Provider.watchGameForRoomId(roomId, (gameUpdate) => {
        dispatch(gameStateSlice.actions.gameUpdated({ game: gameUpdate }));
      });
      return listener;
    })
  );
};

const stopWatchingRoomAction = (roomId) => (dispatch) => {
  dispatch(stopWatchingGameActionBase({ roomId }));
};

const updatingGameState = gameStateSlice.actions.updatingGameState;

export {
  watchGameAction,
  stopWatchingGameAction,
  watchRoomAction,
  stopWatchingRoomAction,
  updatingGameState,
};

/* -- Selectors -- */
const selectGameState = (state) => state.game;

const selectLoadingGame = createSelector(selectGameState, (gs) => gs.loading);
const selectGameObject = createSelector(selectGameState, (gs) => gs.game);
const selectGameId = createSelector(selectGameObject, (g) => (g ? g.id : null));

const selectGameStatusObject = createSelector(selectGameState, (gs) =>
  gs.game !== null ? gs.game.state : {}
);

export const getSimpleGameStateForGame = (gameObject) => {
  const { state: gameState } = gameObject;
  switch (gameState.currentState) {
    case Constants.GAMESTATE_CURRENT_LOBBY:
      if (!gameState.round) {
        // This is a new game.
        return "Not Started";
      }
      return "In Progress";
    case Constants.GAMESTATE_CURRENT_ROUND:
      return "In Progress";
    case Constants.GAMESTATE_CURRENT_CREDITS:
      return "Finished";
    default:
      return "";
  }
};

export {
  selectGameState,
  selectLoadingGame,
  selectGameObject,
  selectGameId,
  selectGameStatusObject,
};

export default gameStateSlice.reducer;
