import utils from "./firebaseUtils";
import auth from "./firebaseAuth";
import game from "./game";
import question from "./question";
import player from "./player";
import submission from "./submission";
import editor from "./editor";
import superlatives from "./superlatives";

const api = {
  initialize: utils.initialize,
  stopWatching: utils.stopWatching,
  ...auth,
  ...game,
  ...question,
  ...player,
  ...submission,
  ...editor,
  ...superlatives,
};

export default api;
