import logging from "utils/logging";
import utils from "./firebaseUtils";
import {
  FIREBASE_COLLECTION_GAMES,
  FIREBASE_COLLECTION_QUESTIONS,
  FIREBASE_FUNCTION_UPDATE_CATEGORY,
} from "./constants";

const log = logging.getLogger("firebase-editor-funcs");

const updateGameName = async (gameId, newName) => {
  const gameRef = utils.db().collection(FIREBASE_COLLECTION_GAMES).doc(gameId);
  await gameRef.update("name", newName);
};

const updateCategory = async (gameId, roundId, categoryIndex, newCategory) => {
  const editCategory = utils
    .functions()
    .httpsCallable(FIREBASE_FUNCTION_UPDATE_CATEGORY);
  const result = await editCategory({
    gameId,
    roundId,
    categoryIndex,
    newCategory,
  });
  log.info("Got result from updating category: ", result);
  return result;
};

const updateFinalCategory = async (gameId, newCategory) => {
  const gameRef = utils.db().collection(FIREBASE_COLLECTION_GAMES).doc(gameId);

  await gameRef.update("categories.final.category", newCategory);

  const gameSnap = await gameRef.get();
  const finalQuestionId = gameSnap.get("categories.final.question");

  const questionRef = utils
    .db()
    .collection(FIREBASE_COLLECTION_GAMES)
    .doc(gameId)
    .collection(FIREBASE_COLLECTION_QUESTIONS)
    .doc(finalQuestionId);

  await questionRef.update("category", newCategory);
};

const updateQuestion = async (gameId, questionId, newQuestionData) => {
  const questionRef = utils
    .db()
    .collection(FIREBASE_COLLECTION_GAMES)
    .doc(gameId)
    .collection(FIREBASE_COLLECTION_QUESTIONS)
    .doc(questionId);
  await questionRef.update(newQuestionData);
};
const api = {
  updateGameName,
  updateCategory,
  updateFinalCategory,
  updateQuestion,
};

export default api;
