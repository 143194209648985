import logging from "utils/logging";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Typography, makeStyles } from "@material-ui/core";
import { selectQuestionById, submitAnswerAction } from "./questionSlice";
import { selectCurrentSubmission } from "./submissionSlice";
import { selectGameStatusObject } from "../gamestate/gameStateSlice";
import {
  GAMESTATE_ROUNDSTATE_QUESTION_ANSWERING,
  GAMESTATE_ROUNDSTATE_QUESTION_JUDGING,
  GAMESTATE_ROUNDSTATE_QUESTION_READING,
  GAMESTATE_ROUNDSTATE_QUESTION_RESULTS,
  SUBMISSION_STATE_COMMITTED,
  SUBMISSION_STATE_CORRECT,
} from "../gamestate/constants";
import AnswerSubmitButton from "./components/AnswerSubmitButton";
import AnswerTextField from "./components/AnswerTextField";
import QuestionCountdown from "../questionCountdown/QuestionCountdown";
import * as Constants from "../gamestate/constants";
import { updateGameStateAction } from "../gamestate/stateActions";

const log = logging.getLogger("GameComponent");

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#1700a0",
    color: "white",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  questionHeader: {
    fontFamily: "Helvetica Inserat",
    fontSize: "1.2em",
  },
  clue: {
    fontFamily: "Korinna",
    fontSize: "1.5em",
    textShadow: `0px 0px 0 rgb(-10,-10,0),
                 1px 1px 0 rgb(-20,-20,0),
                 2px 2px 0 rgb(-30,-30,0),
                 3px 3px 3px rgba(0,0,0,0),
                 3px 3px 1px rgba(0,0,0,0.5),
                 0px 0px 5px rgba(0,0,0,.2)`,
    letterSpacing: "3px",
    fontWeight: "bold",
  },
  result: {
    fontFamily: "Helvetica Inserat",
    fontSize: "1.2em",
  },
  plusPoints: {
    color: "#4ce74c",
  },
  minusPoints: {
    color: "#e74c4c",
  },
  correctAnswer: {
    marginTop: theme.spacing(2),
    fontFamily: "Korinna",
    fontSize: "1.2em",
  },
  countdown: {
    margin: theme.spacing(3),
  },
  prompt: {
    fontFamily: "Korinna",
    paddingTop: theme.spacing(2),
    fontSize: "1.5em",
  },
}));

const Question = ({ gameId, userId, playerId, questionId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // ---- Local state items
  const [answer, setAnswer] = useState("");

  // ---- Selectors from global redux state
  const questionObject = useSelector((state) =>
    selectQuestionById(state, questionId)
  );
  const submissionObject = useSelector(selectCurrentSubmission);
  const gameState = useSelector(selectGameStatusObject);

  // ---- Local functions/actions
  const onSubmit = () => {
    log.info("Submitting answer: ", answer);
    // Maybe make this a big ole thunk action
    dispatch(submitAnswerAction(gameId, questionId, playerId, answer));

    if (questionObject.dailyDouble) {
      // It's a daily double, so we can also advance the state to judging
      const { endTime, ...rest } = gameState;
      const newState = {
        ...rest,
        roundState: Constants.GAMESTATE_ROUNDSTATE_QUESTION_JUDGING,
      };
      dispatch(updateGameStateAction(gameId, newState));
    }
  };

  // ---- Render the outputs

  if (!questionObject) {
    return <div>Loading...</div>;
  }

  let responseArea = <div />;
  let button = <div />;
  let extra = <div />;

  let prompt = questionObject.prompt ? (
    <Typography className={classes.prompt}>{questionObject.prompt}</Typography>
  ) : (
    <div />
  );

  switch (gameState.roundState) {
    case GAMESTATE_ROUNDSTATE_QUESTION_READING:
      prompt = <div />;
      // button = <AnswerSubmitButton disabled={true} text={"Waiting..."} />;
      // let val = "";
      // if (submissionObject) {
      //   val = submissionObject.answer;
      // }
      // responseArea = (
      //   <AnswerTextField disabled={true} label={"Answer"} value={val} />
      // );
      break;
    case GAMESTATE_ROUNDSTATE_QUESTION_ANSWERING:
      if (submissionObject && submissionObject.answer) {
        responseArea = (
          <AnswerTextField
            disabled={true}
            label="My Answer"
            value={submissionObject.answer}
          />
        );
        button = (
          <AnswerSubmitButton
            completed={true}
            disabled={true}
            text={"Submitted"}
          />
        );
      } else {
        responseArea = (
          <AnswerTextField
            autoFocus
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            label={"Answer"}
          />
        );
        const fieldEmpty = answer.length === 0;
        button = (
          <AnswerSubmitButton
            disabled={fieldEmpty}
            text={"Submit"}
            onClick={onSubmit}
          />
        );
      }
      extra = (
        <div className={classes.countdown}>
          <QuestionCountdown
            endTimeStr={questionObject.endTime}
            startTimeStr={questionObject.startTime}
            roundState={gameState.roundState}
            shouldPlayFinalMusic={false}
          />
        </div>
      );

      break;
    case GAMESTATE_ROUNDSTATE_QUESTION_JUDGING:
      responseArea = (
        <AnswerTextField
          disabled={true}
          label="My Answer"
          value={submissionObject ? submissionObject.answer : ""}
        />
      );
      break;
    case GAMESTATE_ROUNDSTATE_QUESTION_RESULTS:
      if (submissionObject) {
        responseArea = (
          <AnswerTextField
            disabled={true}
            label="My Answer"
            value={submissionObject ? submissionObject.answer : ""}
          />
        );

        if (
          submissionObject.status === SUBMISSION_STATE_CORRECT ||
          (submissionObject.status === SUBMISSION_STATE_COMMITTED &&
            submissionObject.points > 0)
        ) {
          extra = (
            <Typography variant="h5" className={classes.result}>
              ✅ Correct!{" "}
              <span className={classes.plusPoints}>
                +${submissionObject.points}
              </span>
            </Typography>
          );
        } else {
          extra = (
            <div>
              <div style={{ margin: "8px" }} />
              <Typography variant="h5" className={classes.result}>
                ❌ &nbsp;Incorrect!{" "}
                <span className={classes.minusPoints}>
                  -${Math.abs(submissionObject.points)}
                </span>
              </Typography>
              <Typography variant="h6" className={classes.correctAnswer}>
                Correct Answer: {questionObject.answers[0]}
              </Typography>
            </div>
          );
        }
      } else {
        extra = (
          <div>
            <div style={{ margin: "8px" }} />
            <Typography variant="h6" className={classes.correctAnswer}>
              Answer: {questionObject.answers[0]}
            </Typography>
          </div>
        );
      }
      break;
    default:
      break;
  }

  let questionValue = questionObject.value
    ? questionObject.value.toLocaleString()
    : "0";

  if (submissionObject && submissionObject.wager) {
    log.debug("Submission object: ", submissionObject, questionObject);
    questionValue = submissionObject.wager.toLocaleString();
  }

  return (
    <Container maxWidth="sm" className={classes.root}>
      <div>
        <Typography
          variant="h4"
          gutterBottom
          className={classes.questionHeader}
        >
          {questionObject.category.toUpperCase()} | ${questionValue}
        </Typography>
      </div>
      <div>
        <Typography variant="body1" gutterBottom className={classes.clue}>
          {questionObject.clue.toUpperCase()}
        </Typography>
      </div>
      <div>{prompt}</div>
      <div>{responseArea}</div>
      <div>{button}</div>
      <div>{extra}</div>
    </Container>
  );
};

export default Question;
