import logging from "utils/logging";
import utils from "./firebaseUtils";
import {
  FIREBASE_COLLECTION_GAMES,
  FIREBASE_COLLECTION_QUESTIONS,
} from "./constants";
import { loadDiffOfModelsForQuerySnapshot } from "./firebaseModels";

const log = logging.getLogger("firebase/question");

const watchQuestionsForGameId = (gameId, onChange) => {
  log.debug("Watching questions for game: ", gameId);

  const questionsRef = utils
    .db()
    .collection(FIREBASE_COLLECTION_GAMES)
    .doc(gameId)
    .collection(FIREBASE_COLLECTION_QUESTIONS);

  return questionsRef.onSnapshot(async (qSnap) => {
    let diff = await loadDiffOfModelsForQuerySnapshot(qSnap);
    log.debug("Got diffed questions: ", diff);
    onChange(diff);
  });
};

const markQuestionSelected = async (gameId, questionId) => {
  const questionsRef = utils
    .db()
    .collection(FIREBASE_COLLECTION_GAMES)
    .doc(gameId)
    .collection(FIREBASE_COLLECTION_QUESTIONS)
    .doc(questionId);

  await questionsRef.update({
    status: "active",
    picked: true,
  });
};

const markQuestionOpenForSubmissions = async (gameId, questionId, endTime) => {
  const questionsRef = utils
    .db()
    .collection(FIREBASE_COLLECTION_GAMES)
    .doc(gameId)
    .collection(FIREBASE_COLLECTION_QUESTIONS)
    .doc(questionId);

  await questionsRef.update({
    status: "active",
    picked: true,
    endTime: endTime,
    startTime: utils.firestore().FieldValue.serverTimestamp(),
  });
};

const api = {
  watchQuestionsForGameId,
  markQuestionSelected,
  markQuestionOpenForSubmissions,
};

export default api;
