import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  categoryCell: {
    height: "80%",
    minHeight: "100px",
    minWidth: "165px",
    maxWidth: "400px",
    maxHeight: "300px",
    textAlign: "center",
    padding: theme.spacing(2),
    margin: "auto",
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#1700a0",
    fontFamily: "Helvetica Inserat",
    fontSize: "3em",
    color: "white",
    textShadow: "4px 4px 2px #000000",
  },
}));

const FinalCategoryCell = ({ label, onClick, ...props }) => {
  const classes = useStyles();

  return (
    <Paper {...props} className={classes.categoryCell} onClick={onClick}>
      {label.toUpperCase()}
    </Paper>
  );
};

export default FinalCategoryCell;
