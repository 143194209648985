import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles((theme) => ({
  answerField: {
    // backgroundColor: "#ffffff",
    color: "white",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
      "&.Mui-error fieldset": {
        borderColor: "red",
      },
      "& input": {
        color: "white",
      },
    },
    margin: theme.spacing(3),
  },
}));

const AnswerTextField = ({
  value,
  disabled = false,
  label,
  onChange,
  number = false,
  inputComponent = "input",
  ...others
}) => {
  const classes = useStyles();
  return (
    <TextField
      {...others}
      id="answer-text-field"
      style={{ margin: 8 }}
      label={label}
      value={value}
      fullWidth
      disabled={disabled}
      type={number ? "number" : "text"}
      InputProps={{
        readOnly: disabled,
        inputComponent: inputComponent,
        inputMode: "numeric",
      }}
      margin="normal"
      // color="secondary"
      className={classes.answerField}
      InputLabelProps={{
        shrink: true,
        style: { color: fade("#FFFFFF", 0.7) },
      }}
      variant="outlined"
      onChange={onChange}
    />
  );
};

export default AnswerTextField;
