import logging from "utils/logging";
import { createAction } from "@reduxjs/toolkit";
import {
  LISTENER_ACTION_CLOSE,
  LISTENER_ACTION_OPEN,
  MODELS_UPDATED,
} from "./constants";
import { normalize } from "normalizr";

const log = logging.getLogger("redux/actions"); //eslint-disable-line no-unused-vars

const createOpenListenerAction = (actionType, listenerId) => (
  payload,
  openListenerFunc
) => ({
  type: actionType,
  listenerAction: LISTENER_ACTION_OPEN,
  openFunction: openListenerFunc,
  listenerId,
  payload,
});

const createCloseListenerAction = (actionType, listenerId) => (payload) => ({
  type: actionType,
  listenerAction: LISTENER_ACTION_CLOSE,
  listenerId,
  payload,
});

const modelsUpdated = createAction(
  MODELS_UPDATED,
  function prepare(modelDiff, schema) {
    const addedNormalized = normalize(modelDiff.added, [schema]);
    const removedNormalized = normalize(modelDiff.deleted, [schema]);
    const updatedNormalized = normalize(modelDiff.updated, [schema]);

    return {
      payload: {
        entityKey: schema.key,
        added: addedNormalized,
        removed: removedNormalized,
        updated: updatedNormalized,
      },
    };
  }
);

export { createOpenListenerAction, createCloseListenerAction, modelsUpdated };
