import React from "react";
import logging from "utils/logging";
import { Container, makeStyles, Typography } from "@material-ui/core";
import FirebaseAuth from "provider/components/FirebaseAuth";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { selectDestination, selectUserIsAuthed } from "./authSlice";
import { FullBackgroundColor } from "../components/FullBackgroundColor";

const log = logging.getLogger("Groupardy Auth"); //eslint-disable-line no-unused-vars

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    background: "Blue",
    margin: 0,
  },
  headline: {
    color: "white",
    textAlign: "center",
    margin: theme.spacing(3),
  },
}));

const GroupardyAuth = () => {
  const styles = useStyles();

  const userIsAuthed = useSelector(selectUserIsAuthed);
  const dest = useSelector(selectDestination);

  if (userIsAuthed) {
    log.debug("User authed. Redirecting to: ", dest, dest ? dest : "/manage");
    return <Redirect to={dest ? dest : "/manage"} />;
  }

  return (
    <div className={styles.root}>
      <FullBackgroundColor color="blue" />
      <Typography variant="h2" className={styles.headline}>
        Sign In to Groupardy
      </Typography>
      <Container maxWidth="sm">
        {/*<Card>*/}
        <FirebaseAuth />
        {/*</Card>*/}
      </Container>
    </div>
  );
};

export default GroupardyAuth;
