import React from "react";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectAllQuestionsForRound } from "../question/questionSlice";
import { Grid, Paper } from "@material-ui/core";
import ScaleText from "react-scale-text";
import { makeStyles } from "@material-ui/core/styles";
import {
  editQuestion,
  editCategory,
  selectEditingQuestionId,
  selectEditingCategory,
} from "./editorSlice";
import logging from "utils/logging";
import { GAMESTATE_ROUND_FINAL } from "../gamestate/constants";
import { EditCategoryModal, EditQuestionModal } from "./EditorModal";
import "./gameEditor.css";

const log = logging.getLogger("EditorBoard");
log.setLevel("error");

const useStyles = makeStyles((theme) => ({
  rootGrid: {},
  editorCell: {
    width: "150px",
    height: "75px",
    backgroundColor: "#656565",
    color: "white",
    textAlign: "center",
    // padding: theme.spacing(1),
    margin: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    cursor: "pointer",
    fontFamily: "swiss911_xcm_btregular",
    // fontSize: "3em",

    "&:hover": {
      background: "#868686",
    },
  },
  dailydouble: {
    backgroundColor: "#abab11",
  },
  cellText: {
    height: "75px",
    justifyContent: "center",
  },
}));

const EditorCell = ({ label, questionId, isCategory, isDD }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const onClick = () => {
    if (isCategory) {
      dispatch(editCategory({ categoryNum: questionId }));
    } else {
      dispatch(editQuestion({ questionId }));
    }
  };

  return (
    <Paper
      className={clsx([classes.editorCell, isDD && classes.dailydouble])}
      onClick={onClick}
    >
      <ScaleText minFontSize={25} maxFontSize={40}>
        <span className={classes.cellText}>{label.toUpperCase()}</span>
      </ScaleText>
    </Paper>
  );
};

const EditorRow = ({ cellData, isCategory }) => {
  return (
    <React.Fragment>
      <Grid container item>
        {cellData &&
          cellData.map((cell, idx) => (
            <Grid key={`grid-row-${cell.label}-${idx}`} item xs>
              <EditorCell
                label={cell.label}
                questionId={isCategory ? idx : cell.id}
                isCategory={isCategory}
                isDD={cell.dailyDouble}
              />
            </Grid>
          ))}
      </Grid>
    </React.Fragment>
  );
};

const EditorGrid = ({ questions }) => {
  return (
    <Grid
      container
      spacing={1}
      alignItems="stretch"
      // flexGrow={1}
    >
      <EditorRow cellData={questions.categories} isCategory={true} />
      <EditorRow cellData={questions[1]} />
      <EditorRow cellData={questions[2]} />
      <EditorRow cellData={questions[3]} />
      <EditorRow cellData={questions[4]} />
      <EditorRow cellData={questions[5]} />
    </Grid>
  );
};

const FinalRoundGrid = ({ question }) => {
  return (
    <Grid container spacing={1}>
      <Grid container spacing={1} alignItems="stretch">
        <Grid key={`grid-row-final-category`} item xs={12} md>
          <EditorCell
            label={question.category}
            questionId={0}
            isCategory={true}
            isDD={false}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        alignItems="stretch"
        // flexGrow={1}
      >
        <Grid key={`grid-row-final-clue`} item xs={12} md>
          <EditorCell
            label={"wager"}
            questionId={question.question}
            isCategory={false}
            isDD={false}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const EditorBoard = () => {
  const { round } = useParams();

  const questions = useSelector(selectAllQuestionsForRound(round));
  const editingQuestionId = useSelector(selectEditingQuestionId);
  const editingCategoryIdx = useSelector(selectEditingCategory);

  log.debug("Round: ", round, "Questions: ", questions);

  return (
    <div>
      {round === GAMESTATE_ROUND_FINAL ? (
        <FinalRoundGrid question={questions} />
      ) : (
        <EditorGrid questions={questions} />
      )}
      {editingQuestionId && (
        <EditQuestionModal questionId={editingQuestionId} />
      )}
      {editingCategoryIdx !== null && <EditCategoryModal />}
    </div>
  );
};

export default EditorBoard;
