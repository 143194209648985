import fbUtils from "./firebaseUtils";
import logging from "../utils/logging";
import { loadUserModelForFirebaseUser } from "./firebaseModels";

const log = logging.getLogger("firebase-auth");

const getAnonymousUser = async () => {
  const auth = fbUtils.auth();
  try {
    await auth.signInAnonymously();
    // log.debug("Got anonymous user creds: ", creds, auth.currentUser);
    return auth.currentUser;
  } catch (e) {
    log.error("Error getting anonymous user: ", e);
  }
};

const watchAuthedUser = async (onChange) => {
  const auth = fbUtils.auth();
  try {
    await auth.onAuthStateChanged((firebaseUser) => {
      const user = loadUserModelForFirebaseUser(firebaseUser);
      onChange(user);
    });
  } catch (e) {
    log.error("Error watching auth state: ", e);
  }
};

const logOut = async () => {
  const auth = fbUtils.auth();
  return auth.signOut();
};

const api = {
  getAnonymousUser,
  watchAuthedUser,
  logOut,
};

export default api;
