import React from "react";
import { Card, Typography, CardContent, makeStyles } from "@material-ui/core";
import { useSpring, animated } from "react-spring";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    textAlign: "center",
    minWidth: "600px",
  },
  header: {
    fontFamily: "Helvetica Inserat",
    color: "black",
    fontSize: "4em",
  },
  subHeader: {
    color: "#5f5f5f",
    fontSize: "2em",
  },
  winner: {
    padding: theme.spacing(3),
    color: "black",
    fontSize: "6.5em",
  },
  winnerDetail: {
    color: "#5f5f5f",
    fontSize: "2em",
  },
}));

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const SuperlativeCard = ({ superlative, doneShowingCallback }) => {
  const classes = useStyles();

  const springProps = useSpring({
    to: async (next, cancel) => {
      await next({ transform: "translate(0px, 0px)" });
      await sleep(5000);
      await next({ transform: "translate(0px, -1000px)" });
      doneShowingCallback();
    },
    from: { transform: "translate(0px, 1500px)" },
  });

  return (
    <animated.div style={springProps}>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h3" className={classes.header}>
            {superlative.header}
          </Typography>
          <Typography variant="h5" className={classes.subHeader}>
            {superlative.subhead}
          </Typography>
          <Typography variant="h1" className={classes.winner}>
            {superlative.winner}
          </Typography>
          <Typography variant="h5" className={classes.winnerDetail}>
            {superlative.winnerDetail}
          </Typography>
        </CardContent>
      </Card>
    </animated.div>
  );
};

export { SuperlativeCard };
