import React from "react";
import "./App.css";
import { HelmetProvider } from "react-helmet-async";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { JoinRoom } from "features/joinRoom/JoinRoom";
import { Game as GameClient } from "features/game/Game";
import GameController from "features/manager/controller/GameController";
import AnonymousUserWrapper from "features/player/AnonymousUserWrapper";
import { GameWatcherWrapper } from "./features/gamestate/GameWatcherWrapper";
import GameBoardState from "./features/gameboard/GameBoardState";
import GameEditor from "./features/gameEditor/GameEditor";
import { CreateGame } from "./features/manager/createGame/CreateGame";
import GroupardyAuth from "./features/auth/GroupardyAuth";

import logging from "utils/logging";
import AuthedUserRoute from "./features/auth/AuthedUserRoute";
import AuthedUserWatcherWrapper from "./features/auth/AuthedUserWatcherWrapper";
import { ManagerHome } from "./features/manager/managerHome/ManagerHome";
import ManagerWrapper from "./features/manager/ManagerWrapper";
import ManagedGameHome from "./features/manager/manageGame/ManagedGameHome";
const log = logging.getLogger("App (Router)");

const ManagerRouter = () => {
  const location = useLocation();
  let { path } = useRouteMatch();

  log.debug("Manager router: ", location);

  return (
    <ManagerWrapper>
      <Switch>
        <Route exact path={`${path}/game/:gameId/controller`}>
          <GameWatcherWrapper>
            <GameController />
          </GameWatcherWrapper>
        </Route>
        <Route path={`${path}/game/:gameId`}>
          <GameWatcherWrapper>
            <ManagedGameHome />
          </GameWatcherWrapper>
        </Route>
        <Route path={`${path}/create`}>
          <CreateGame />
        </Route>
        <Route path={`${path}/`}>
          <ManagerHome />
        </Route>
      </Switch>
    </ManagerWrapper>
  );
};

function primaryRouter(isAuthenticated) {
  return (
    <AuthedUserWatcherWrapper>
      <Switch>
        {/* Index */}
        <Route exact path="/">
          <JoinRoom />
        </Route>

        {/* Anonymous users: game playing */}
        <Route path="/game/:roomId">
          <AnonymousUserWrapper>
            <GameWatcherWrapper>
              <GameClient />
            </GameWatcherWrapper>
          </AnonymousUserWrapper>
        </Route>

        {/* Game Board - to be screen shared */}
        <Route exact path="/manage/game/:gameId/gameboard">
          <GameWatcherWrapper>
            <GameBoardState />
          </GameWatcherWrapper>
        </Route>

        {/* Manager routes only below */}
        <Route exact path="/auth">
          <GroupardyAuth />
        </Route>

        {/* All internal/manager facing app */}
        <AuthedUserRoute path="/manage" redirect="/auth">
          <ManagerRouter />
        </AuthedUserRoute>
      </Switch>
    </AuthedUserWatcherWrapper>
  );
}

const App = () => {
  const router = primaryRouter();
  return (
    <div className="App">
      <HelmetProvider>
        <Router>{router}</Router>
      </HelmetProvider>
    </div>
  );
};

export default App;
