import logging from "utils/logging";
import React, { useEffect, useState } from "react";
import { LinearProgress } from "@material-ui/core";
import useSound from "use-sound";
import thinkSong from "sounds/think.mp3";

import { GAMESTATE_ROUNDSTATE_QUESTION_ANSWERING } from "../gamestate/constants";

const log = logging.getLogger("QuestionCountdown"); //eslint-disable-line no-unused-vars

const QuestionCountdown = ({
  endTimeStr,
  startTimeStr,
  onTimeReachedZero,
  roundState,
  shouldPlayFinalMusic,
}) => {
  const getTimeLeft = () => {
    const endDate = new Date(endTimeStr);
    const now = new Date();

    const diff = +endDate - +now;
    // log.info("Time diff: ", diff);
    // const timeDiff = diff;
    return Math.max(0, diff);
  };

  const [timeLeft, setTimeLeft] = useState(getTimeLeft());

  const [playFinalMusic, { stop: stopFinalMusic }] = useSound(thinkSong);

  useEffect(() => {
    log.debug("Should play music? ", shouldPlayFinalMusic, playFinalMusic);
    if (shouldPlayFinalMusic && playFinalMusic) {
      playFinalMusic();
    }
    return function cleanup() {
      if (shouldPlayFinalMusic && stopFinalMusic) {
        stopFinalMusic();
      }
    };
  }, [playFinalMusic, shouldPlayFinalMusic, stopFinalMusic]);

  useEffect(() => {
    const timer = setTimeout(() => {
      let timeLeft = getTimeLeft();
      timeLeft = timeLeft / 1000.0;
      // log.debug("Time left effect: ", timeLeft);
      if (timeLeft === null) {
        setTimeLeft(null);
      } else if (timeLeft <= 0) {
        if (onTimeReachedZero) {
          onTimeReachedZero();
        }
      } else {
        setTimeLeft(timeLeft);
      }
    }, 100);

    return function cleanup() {
      clearTimeout(timer);
    };
  });

  if (roundState !== GAMESTATE_ROUNDSTATE_QUESTION_ANSWERING) {
    return <div />;
  }

  const sTime = new Date(startTimeStr);
  const eTime = new Date(endTimeStr);

  const totalQuestionTime = Math.abs(eTime - sTime) / 1000;

  let percent = Math.min(100, (100 * timeLeft) / totalQuestionTime);
  // log.info("Time left: ", percent, "from: ", startTimeStr, endTimeStr);

  if (startTimeStr === null) {
    percent = 100;
  }

  return <LinearProgress variant="determinate" value={percent} />;
};

export default QuestionCountdown;
