import logging from "utils/logging";
import utils from "./firebaseUtils";
import {
  FIREBASE_COLLECTION_GAMES,
  FIREBASE_COLLECTION_SUPERLATIVES,
  FIREBASE_FUNCTION_GENERATE_SUPERLATIVES,
} from "./constants";
import { loadModelsForQuerySnapshot } from "./firebaseModels";

const log = logging.getLogger("Provider/Superlatives");

const generateSuperlatives = async (gameId) => {
  const generateSuperlativesFunc = utils
    .functions()
    .httpsCallable(FIREBASE_FUNCTION_GENERATE_SUPERLATIVES);
  const result = await generateSuperlativesFunc({ gameId });
  log.debug("Got result of generating superlatives: ", result);
};

const getSuperlatives = async (gameId) => {
  // Run the "get superlatives" function, but then just to be safe, get the docs via query too.

  const superlativesRef = utils
    .db()
    .collection(FIREBASE_COLLECTION_GAMES)
    .doc(gameId)
    .collection(FIREBASE_COLLECTION_SUPERLATIVES);

  const superlativeSnaps = await superlativesRef.get();

  return await loadModelsForQuerySnapshot(superlativeSnaps);
};

const api = {
  generateSuperlatives,
  getSuperlatives,
};

export default api;
