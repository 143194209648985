import logging from "utils/logging";
import { MODELS_UPDATED } from "./constants";

const log = logging.getLogger("Model Reducer");

const buildModelReducer = ({ builder, entity, adapter, loadingKey }) => {
  builder.addCase(MODELS_UPDATED, (state, action) => {
    if (action.payload.entityKey === entity.key) {
      state[loadingKey] = false;
      if (action.payload.added.result.length > 0) {
        log.debug(
          "About to add: ",
          action.payload.added.entities[entity.key],
          "to state: ",
          state
        );
        adapter.addMany(state, action.payload.added.entities[entity.key]);
      }
      if (action.payload.updated.result.length > 0) {
        adapter.upsertMany(state, action.payload.updated.entities[entity.key]);
      }
      if (action.payload.removed.result.length > 0) {
        log.debug(
          "About to remove: ",
          action.payload.removed.result,
          "from state: ",
          state
        );
        adapter.removeMany(state, action.payload.removed.result);
      }
    }
  });
};

export { buildModelReducer };
