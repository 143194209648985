import { useEffect } from "react";
import {
  stopWatchingAuthedUserAction,
  watchAuthedUserAction,
} from "./authSlice";
import { useDispatch } from "react-redux";

const AuthedUserWatcherWrapper = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(watchAuthedUserAction());

    return function cleanup() {
      dispatch(stopWatchingAuthedUserAction());
    };
  }, [dispatch]);

  return props.children;
};

export default AuthedUserWatcherWrapper;
