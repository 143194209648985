import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  loading: {
    width: "100%",
    height: "100%",
    minHeight: "300px",
  },
}));

const FullScreenSpinner = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classes.loading}
    >
      <CircularProgress />
    </Grid>
  );
};

export { FullScreenSpinner };
