import React from "react";
import { FullBackgroundColor } from "../components/FullBackgroundColor";
import { CssBaseline, makeStyles } from "@material-ui/core";
import { AppBarSpacer, ManagerAppBar } from "./appBar/AppBar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
}));

const ManagerWrapper = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FullBackgroundColor color="white" />
      <CssBaseline />
      <ManagerAppBar title="Groupardy" />
      <main className={classes.content}>
        <AppBarSpacer />
        {props.children}
      </main>
    </div>
  );
};

export default ManagerWrapper;
