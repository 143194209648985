import logging from "utils/logging";
import Provider from "provider";
import {
  createCloseListenerAction,
  createOpenListenerAction,
  modelsUpdated,
} from "redux/actions";
import {
  createEntityAdapter,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import { buildModelReducer } from "../../redux/reducers";
import { gameEntity } from "../../models/game";
import { getSimpleGameStateForGame } from "../gamestate/gameStateSlice";

const log = logging.getLogger("Manager Slice"); //eslint-disable-line no-unused-vars

const managedGamesAdapter = createEntityAdapter();
const initialState = managedGamesAdapter.getInitialState({
  loadingGames: true,
});

const managedGamesSlice = createSlice({
  name: "managedGames",
  initialState,
  reducers: {
    loadingManagedGames(state) {
      state.loadingGames = true;
    },
    gamesLoaded(state, action) {
      state.loadingGames = false;
    },
  },
  extraReducers: (builder) => {
    buildModelReducer({
      builder,
      entity: gameEntity,
      loadingKey: "loadingGames",
      adapter: managedGamesAdapter,
    });
  },
});

const watchManagedGamesActionBase = createOpenListenerAction(
  "manage/watch_managed_games",
  "watch-managed-games"
);

const stopWatchingManagedGamesActionBase = createCloseListenerAction(
  "manage/stop_watching_managed_games",
  "watch-managed-games"
);

const watchManagedGamesAction = (userId) => (dispatch) => {
  dispatch(managedGamesSlice.actions.loadingManagedGames());
  dispatch(
    watchManagedGamesActionBase({ userId }, async () => {
      log.info("Watching managed games for user: ", userId);
      return await Provider.watchManagedGames(userId, (games) => {
        log.info("Got updated managed games: ", games);
        dispatch(modelsUpdated(games, gameEntity));
      });
    })
  );
};

const stopWatchingManagedGamesAction = () => (dispatch) => {
  dispatch(stopWatchingManagedGamesActionBase({}));
};

/* --- Selectors ---- */
const selectManagedGamesState = (state) => state.managedGames;

const selectManagedGamesLoading = createSelector(
  selectManagedGamesState,
  (s) => s.loadingGames
);

const selectSimpleGameStatus = (gameId) =>
  createSelector(
    (state) => selectManagedGameById(state, gameId),
    (game) => {
      return getSimpleGameStateForGame(game);
    }
  );

/* ---- Exports ---- */

export const {
  selectById: selectManagedGameById,
  selectIds: selectManagedGamesIds,
  selectEntities: selectManagedGameEntities,
  selectAll: selectAllManagedGames,
  selectTotal: selectTotalManagedGames,
} = managedGamesAdapter.getSelectors((state) => state.managedGames);

export { selectManagedGamesLoading, selectSimpleGameStatus };

export { watchManagedGamesAction, stopWatchingManagedGamesAction };

export default managedGamesSlice.reducer;
