import logging from "utils/logging";
import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import Provider from "provider";
import {
  createCloseListenerAction,
  createOpenListenerAction,
  modelsUpdated,
} from "redux/actions";
import { questionEntity } from "models/question";
import { submissionLoaded } from "./submissionSlice";
import { buildModelReducer } from "../../redux/reducers";
import {
  selectGameObject,
  selectGameStatusObject,
} from "../gamestate/gameStateSlice";

const log = logging.getLogger("question-slice");

export const questionAdaptor = createEntityAdapter();
const initialState = questionAdaptor.getInitialState({
  loading: true,
  submittingAnswer: false,
});

const questionSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    loadingQuestions(state) {
      state.loading = true;
    },
    submittingAnswer(state, action) {
      state.submittingAnswer = true;
      state.entities[action.payload.questionId].submitting = true;
    },
    answerSubmitted(state, action) {
      state.submittingAnswer = false;
      state.answerSubmitted = true;
      state.entities[action.payload.questionId].submitted = true;
    },
  },
  extraReducers: (builder) => {
    buildModelReducer({
      builder,
      entity: questionEntity,
      adapter: questionAdaptor,
      loadingKey: "loading",
    });
  },
});

const watchQuestionsActionBase = createOpenListenerAction(
  "game/watch_questions",
  "watch-questions"
);

const stopWatchingQuestionsActionBase = createCloseListenerAction(
  "game/stop_watching_questions",
  "watch-questions"
);

const watchQuestionsAction = (gameId) => (dispatch) => {
  dispatch(questionSlice.actions.loadingQuestions);
  dispatch(
    watchQuestionsActionBase({ gameId }, async () => {
      log.info("Watching questions for game: ", gameId);
      let listener = await Provider.watchQuestionsForGameId(
        gameId,
        (questionsUpdate) => {
          log.info("Got updated question data: ", questionsUpdate);
          dispatch(modelsUpdated(questionsUpdate, questionEntity));
        }
      );
      return listener;
    })
  );
};

const stopWatchingQuestionsAction = (gameId) => (dispatch) => {
  dispatch(stopWatchingQuestionsActionBase({ gameId }));
};

const makeQuestionAvailableAction = (gameId, questionId, endTime) => async (
  dispatch
) => {
  await Provider.markQuestionOpenForSubmissions(gameId, questionId, endTime);
};

const submitAnswerAction = (gameId, questionId, playerId, answer) => async (
  dispatch,
  getState
) => {
  // "Submitting action" with ID
  dispatch(
    questionSlice.actions.submittingAnswer({
      gameId,
      questionId,
      playerId,
      answer,
    })
  );

  // log.info("Submitting answer: ", gameId, playerId, )

  const submission = await Provider.submitQuestionAction(
    gameId,
    questionId,
    playerId,
    answer
  );

  log.info("Got submission: ", submission);

  dispatch(submissionLoaded({ submission }));
  // dispatch(submissionSli.actions.submissionLoaded({ submission }));
  dispatch(questionSlice.actions.answerSubmitted({ questionId, answer }));
};

const submitWagerAction = (gameId, questionId, playerId, wager) => async (
  dispatch
) => {
  const submission = await Provider.submitWager(
    gameId,
    questionId,
    playerId,
    wager
  );

  log.info("Submitted wager for submission: ", submission);

  dispatch(submissionLoaded({ submission }));
};

export {
  watchQuestionsAction,
  stopWatchingQuestionsAction,
  submitAnswerAction,
  makeQuestionAvailableAction,
  submitWagerAction,
};

/* ---- Selectors ---- */

const selectQuestionsState = (state) => state.questions;
const selectLoadingQuestions = createSelector(
  selectQuestionsState,
  (s) => s.loading
);

export const {
  selectById: selectQuestionById,
  selectIds: selectQuestionIds,
  selectEntities: selectQuestionEntities,
  selectAll: selectAllQuestions,
  selectTotal: selectTotalQuestions,
} = questionAdaptor.getSelectors((state) => state.questions);

const selectCurrentQuestionObject = createSelector(
  (state) => state,
  selectGameStatusObject,
  (state, gameState) => {
    if (!gameState || !gameState.questionId) {
      return null;
    }

    return selectQuestionById(state, gameState.questionId);
  }
);

const getQuestionsForRound = (roundData, questions) => {
  let output = {
    categories: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
  };

  if (!Array.isArray(roundData) && roundData.category) {
    return roundData;
  }

  roundData.forEach((col) => {
    output["categories"].push({
      label: col["category"],
    });
    for (let i = 1; i <= 5; i++) {
      if (col[i]) {
        if (questions[col[i]]) {
          let qData = questions[col[i]];
          output[i].push({
            ...qData,
            isMoneyLabel: true,
          });
        }
      }
    }
  });
  return output;
};

const selectAllQuestionsForRound = (roundId) =>
  createSelector(
    [selectGameObject, selectQuestionEntities],
    (game, questions) => {
      const roundData = game.categories[roundId];
      return getQuestionsForRound(roundData, questions);
    }
  );

export {
  getQuestionsForRound,
  selectAllQuestionsForRound,
  selectLoadingQuestions,
  selectCurrentQuestionObject,
};

export default questionSlice.reducer;
