import React, { useEffect } from "react";
import clsx from "clsx";
import {
  Container,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { selectLeaderboardPlayers } from "./leaderboardSlice";
import { selectPlayer } from "../player/playerSlice";
import {
  selectPlayerSubmissionsForGameboardLeaderboard,
  stopWatchingQuestionSubmissionsAction,
  watchQuestionSubmissionsAction,
} from "../question/submissionSlice";
import logging from "utils/logging";
import {
  selectGameId,
  selectGameStatusObject,
} from "../gamestate/gameStateSlice";
import {
  GAMESTATE_CURRENT_ROUND,
  GAMESTATE_ROUND_FINAL,
  GAMESTATE_ROUNDSTATE_QUESTION_JUDGING,
  GAMESTATE_ROUNDSTATE_QUESTION_RESULTS,
} from "../gamestate/constants";
const log = logging.getLogger("Player Leaderboard");

const useStyles = makeStyles((theme) => ({
  gameboardLeaderboardRow: {
    backgroundColor: "#1700a0",
    color: "white",
    fontFamily: "Helvetica Inserat",
    fontSize: "1.2em",
    "& .MuiListItemText-primary": {
      fontFamily: "Helvetica Inserat",
      color: "white",
      fontSize: "1.1em",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    "& .MuiListItemText-secondary": {
      color: "white",
      fontSize: "0.7em",
    },
  },
  highlightedRow: {
    "& .MuiListItemText-primary": {
      color: "#e5ba32",
    },
  },
  whiteHeader: {
    color: "white",
    fontFamily: "Helvetica Inserat",
    fontSize: "1.5em",
    textAlign: "center",
  },
  gameboardLeaderboardRowMoney: {
    color: "white",
    fontFamily: "Swiss911 XCm BT",
    fontSize: "1.5em",
    textAlign: "center",
  },
  gameboardLeaderboardRowNegativeMoney: {
    color: "#bc1b1b",
    fontFamily: "Swiss911 XCm BT",
    fontSize: "1.75em",
    textAlign: "center",
  },
  highlightedRowMoney: {
    color: "#e5ba32",
    fontFamily: "Swiss911 XCm BT",
    fontSize: "1.5em",
    textAlign: "center",
  },
}));

const PlayerLeaderboardRow = ({ player, highlighted, index, submission }) => {
  const classes = useStyles();
  return (
    <ListItem
      className={clsx(
        classes.gameboardLeaderboardRow,
        highlighted && classes.highlightedRow
      )}
      divider={true}
    >
      <ListItemText
        primary={`${index + 1}. ${player.name}`}
        className={classes.gameboardLeaderboardRow}
        secondary={submission}
      />
      <Typography
        variant="h5"
        className={
          highlighted
            ? classes.highlightedRowMoney
            : player.score >= 0
            ? classes.gameboardLeaderboardRowMoney
            : classes.gameboardLeaderboardRowNegativeMoney
        }
        align="right"
      >
        {player.score >= 0
          ? "$" + player.score.toLocaleString()
          : "- $" + Math.abs(player.score).toLocaleString()}
      </Typography>
    </ListItem>
  );
};

const PlayerLeaderboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const gameStateObj = useSelector(selectGameStatusObject);
  const gameId = useSelector(selectGameId);
  const questionId = gameStateObj.questionId;
  const players = useSelector(selectLeaderboardPlayers);
  const gameState = useSelector(selectGameStatusObject);
  const players2 = useSelector(selectPlayerSubmissionsForGameboardLeaderboard);

  const currentPlayer = useSelector(selectPlayer);

  useEffect(() => {
    if (gameId && questionId) {
      dispatch(watchQuestionSubmissionsAction(gameId, questionId));
    }

    return function cleanup() {
      if (gameId && questionId) {
        dispatch(stopWatchingQuestionSubmissionsAction(gameId, questionId));
      }
    };
  }, [dispatch, questionId, gameId]);

  let playerList = players;
  if (
    gameState.currentState === GAMESTATE_CURRENT_ROUND &&
    gameState.round !== GAMESTATE_ROUND_FINAL &&
    (gameState.roundState === GAMESTATE_ROUNDSTATE_QUESTION_JUDGING ||
      gameState.roundState === GAMESTATE_ROUNDSTATE_QUESTION_RESULTS)
  ) {
    playerList = players2;
  }

  let list;
  if (!players) {
    list = <Typography>Loading...</Typography>;
  } else {
    list = playerList.map((player, idx) => (
      <PlayerLeaderboardRow
        player={player}
        key={`player-${player.id}`}
        index={idx}
        highlighted={player.id === currentPlayer.id}
        submission={player.submission ? player.submission.answer : null}
      />
    ));
  }

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Typography variant="h5">Leaderboard</Typography>
      <List>{list}</List>
    </Container>
  );
};

export default PlayerLeaderboard;
