import logging from "utils/logging";
import _ from "lodash";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import SignaturePad from "react-signature-pad-wrapper";
import { TextField, Container, Button, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  createPlayerForUserInGameAction,
  selectLoadingPlayer,
} from "features/player/playerSlice";
import trimCanvas from "utils/trimCanvas";

const log = logging.getLogger("MakePlayer screen");

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(3),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  signaturePad: {
    margin: 0,
    width: "100%",
  },
}));

const MakePlayer = ({ gameId, userId }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const signaturePad = useRef(null);

  const playerIsLoading = useSelector(selectLoadingPlayer);

  const [creatingPlayer, setCreatingPlayer] = useState(false);
  const [name, setName] = useState("");
  const [signError, setSignError] = useState(false);

  const trimSignature = () => {
    const sigPad = signaturePad.current;
    if (!sigPad) {
      return;
    }

    // let x = sigPad.instance.trimSignature();
    // log.debug("trimmed? ", x);
    const canvas = sigPad.canvas;

    log.debug("Got canvas: ", canvas);

    let copy = document.createElement("canvas");
    copy.width = canvas.width;
    copy.height = canvas.height;
    copy.getContext("2d").drawImage(canvas, 0, 0);
    // then trim it
    return trimCanvas(copy, { r: 23, g: 0, b: 160, a: 255 });
  };

  async function onCreatePlayer() {
    let signatureURL = null;

    if (signaturePad.current && signaturePad.current.isEmpty()) {
      log.debug("No signature");
      setSignError(true);
      return;
    } else {
      // log.debug(signaturePad.current.toDataURL("image/jpeg"));

      let trimmed = trimSignature();
      signatureURL = trimmed.toDataURL("image/jpeg");
      // log.debug("Trimmed: ", signatureURL);
    }

    setCreatingPlayer(true);

    dispatch(
      createPlayerForUserInGameAction(userId, gameId, name.trim(), signatureURL)
    );

    // setCreatingPlayer(false);
  }

  const resetSignature = () => {
    signaturePad.current.clear();
  };

  let disabled = false;
  let buttonCopy = "Join Game";

  if (_.isEmpty(name)) {
    disabled = true;
  } else if (creatingPlayer || playerIsLoading) {
    disabled = true;
    buttonCopy = "Joining game...";
  }

  log.debug("Joining: ", creatingPlayer, playerIsLoading, name, disabled);

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Typography variant="h2" gutterBottom>
        Welcome!
      </Typography>
      <Typography variant="body1" gutterBottom>
        What do you want to be called?
      </Typography>
      <div>
        <TextField
          id="room-code"
          label="e.g. Alex"
          variant="outlined"
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className={classes.signaturePad}>
        <Typography variant="body1" gutterBottom>
          Add your signature:
        </Typography>
        <SignaturePad
          height={300}
          options={{
            minWidth: 0.8,
            maxWidth: 2.5,
            backgroundColor: "#1700a0",
            penColor: "#ffffff",
          }}
          ref={signaturePad}
        />
        <Button onClick={() => resetSignature()}>Clear Signature</Button>
        {signError && (
          <Alert variant="outlined" severity="error">
            Please add your signature!
          </Alert>
        )}
      </div>
      <div>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          disabled={disabled}
          className={classes.margin}
          onClick={onCreatePlayer}
        >
          {buttonCopy}
        </Button>
      </div>
    </Container>
  );
};

export default MakePlayer;
