import logging from "utils/logging";
import React, { useEffect } from "react";
import {
  selectGameId,
  selectGameObject,
  selectLoadingGame,
  watchGameAction,
  watchRoomAction,
  stopWatchingGameAction,
  stopWatchingRoomAction,
} from "./gameStateSlice";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  stopWatchingQuestionsAction,
  watchQuestionsAction,
} from "../question/questionSlice";
import {
  watchPlayersAction,
  stopWatchingPlayersAction,
} from "../player/playerSlice";
import { FullScreenSpinner } from "../components/FullScreenSpinner";

const log = logging.getLogger("GameWatcherWrapper");

const GameWatcherWrapper = (props) => {
  const dispatch = useDispatch();

  let { roomId, gameId } = useParams();
  roomId = roomId ? roomId.trim().toUpperCase() : null;
  gameId = gameId ? gameId.trim() : null;

  const game = useSelector(selectGameObject);
  const selectedGameId = useSelector(selectGameId);
  const loading = useSelector(selectLoadingGame);

  useEffect(() => {
    // Watch the game
    if (roomId) {
      dispatch(watchRoomAction(roomId));
    } else if (gameId) {
      log.info("Watching game: ", gameId);
      dispatch(watchGameAction(gameId));
    }

    return function cleanup() {
      log.info("Cleaning up Game", roomId);
      if (roomId) {
        dispatch(stopWatchingRoomAction(roomId));
      } else if (gameId) {
        dispatch(stopWatchingGameAction(gameId));
      }
    };
  }, [dispatch, roomId, gameId]);

  useEffect(() => {
    if (selectedGameId) {
      dispatch(watchQuestionsAction(selectedGameId));
    }

    return function cleanup() {
      if (selectedGameId) {
        dispatch(stopWatchingQuestionsAction(selectedGameId));
      }
    };
  }, [dispatch, selectedGameId]);

  useEffect(() => {
    if (selectedGameId) {
      dispatch(watchPlayersAction(selectedGameId));
    }

    return function cleanup() {
      if (selectedGameId) {
        dispatch(stopWatchingPlayersAction(selectedGameId));
      }
    };
  }, [dispatch, selectedGameId]);

  // If we don't have a game yet, show a loading screen
  if (loading || !game) {
    return <FullScreenSpinner />;
  }

  return props.children;
};

export { GameWatcherWrapper };
