import _ from "lodash";
import {
  FIREBASE_COLLECTION_GAMES,
  FIREBASE_COLLECTION_PLAYERS,
  FIREBASE_COLLECTION_QUESTIONS,
  FIREBASE_COLLECTION_SUBMISSIONS,
  FIREBASE_COLLECTION_SUPERLATIVES,
} from "./constants";

const loadUserModelForFirebaseUser = (user) => {
  return _.pick(user, [
    "uid",
    "displayName",
    "email",
    "emailVerified",
    "isAnonymous",
    "photoURL",
  ]);
};

const loadModelForDocumentSnapshot = async (snapshot) => {
  const docType = snapshot.ref.parent.id;
  switch (docType) {
    case FIREBASE_COLLECTION_GAMES:
      return loadGameFromSnapshot(snapshot);
    case FIREBASE_COLLECTION_QUESTIONS:
      return loadQuestionFromSnapshot(snapshot);
    case FIREBASE_COLLECTION_PLAYERS:
      return loadPlayerFromSnapshot(snapshot);
    case FIREBASE_COLLECTION_SUBMISSIONS:
      return loadSubmissionFromSnapshot(snapshot);
    case FIREBASE_COLLECTION_SUPERLATIVES:
      return loadSuperlativeFromSnapshot(snapshot);
    default:
      return null;
  }
};

const loadModelsForQuerySnapshot = (snapshot) => {
  if (snapshot.empty) {
    return Promise.resolve([]);
  }

  const promises = snapshot.docs.map((docSnapshot) =>
    loadModelForDocumentSnapshot(docSnapshot)
  );
  return Promise.all(promises);
};

const loadDiffOfModelsForQuerySnapshot = (snapshot) => {
  // Return an object of normalized values within { added: [], deleted: [], updated: [] }
  let result = {
    added: [],
    deleted: [],
    updated: [],
  };

  let promises = [];

  snapshot.docChanges().forEach((docChange) => {
    let changeType = docChange.type;
    let snapshot = docChange.doc;
    promises.push(
      loadModelForDocumentSnapshot(snapshot).then((model) => {
        // Do something here

        switch (changeType) {
          case "added":
            result.added.push(model);
            break;
          case "modified":
            result.updated.push(model);
            break;
          case "removed":
            result.deleted.push(model);
            break;
          default:
            break;
        }
      })
    );
  });

  return Promise.all(promises).then(() => result);
};

const loadGameFromSnapshot = async (snapshot) => {
  const data = snapshot.data();

  if (!data) {
    return null;
  }

  const { createdAt, ...rest } = data;

  return {
    ...rest,
    id: snapshot.id,
    createdAt: createdAt ? createdAt.toDate().toJSON() : null,
    // docRef: snapshot.ref,
  };
};

const loadQuestionFromSnapshot = async (snapshot) => {
  const data = snapshot.data();
  const gameId = snapshot.ref.parent.parent.id;
  const { startTime, ...rest } = data;

  return {
    ...rest,
    id: snapshot.id,
    gameId: gameId,
    startTime: startTime ? startTime.toDate().toJSON() : null,
  };
};

const loadSubmissionFromSnapshot = async (snapshot) => {
  const data = snapshot.data();
  const questionId = snapshot.ref.parent.parent.id;
  const gameId = snapshot.ref.parent.parent.parent.parent.id;
  const { submittedAt, ...rest } = data;

  return {
    ...rest,
    id: snapshot.id,
    questionId,
    gameId,
    submittedAt: submittedAt ? submittedAt.toDate().toJSON() : null,
  };
};

const loadPlayerFromSnapshot = async (snapshot) => {
  const data = snapshot.data();
  const gameId = snapshot.ref.parent.parent.id;
  return {
    ...data,
    id: snapshot.id,
    gameId: gameId,
  };
};

const loadSuperlativeFromSnapshot = async (snapshot) => {
  const data = snapshot.data();
  const gameId = snapshot.ref.parent.parent.id;
  return {
    ...data,
    id: snapshot.id,
    gameId: gameId,
  };
};

export {
  loadUserModelForFirebaseUser,
  loadModelForDocumentSnapshot,
  loadModelsForQuerySnapshot,
  loadDiffOfModelsForQuerySnapshot,
};
