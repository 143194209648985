import React, { useEffect } from "react";
import logging from "utils/logging";
import { Redirect, Route, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAuthLoading,
  redirectToAuthFrom,
  selectUserIsAuthed,
} from "./authSlice";
import { FullScreenSpinner } from "../components/FullScreenSpinner";

const log = logging.getLogger("Auth Route (Wrapper)"); //eslint-disable-line no-unused-vars

/*
 * Basically this should not render it's children until/unless we get the authed user.
 * If there is no authed user, fire an event to store the URL, and redirect to the auth page.
 */
const AuthedUserRoute = ({ path, redirect, children }) => {
  const dispatch = useDispatch();

  const userIsAuthed = useSelector(selectUserIsAuthed);
  const loading = useSelector(selectAuthLoading);

  const location = useLocation();

  useEffect(() => {
    log.debug("useEffect on Authed User Route");
    log.debug("path: ", location, loading, userIsAuthed);
    if (loading) {
      return;
    }
    if (!userIsAuthed) {
      log.debug("redirecting to auth from... ", location.pathname);
      // The user isn't authed, they shouldn't be here
      // Save the attempted path (dispatch an action)
      dispatch(redirectToAuthFrom({ destination: location.pathname }));
      // And then redirect to /auth
    }
  }, [dispatch, userIsAuthed, loading, location]);

  if (loading) {
    return <FullScreenSpinner />;
  }

  log.debug("User is authed: ", userIsAuthed);

  return (
    <Route
      path={path}
      render={() => (userIsAuthed ? children : <Redirect to="/auth" />)}
    />
  );
};

export default AuthedUserRoute;
