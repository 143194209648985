import logging from "utils/logging";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { selectGameId } from "../gamestate/gameStateSlice";
import {
  getSuperlativesAction,
  selectAllSuperlatives,
} from "./superlativeSlice";
import { SuperlativeCard } from "./components/SuperlativeCard";
import useSound from "use-sound";
import closingSong from "sounds/closing-theme.mp3";

const log = logging.getLogger("Superlative Show"); //eslint-disable-line no-unused-vars

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const SuperlativeShow = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const gameId = useSelector(selectGameId);
  const superlatives = useSelector(selectAllSuperlatives);

  const [superlativeIndex, setSuperlativeIndex] = useState(0);
  const [showCard, setShowCard] = useState(true);

  const [playCreditsMusic, { stop: stopCreditsMusic }] = useSound(closingSong);

  useEffect(() => {
    dispatch(getSuperlativesAction(gameId));
  }, [gameId, dispatch]);

  useEffect(() => {
    playCreditsMusic();
    return function cleanup() {
      stopCreditsMusic();
    };
  }, [playCreditsMusic, stopCreditsMusic]);

  const onCardDone = async () => {
    if (superlativeIndex === superlatives.length - 1) {
      setShowCard(false);
      // stopCreditsMusic();
      return;
    }
    setShowCard(false);
    setSuperlativeIndex(superlativeIndex + 1);
    setShowCard(true);
  };

  if (superlatives.length === 0) {
    return null;
  }

  return (
    <div className={classes.root}>
      {showCard && (
        <SuperlativeCard
          superlative={superlatives[superlativeIndex]}
          doneShowingCallback={onCardDone}
        />
      )}
    </div>
  );
};

export { SuperlativeShow };
