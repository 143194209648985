import logging from "utils/logging";
import React from "react";
import {
  makeStyles,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { selectPlayerSubmissionsForJudging } from "features/question/submissionSlice";
import { useSelector } from "react-redux";
import { selectCurrentQuestionObject } from "../../question/questionSlice";
import Provider from "provider";
import {
  GAMESTATE_ROUND_FINAL,
  SUBMISSION_STATE_COMMITTED,
  SUBMISSION_STATE_CORRECT,
  SUBMISSION_STATE_WRONG,
} from "../../gamestate/constants";
import { selectGameStatusObject } from "../../gamestate/gameStateSlice";

const log = logging.getLogger("JudgingBoard");

const useStyles = makeStyles((theme) => ({
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    marginTop: theme.spacing(3),
  },
  inline: {
    display: "inline",
  },
}));

const PlayerRow = ({ player, submission, question, round }) => {
  const classes = useStyles();

  // log.info("Player for row: ", player);

  let answer = "";
  let value = "";
  let primary = player.name;

  let pointValue = question.value;
  if (submission && submission.wager) {
    pointValue = submission.wager;
  }

  const approveSubmission = async () => {
    const { gameId, questionId, id } = submission;
    await Provider.updateSubmission(
      gameId,
      questionId,
      id,
      SUBMISSION_STATE_CORRECT,
      pointValue
    );
  };

  const rejectSubmission = async () => {
    const { gameId, questionId, id } = submission;
    await Provider.updateSubmission(
      gameId,
      questionId,
      id,
      SUBMISSION_STATE_WRONG,
      -pointValue
    );
  };

  let disableButtons = !submission;

  // Disable the judging buttons if there is no answer submitted
  if (submission && !submission.answer) {
    // Unless it's a daily double or final jeopardy
    if (question.dailyDouble || round === GAMESTATE_ROUND_FINAL) {
      disableButtons = false;
    } else {
      disableButtons = true;
    }
  }

  let approveButton = (
    <IconButton
      edge="end"
      aria-label="approve"
      onClick={approveSubmission}
      disabled={disableButtons}
    >
      <CheckCircleIcon />
    </IconButton>
  );

  let rejectButton = (
    <IconButton
      edge="end"
      aria-label="approve"
      onClick={rejectSubmission}
      disabled={disableButtons}
    >
      <CancelIcon />
    </IconButton>
  );

  if (submission) {
    let answerTime = "";
    if (question.startTime && submission.submittedAt) {
      const qTime = new Date(question.startTime);
      const aTime = new Date(submission.submittedAt);

      answerTime = "" + Math.abs(aTime - qTime) / 1000;
      answerTime = " — " + answerTime + "s";
    }

    if (submission.wager) {
      primary = (
        <React.Fragment>
          <span>{player.name}</span>
          {" | Wager: $"}
          <span>{submission.wager.toLocaleString()}</span>
        </React.Fragment>
      );
    }

    if (submission.status === SUBMISSION_STATE_CORRECT) {
      approveButton = (
        <IconButton
          edge="end"
          aria-label="approve"
          style={{ color: "#1bbc1b" }}
          disabled
        >
          <CheckCircleIcon />
        </IconButton>
      );
      value = (
        <span style={{ color: "#1bbc1b" }}>{" | +" + submission.points}</span>
      );
    } else if (submission.status === SUBMISSION_STATE_WRONG) {
      rejectButton = (
        <IconButton
          edge="end"
          aria-label="reject"
          style={{ color: "#bc1b1b" }}
          disabled
        >
          <CancelIcon />
        </IconButton>
      );
      value = (
        <span style={{ color: "#bc1b1b" }}>{" | " + submission.points}</span>
      );
    } else if (submission.status === SUBMISSION_STATE_COMMITTED) {
      rejectButton = (
        <IconButton edge="end" aria-label="reject" disabled>
          <CancelIcon />
        </IconButton>
      );
      approveButton = (
        <IconButton edge="end" aria-label="approve" disabled>
          <CheckCircleIcon />
        </IconButton>
      );

      value = <span>{" | " + submission.points}</span>;
    }
    answer = (
      <React.Fragment>
        <Typography
          component="span"
          variant="body2"
          className={classes.inline}
          color="textPrimary"
        >
          {submission.answer}
        </Typography>
        {answerTime}
        {value}
      </React.Fragment>
    );
  }

  return (
    <ListItem>
      <ListItemText primary={primary} secondary={answer} />

      <ListItemSecondaryAction>
        {approveButton}
        {rejectButton}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const JudgingBoard = () => {
  const classes = useStyles();

  const playerSubmissions = useSelector(selectPlayerSubmissionsForJudging);
  const question = useSelector(selectCurrentQuestionObject);
  const gameState = useSelector(selectGameStatusObject);

  let list;
  if (!playerSubmissions || !question) {
    log.debug("Question that won't load: ", question);
    list = <Typography>Loading...</Typography>;
  } else {
    list = playerSubmissions.map((playerSub) => (
      <PlayerRow
        player={playerSub}
        question={question}
        submission={playerSub.submission}
        round={gameState.round}
        key={`player-${playerSub.id}`}
      />
    ));
  }

  return (
    <div className={classes.demo}>
      <Typography variant="h5" className={classes.header}>
        Submissions
      </Typography>
      <List>{list}</List>
    </div>
  );
};

export default JudgingBoard;
