import logging from "utils/logging";
import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  createCloseListenerAction,
  createOpenListenerAction,
} from "redux/actions";
import Provider from "provider";

const log = logging.getLogger("Auth Slice"); //eslint-disable-line no-unused-vars

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    loadingAuthState: true,
    destination: null,
  },
  reducers: {
    userLoaded(state, action) {
      log.debug("Authed user loaded. Action: ", action);
      state.user = action.payload.user;
      state.loadingAuthState = false;
    },
    loadingUser(state) {
      state.user = null;
      state.loadingAuthState = true;
    },
    watchingUser(state) {
      state.loadingAuthState = true;
    },
    redirectToAuthFrom(state, action) {
      state.destination = action.payload.destination;
    },
    loggingOut(state, action) {
      state.loadingAuthState = true;
      state.user = null;
      state.destination = null;
    },
  },
});

export const { redirectToAuthFrom } = authSlice.actions;

const watchAuthedUserActionBase = createOpenListenerAction(
  "user/watch_authed_user",
  "watch-authed-user"
);

const stopWatchingAuthedUserActionBase = createCloseListenerAction(
  "user/watch_authed_user",
  "watch-authed-user"
);

const watchAuthedUserAction = () => (dispatch) => {
  dispatch(authSlice.actions.watchingUser());
  dispatch(
    watchAuthedUserActionBase({}, async () => {
      log.info("Watching authed user.");
      return await Provider.watchAuthedUser((user) => {
        log.info("Got auth change: ", user);
        dispatch(authSlice.actions.userLoaded({ user }));
      });
    })
  );
};

const stopWatchingAuthedUserAction = () => (dispatch) => {
  dispatch(stopWatchingAuthedUserActionBase({}));
};

const logOutAction = () => async (dispatch) => {
  dispatch(authSlice.actions.loggingOut());
  await Provider.logOut();
};

export { watchAuthedUserAction, stopWatchingAuthedUserAction, logOutAction };

/* ---- Selectors ---- */

const selectAuthState = (state) => state.auth;
const selectCurrentUser = createSelector(selectAuthState, (s) => s.user);
const selectCurrentUserId = createSelector(selectAuthState, (s) =>
  s.user ? s.user.uid : null
);
const selectAuthLoading = createSelector(
  selectAuthState,
  (s) => s.loadingAuthState
);
const selectUserIsAuthed = createSelector(selectAuthState, (s) => {
  const user = s.user;
  return user && user.uid && !user.isAnonymous;
});
const selectDestination = createSelector(selectAuthState, (s) => s.destination);

export {
  selectCurrentUser,
  selectCurrentUserId,
  selectAuthLoading,
  selectUserIsAuthed,
  selectDestination,
};

export default authSlice.reducer;
