import React, { useState } from "react";
import {
  Button,
  makeStyles,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Provider from "provider";
import { useSelector } from "react-redux";
import { selectGameId } from "features/gamestate/gameStateSlice";
import logging from "utils/logging";

const log = logging.getLogger("Game Settings");

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(2),
  },
}));

const ResetGameButton = () => {
  const classes = useStyles();

  const gameId = useSelector(selectGameId);

  const [resetting, setResetting] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const closeToast = () => setToastOpen(false);
  const closeConfirm = () => setConfirmOpen(false);

  const resetGame = async () => {
    setResetting(true);
    setConfirmOpen(false);
    const result = await Provider.resetGame(gameId);
    log.debug("reset game result: ", result);
    setResetting(false);
    setToastMessage("Game has been reset!");
    setToastOpen(true);
  };

  return (
    <React.Fragment>
      <Button
        className={classes.button}
        size="large"
        variant="contained"
        onClick={() => setConfirmOpen(true)}
        color="secondary"
        disabled={resetting}
      >
        Reset Game
      </Button>
      <Dialog
        open={confirmOpen}
        onClose={closeConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to reset this game? This will delete all
            participants, scores, and reset the game to the beginning. <br />
            <br />
            The questions will remain unchanged.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirm} color="primary">
            Cancel
          </Button>
          <Button onClick={resetGame} color="primary" autoFocus>
            Reset Game
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={toastOpen} autoHideDuration={6000} onClose={closeToast}>
        <Alert
          elevation={6}
          variant="filled"
          onClose={closeToast}
          severity="success"
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default ResetGameButton;
