import React, { useState } from "react";
import { Button, makeStyles, Typography, Snackbar } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { selectGameId } from "features/gamestate/gameStateSlice";
import ResetGameButton from "../components/ResetGameButton";
import CopyGameButton from "../components/CopyGameButton";
import logging from "utils/logging";

const log = logging.getLogger("Game Settings");

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex"
    margin: theme.spacing(1),
  },
  sectionHeader: {
    marginBottom: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(2),
  },
}));

const GameSettings = () => {
  const classes = useStyles();

  const gameId = useSelector(selectGameId);

  const copyGame = () => {
    // const result = await Provider.copy
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.sectionHeader}>
        Actions
      </Typography>
      <ResetGameButton />
      <CopyGameButton />
    </div>
  );
};

export default GameSettings;
