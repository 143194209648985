import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Typography,
  Paper,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  createGameAction,
  resetCreateGameStateAction,
  selectCreatedGameId,
  selectCreating,
} from "./createGameSlice";
import logging from "utils/logging";
import { ManagerAppBar } from "../appBar/AppBar";
import { FullBackgroundColor } from "../../components/FullBackgroundColor";
import { selectCurrentUserId } from "../../auth/authSlice";
import { blue } from "@material-ui/core/colors";
const log = logging.getLogger("Create Game");

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  title: {
    margin: theme.spacing(2),
    textAlign: "center",
    fontFamily: "Gyparody",
    color: "#ffbf00",
    fontSize: "3em",
    textShadow: `0px 0px 0 rgb(243,179,0),
                 1px 1px 0 rgb(230,166,0),
                 2px 2px 0 rgb(218,154,0),
                 3px 3px 0 rgb(206,142,0),
                 4px 4px 0 rgb(193,129,0),
                 5px 5px 0 rgb(181,117,0),
                 6px 6px 0 rgb(168,104,0),
                 7px 7px 0 rgb(156,92,0),
                 8px 8px  0 rgb(144,80,0),
                 9px 9px 8px rgba(120,128,204,0.58),
                 9px 9px 1px rgba(120,128,204,0.5),
                 0px 0px 8px rgba(120,128,204,.2)`,
  },
  paper: {
    padding: theme.spacing(3),
  },
  subTitle: {
    margin: theme.spacing(2),
  },
  titleBox: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // margin: theme.spacing(2),
    // padding: theme.spacing(3),
  },
  createButton: {
    margin: theme.spacing(2),
  },
  buttonProgress: {
    color: blue[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const CreateGame = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  // const createGame = () => {};
  const [gameName, setGameName] = useState("");
  const [categoryCount, setCategoryCount] = useState(6);

  const creating = useSelector(selectCreating);
  const createdGameId = useSelector(selectCreatedGameId);
  const userId = useSelector(selectCurrentUserId);

  useEffect(() => {
    dispatch(resetCreateGameStateAction());
  }, [dispatch]);

  useEffect(() => {
    if (createdGameId) {
      log.debug("Pushing to edit new game: ", createdGameId);
      history.push(`/manage/game/${createdGameId}/editor/round1`);
    }
  }, [createdGameId, dispatch, history]);

  const onCreateGame = () =>
    dispatch(createGameAction(gameName, categoryCount, userId));

  return (
    <React.Fragment>
      <Container maxWidth="md" className={classes.root}>
        <Paper className={classes.paper}>
          <Typography variant="h2" className={classes.title}>
            New Game
          </Typography>
          {/*<Typography variant="h4" className={classes.subTitle}>*/}
          {/*  Start by giving it a name*/}
          {/*</Typography>*/}
          <TextField
            id="game-name-field"
            label="Name"
            variant="outlined"
            fullWidth
            placeholder="My Fun Groupardy Game"
            autoFocus
            className={classes.titleBox}
            value={gameName}
            onChange={(e) => setGameName(e.target.value)}
          />
          <Typography variant="h5" className={classes.subTitle}>
            Categories per Round
          </Typography>
          <ToggleButtonGroup
            size="large"
            exclusive
            onChange={(e, v) => {
              log.debug("Changing category count: ", v);
              if (v !== null) {
                setCategoryCount(v);
              }
            }}
            color="primary"
            aria-label="categories per round"
            value={categoryCount}
          >
            <ToggleButton value={4}>4</ToggleButton>
            <ToggleButton value={5}>5</ToggleButton>
            <ToggleButton value={6}>6</ToggleButton>
          </ToggleButtonGroup>
          <br />
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.createButton}
              onClick={onCreateGame}
              disabled={creating || !gameName}
            >
              Create Game
            </Button>
            {creating && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </Paper>
      </Container>
    </React.Fragment>
  );
};

export { CreateGame };
